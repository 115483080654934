import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Box, Text, Checkbox, HStack, VStack, Flex, Center, Container, Button, Spinner, Modal, TextArea } from 'native-base';

import { Helmet } from "react-helmet";
import { useNavigate, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next';

import StatisticheQuizSvolto from './statisticheQuizSvolto';
import Pagination from './pagination';
import Answer from './answer';
import Timer from './timer';
import Logo from "../../img/logoEspanso.png";
import Logomin from "../../img/logoMin.png";
import { QuestionariAPI } from '../../api/questionariApi';
// import Logo from '../logoSimone.png'


export default function VistaQuiz() {
    const tokenUser = JSON.parse(localStorage.getItem("token"));


    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const abortController = new AbortController();
    const signal = abortController.signal;

    const [nomeQuestionario, setNomeQuestionario] = useState('Quiz');
    const [idUtente, setIdUtente] = useState(99);

    const [quizLoaded, setQuizLoaded] = useState(false);
    const [selectedDomanda, setSelectedDomanda] = useState('');
    const [indexDomanda, setIndexDomanda] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [indexDomandaProgress, setIndexDomandaProgress] = useState(0);
    const [indexSezione, setIndexSezione] = useState(0);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [nextQuestion, setNextQuestion] = useState(false);
    const [statisticheDialogOpen, setStatisticheDialogOpen] = useState(false);
    const [sezioni, setSezioni] = useState([]);
    const [questionarioCompilato, setQuestionarioCompilato] = useState([]);

    const [timer, setTimer] = useState(3600);
    const [tempoIniziale, setTempoIniziale] = useState(0);
    const [tempoCompletamento, setTempoCompletamento] = useState(0);
    const [remainingTime, setRemainingTime] = useState(3600);
    const [lastSectionNumber, setLastSectionNumber] = useState(1);

    const [tempoTrascorsoInSezione, setTempoTrascorsoInSezione] = useState(0);
    const [sezioniConTempoMinTrascorso, setSezioniConTempoMinTrascorso] = useState([]);

    const [totalNumberQuiz, setTotalNumberQuiz] = useState(0);
    const [questionarioSelezionatoID, setQuestionarioSelezionatoID] = useState(-1);
    const [volumeSelezionatoID, setvolumeSelezionatoID] = useState(-1);
    const [typeQuestionario, setTypeQuestionario] = useState('Sim');
    const [isGratuito, setIsGratuito] = useState(false);
    const [errorQuiz, setErrorQuiz] = useState(false);
    const [quizSection, setquizSection] = useState([]);
    const [idQuizCompletati, setIdQuizCompletati] = useState([]);
    const [quizPartenza, setQuizPartenza] = useState(0);

    const [testoSegnalazione, setTestoSegnalazione] = useState('');
    const [dialogSegnalazioneOpen, setDialogSegnalazioneOpen] = useState(false);
    const [alertSegnalazioneInviataOpen, setAlertSegnalazioneInviataOpen] = useState(false);
    const [tipoAlertSegnalazione, setTipoAlertSegnalazione] = useState('success');

    const [nextDomandaButtonDisabled, setNextDomandaButtonDisabled] = useState(false);

    const [pageNumberLimit, setPageNumberLimit] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [minPage, setMinPage] = useState(0);
    const [windowWidth, setWindiwWidth] = useState(window.innerWidth)
    const [openEndQuizDialog, setOpenEndQuizDialog] = useState(false);

    const [timeFinishedDialogOpen, setTimeFinishedDialogOpen] = useState(false)

    const [sogliaSufficienza, setSogliaSufficienza] = useState(0);


    useEffect(() => {
        if (location.state != null) {
            setIdUtente(location.state.idUtente)
            setIsGratuito(location.state.gratuito)

            if (location.state.questionario != null) {
                setvolumeSelezionatoID(location.state.questionario.idVolume)
                setNomeQuestionario(location.state.questionario.nomeQuestionario)

                setTypeQuestionario(location.state.type)

                if (location.state.type == "Ese") {
                    setTotalNumberQuiz(location.state.numeroQuiz)
                }

                // if (location.state.questionario.permalink != null && location.state.questionario.permalink != "") {
                //     let url = location.state.questionario.permalink;
                //     window.history.replaceState(null, location.state.questionario.nome, url)
                // } else {
                //     let url = location.state.questionario.nome;
                //     window.history.replaceState(null, location.state.questionario.nome, url)
                // }
            }
            parseQuestionario();
        }
        else { //Se location.state è null allora ti rimando alla home
            navigate("/")
        }
        return () => {
            abortController.abort();
        }
    }, []);

    useEffect(() => {
        detectWindowWidth();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth]);


    useInterval(() => {
        if (sezioni.length > 0) {
            setTempoTrascorsoInSezione(tempoTrascorsoInSezione + 1);
        }
    }, 1000);

    //Metodi server
    function parseQuestionario() {

        let partiListTmp = [];
        let data;
        let timerTmp = 0;
        // if (location.state.type !== 'Seq') {

        for (let i = 0; i < location.state.parti.length; i++) {
            if (location.state.parti[i].quizSelezionati > 0)
                timerTmp += location.state.parti[i].tempMax * 60;
        }
        setTimer(timerTmp)

        if (location.state.parti.length > 0) {
            if (location.state.parti[0].tempMax > 0)
                //setTimer(location.state.parti[0].tempMax * 60)

                for (let parte of location.state.parti) {
                    let parteTmp;
                    if (location.state.type === 'Sim') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizDisponibili,
                        }
                    }
                    else if (location.state.type === 'Ese') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizSelezionati,
                        }
                    }
                    else if (location.state.type === 'Seq') {
                        parteTmp = {
                            idParte: parte.idParte,
                            numQuiz: parte.quizSelezionati,
                            offset: parte.offset,
                            idVolume: parte.idVolume
                        }
                    }


                    if (location.state.type !== 'Seq') {
                        parteTmp.tempMin = parseInt(parte.tempMin);
                        parteTmp.tempMax = parseInt(parte.tempMax);
                        parteTmp.remainingTime = parseInt(parte.tempMax);
                    }

                    if (parteTmp.numQuiz > 0) { //Se la parte non ha quiz disponibili, la escludo
                        partiListTmp.push(parteTmp);
                    }
                }

        }

        if (location.state.type === 'Ese') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
                "parti": partiListTmp
            }
        }
        else if (location.state.type === 'Sim') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
            }
        }
        else if (location.state.type === 'Seq') {
            data = {
                "type": location.state.type,
                "id": location.state.idQuestionario,
                "parti": partiListTmp
            }
        }
        // }
        // else {
        //     //offset -1 perchè parte da 1 e non da 0
        //     data = {
        //         "type": location.state.type,
        //         "id": location.state.idQuestionario,
        //         "offset": location.state.offset - 1,
        //         "numQuiz": location.state.quizSequenzialiSelezionati
        //     }
        //     setQuizPartenza(0)//(location.state.offset - 1)
        // }




        let bodyQuestionario = data;
        QuestionariAPI.parseQuestionario(bodyQuestionario, tokenUser)
            .then(result => {
                if (result.success) {
                    let questionCompilatoArray = [];
                    let tmpSezioni = []; //Per evitare che vengano messe sezioni senza quiz
                    let numberQuiz = 0;
                    for (let i = 0; i < result.data.sezione.length; i++) {
                        if (result.data.sezione[i].quiz.length > 0) {
                            let tmpSezione = result.data.sezione[i]
                            tmpSezione.tempMin = location.state.parti[i].tempMin;
                            tmpSezione.tempMax = location.state.parti[i].tempMax;

                            let titleTmp = '';
                            for (let j = 0; j < tmpSezione.quiz.length; j++) {

                                //pulizia domanda
                                titleTmp = tmpSezione.quiz[j].title;
                                titleTmp = unEscape(titleTmp);
                                if (titleTmp.includes("src=\"http://sandbox2.itlogix.it/")) {
                                    titleTmp = titleTmp.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                }
                                else if (titleTmp.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                                    titleTmp = titleTmp.replaceAll("src=\"\/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                                else titleTmp = titleTmp.replaceAll("src=\"", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                tmpSezione.quiz[j].title = titleTmp;

                                //pulizia risposte
                                for (let x = 0; x < tmpSezione.quiz[j].choices.length; x++) {
                                    let tmpChoice = tmpSezione.quiz[j].choices[x].text;
                                    tmpChoice = unEscape(tmpChoice);
                                    if (tmpChoice.includes("src=\"http://sandbox2.itlogix.it/")) {
                                        tmpChoice = tmpChoice.replaceAll("src=\"http://sandbox2.itlogix.it/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    }
                                    else if (tmpChoice.includes("src=\"\/")) { // /static/uploads/quizImg/quizImg_1653561997856.jpg
                                        tmpChoice = tmpChoice.replaceAll("src=\"\/", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    } // static/uploads/quizImg/quizImg_1653561997856.jpg (Se static non ha il carattere / davanti)
                                    else tmpChoice = tmpChoice.replaceAll("src=\"", "src=\"" + process.env.REACT_APP_IMAGES_PREFIX)
                                    tmpSezione.quiz[j].choices[x].text = tmpChoice;
                                }

                                questionCompilatoArray.push({
                                    "quizId": tmpSezione.quiz[j].quizId,
                                    "type": tmpSezione.quiz[j].type,
                                    "title": tmpSezione.quiz[j].title,
                                    "choices": tmpSezione.quiz[j].choices,
                                    "choicesOrder": tmpSezione.quiz[j].choicesOrder,
                                    "correctAnswer": tmpSezione.quiz[j].correctAnswer,
                                    "givenAnswer": selectedDomanda !== "" ? selectedDomanda : "",
                                    "puntiErrata": tmpSezione.puntiErrata !== undefined ? tmpSezione.puntiErrata : -1,
                                    "puntiOmessa": tmpSezione.puntiOmessa !== undefined ? tmpSezione.puntiOmessa : -0,
                                    "puntiCorretta": tmpSezione.puntiCorretta !== undefined ? tmpSezione.puntiCorretta : 1,
                                })


                            }
                            numberQuiz += tmpSezione.quiz.length;
                            setQuestionarioCompilato(questionCompilatoArray)
                            tmpSezioni.push(tmpSezione)
                        }
                    }
                    if (numberQuiz > 0) {
                        setSezioni(tmpSezioni)
                        getTotalAnswerNumber(result.data.sezione)
                        setQuestionarioSelezionatoID(location.state.idQuestionario);
                        setvolumeSelezionatoID(location.state.idVolume);

                        if (tmpSezioni[0].tempMax != undefined) {
                            setTempoIniziale(tmpSezioni[0].tempMax);
                        }
                        else {
                            setTempoIniziale(60);
                        }
                        setErrorQuiz(false);
                        setQuizLoaded(true);
                        setLastSectionNumber(tmpSezioni[0].quiz.length)

                        if (typeQuestionario !== 'Sim')
                            setSogliaSufficienza(result.data.sogliaSufficienza)
                    } else {
                        setQuizLoaded(true);
                        setErrorQuiz(true);
                    }

                }
                else {
                    setErrorQuiz(true);
                }
            })
    }
    function calculateTempoRestanteSezione() {
        let tempoRestante;
        tempoRestante = (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione;
        if (tempoRestante > 0) return convertNumberToMinutes(tempoRestante)
        else return 0;
    }

    //Metodi Utility
    function convertMinutesToSeconds(time) {  /*Converto i secondi in formato MM:SS */
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        if (minutes < 10) minutes = '0' + minutes
        if (seconds < 10) seconds = '0' + seconds
        return (minutes + "." + seconds)
    }
    function convertNumberToMinutes(number) {
        let minutes = Math.floor(number / 60);
        var seconds = number - minutes * 60;
        return minutes + ":" + seconds
    }
    function progressBarPercentage() { /*Barra orizzontale domanda attuale/domande totali */
        if (quizLoaded === true) {
            let current = indexDomandaProgress;
            return Math.round((current * 100) / totalNumberQuiz)
        }
    }
    function getTotalAnswerNumber(sezioniTmp) {

        let quizTotalNumber = 0;
        for (let i = 0; i < sezioniTmp.length; i++) {
            quizTotalNumber += sezioniTmp[i].quiz.length;
        }
        setTotalNumberQuiz(quizTotalNumber);

    }
    function unEscape(htmlStr) {

        if (htmlStr.match(/&lt;/g))
            htmlStr = htmlStr.replace(/&lt;/g, "<");

        if (htmlStr.match(/&gt;/g))
            htmlStr = htmlStr.replace(/&gt;/g, ">");

        if (htmlStr.match(/&quot;/g))
            htmlStr = htmlStr.replace(/&quot;/g, "\"");

        if (htmlStr.match(/&#39;/g))    //eslint-disable-next-line  no-useless-escape
            htmlStr = htmlStr.replace(/&#39;/g, "\'");

        if (htmlStr.match(/&amp;/g))
            htmlStr = htmlStr.replace(/&amp;/g, "&");

        if (htmlStr.includes("<p")) {
            let length = htmlStr.split("<p").length - 1 + htmlStr.split("/p>").length
            for (let i = 0; i < length; i++) {
                htmlStr = htmlStr.replace(/(<p[^>]+?>|<p>|<\/p>)/, "");
            }
        }

        if (htmlStr.includes("<span")) {
            let length = htmlStr.split("<span").length - 1 + htmlStr.split("/span>").length
            for (let i = 0; i < length; i++) {
                htmlStr = htmlStr.replace(/(<span[^>]+?>|<span>|<\/span>)/, "");
            }
        }

        return htmlStr;


    }
    function checkForEmptyGivenAnswer() {
        nextQuestionCheck(true);
        //checkQuizInSection();
        let userAsnwers = questionarioCompilato.filter(quiz => quiz.givenAnswer === '')
        if (userAsnwers.length > 0) {
            handleDialogOpen();
        } else {
            nextQuestionCheck();
            checkQuizInSection();
        }
    }
    function checkQuizInSection() {
        let quizSectionArray = quizSection;
        let checkedId = indexDomanda;
        if (!quizSectionArray.includes(checkedId)) {
            quizSectionArray.push(checkedId);
            setquizSection(quizSectionArray)
        }

    }
    function changeRemainingTime(remainingTime) {
        let remainingTimeTmp = remainingTime;
        if (sezioni[indexSezione].tempMin > 0) {
            remainingTimeTmp = remainingTimeTmp - (sezioni[indexSezione].tempMax * 60)
            setRemainingTime(remainingTimeTmp)
        } else setRemainingTime(0);
    }
    function onTimeFinished() {
        setTimeFinishedDialogOpen(true)
    }
    function changePage(e) {
        e.preventDefault()
        navigate("/questionari")
    }
    function goBackToQuestionarioGratuito() {
        navigate("/questionario/" + location.state.prettyLink)
    }
    async function sendSegnalazione() {
        if (testoSegnalazione != "") {
            let risoluzioneBrowser = window.innerWidth + 'x' + window.innerHeight;
            let data = {
                emailBody: testoSegnalazione,
                risoluzione: risoluzioneBrowser,
                idQuestionario: questionarioSelezionatoID,
                idQuiz: sezioni[indexSezione].quiz[indexDomanda].quizId,
                userAgent: navigator.userAgent
            }
            let url = process.env.REACT_APP_FETCH_URL + "questionario/userFeedback";
            let requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                signal: signal
            };

            try {
                let res = await fetch(url, requestOptions);
                if (res.status === 200) {
                    res.json().then(
                        result => {
                            if (result.success) {
                                setDialogSegnalazioneOpen(false);
                                setTestoSegnalazione('');
                                setAlertSegnalazioneInviataOpen(true)
                                setTipoAlertSegnalazione('success')
                            }
                            else {
                                setAlertSegnalazioneInviataOpen(true)
                                setTipoAlertSegnalazione('error')
                            }
                        }
                    )
                }
            } catch (error) { }
        }
    }

    //Metodi setter
    function selectDomanda(domanda, index) {
        // console.log(domanda)
        if (domanda.value !== undefined) {
            setSelectedDomanda(domanda.value)
        }
        else setSelectedDomanda(index);
    }
    function setCorrectAnswer() {
        setShowCorrectAnswer(!showCorrectAnswer);
    }
    function changeTextSegnalazione(e) {
        setTestoSegnalazione(e.target.value)
    }

    //Metodi dialog
    function handleDialogOpen() {
        setOpenEndQuizDialog(true);
    };
    function handleDialogClose() {
        setOpenEndQuizDialog(false);
    };
    function openDialogSegnalazione() {
        let openTmp = !dialogSegnalazioneOpen;
        setDialogSegnalazioneOpen(openTmp)
    }
    function closeAlertSegnalazione() {
        setAlertSegnalazioneInviataOpen(false)
    }

    //Metodi bottoni
    function nextQuestionCheck(skipCheck) { /*Controlla se è abilitata l'impostazione per controllare la risposta corretta o meno */
        if (showCorrectAnswer === false || currentPage === questionarioCompilato.length - 1) {
            nextQuestionMethod(skipCheck);
        } else setNextQuestion(true);

        if (showCorrectAnswer === true && nextQuestion === true && currentPage !== questionarioCompilato.length - 1) {
            {
                setNextDomandaButtonDisabled(true);
                nextQuestionMethod();
            }
        }
    }

    function nextQuestionMethod(skipCheck) {

        let idQuizArray = idQuizCompletati;
        let questionarioCompilatoTmp = questionarioCompilato; //Aggiungo la risposta data all'array che poi salverò nelle statistiche
        let checkPaginationItem = idQuizArray.filter(quiz => quiz === sezioni[indexSezione].quiz[indexDomanda].quizId)
        idQuizArray.push(sezioni[indexSezione].quiz[indexDomanda].quizId)
        setIdQuizCompletati(idQuizArray)
        let indexofQuestion = questionarioCompilatoTmp.findIndex(quiz => quiz.quizId === sezioni[indexSezione].quiz[indexDomanda].quizId)

        questionarioCompilatoTmp[indexofQuestion] = {
            ...questionarioCompilatoTmp[indexofQuestion],
            "quizId": sezioni[indexSezione].quiz[indexDomanda].quizId,
            "type": sezioni[indexSezione].quiz[indexDomanda].type,
            "title": sezioni[indexSezione].quiz[indexDomanda].title,
            "choices": sezioni[indexSezione].quiz[indexDomanda].choices,
            "choicesOrder": sezioni[indexSezione].quiz[indexDomanda].choicesOrder,
            "correctAnswer": sezioni[indexSezione].quiz[indexDomanda].correctAnswer,
            "givenAnswer": selectedDomanda !== "" ? selectedDomanda : "",
            "puntiErrata": sezioni[indexSezione].puntiErrata !== undefined ? sezioni[indexSezione].puntiErrata : -1,
            "puntiOmessa": sezioni[indexSezione].puntiOmessa !== undefined ? sezioni[indexSezione].puntiOmessa : -0,
            "puntiCorretta": sezioni[indexSezione].puntiCorretta !== undefined ? sezioni[indexSezione].puntiCorretta : 1,
        }
        setQuestionarioCompilato(questionarioCompilatoTmp);

        if (indexDomanda + 1 < sezioni[indexSezione].quiz.length) { //Se non è l'ultima domanda vai alla successiva
            let newIndex = indexDomanda;
            newIndex++;
            if (checkPaginationItem.length === 0) {
                let newIndexProgress = indexDomandaProgress;
                newIndexProgress++;
                //Se NON è abilitata l'impostazione di visualizzare la risposta corretta e già è stato cliccato il tasto "Prosegui"
                setIndexDomandaProgress(newIndexProgress);
            }
            setIndexDomanda(newIndex);
            setSelectedDomanda('');
            setNextQuestion(false);
        } else { //Se è l'ultima domanda della sezione
            if (indexSezione < sezioni.length - 1) { //Se ci sono altre sezioni, sezioni.length -1 perchè senno crashava
                let indexSezioneTmp = indexSezione;
                let sezioniConTempoMinTrascorsoTmp = sezioniConTempoMinTrascorso;
                sezioniConTempoMinTrascorsoTmp.push(indexSezione);
                setSezioniConTempoMinTrascorso(sezioniConTempoMinTrascorsoTmp)
                setTempoTrascorsoInSezione(0);
                indexSezioneTmp++;
                setIndexSezione(indexSezioneTmp);
                //setTimer(sezioni[indexSezioneTmp].tempMax * 60)
                setIndexDomanda(0);

                if (checkPaginationItem.length === 0) {
                    let newIndexProgress = indexDomandaProgress;
                    newIndexProgress++;
                    //Se NON è abilitata l'impostazione di visualizzare la risposta corretta e già è stato cliccato il tasto "Prosegui"
                    setIndexDomandaProgress(newIndexProgress);
                }

                let lastSectionNumber = 0;
                for (let i = 0; i <= indexSezioneTmp; i++) {
                    lastSectionNumber += sezioni[i].quiz.length;
                }
                setLastSectionNumber(lastSectionNumber)
            }
            else { //Se sono finite le sezioni
                let tempoCompletamentoTmp = tempoIniziale - timer;
                tempoCompletamentoTmp = convertMinutesToSeconds(tempoCompletamentoTmp);
                if (checkPaginationItem.length === 0) {
                    let newIndexProgress = indexDomandaProgress;
                    newIndexProgress++;

                    setIndexDomandaProgress(newIndexProgress);
                }
                if (skipCheck == undefined)
                    setStatisticheDialogOpen(true);
                setTempoCompletamento(tempoCompletamentoTmp);
            }
        }



        if ((indexDomanda + 1) > maxPage) {
            setMaxPage(maxPage + pageNumberLimit);
            setMinPage(minPage + pageNumberLimit);
        }
        setCurrentPage(currentPage + 1);
        if (timeFinishedDialogOpen === true)
            setTimeFinishedDialogOpen(false);

        if (showCorrectAnswer === true && nextQuestion === true) {
            setTimeout(() => {
                setNextDomandaButtonDisabled(false)
            }, 500)
        }

    }

    function nextSezioneTimeEnded() {
        let tmpIndexSezione = indexSezione;
        let indexSezioneSuccessiva = indexSezione + 1;

        if (tmpIndexSezione < sezioni.length - 1) { //Se non è l'ultima sezione
            let sezioniTmp = sezioni;
            sezioniTmp[indexSezione].remainingTime = 0;
            setSezioni(sezioniTmp)
            setIndexSezione(indexSezioneSuccessiva);
            setIndexDomanda(0);
            let tmpContatore = 0;
            let lastSectionNumber = 0;

            for (let i = 0; i <= indexSezioneSuccessiva; i++) { //Questo for è diviso dal precedente perchè li c'è un return
                lastSectionNumber += sezioni[i].quiz.length;
            }
            setLastSectionNumber(lastSectionNumber)


            for (let i = 0; i < sezioni.length; i++) {
                for (let j = 0; j < sezioni[i].quiz.length; j++) {

                    if (indexSezioneSuccessiva === i) {
                        setCurrentPage(tmpContatore);
                        setTimeFinishedDialogOpen(false);
                        return;
                    }
                    tmpContatore++;
                }
            }

        }
        else { //Se è l'ultima sezione
            setStatisticheDialogOpen(true);
        }
        setTimeFinishedDialogOpen(false)
    }
    //Metodi pagination
    function onPageChange(pageNumber) {
        let tmpContatore = 0;

        setCurrentPage(pageNumber);




        for (let i = 0; i < sezioni.length; i++) {
            for (let j = 0; j < sezioni[i].quiz.length; j++) {
                if (tmpContatore === pageNumber) {

                    if (indexSezione !== i) //se c'è un cambio di sezione
                    {
                        let tmpSezioniTmp = sezioni;
                        tmpSezioniTmp[indexSezione].remainingTime = remainingTime;
                        setSezioni(tmpSezioniTmp)
                    }

                    setIndexSezione(i);
                    setIndexDomanda(j);
                    let quizIdTmp = sezioni[i].quiz[j].quizId;
                    let indexQuestionarioCompilato = questionarioCompilato.findIndex(quiz => quiz.quizId === quizIdTmp)
                    if (!isGratuito) {
                        if (typeQuestionario === 'Ese' && !idQuizCompletati.includes(sezioni[i].quiz[j].quizId)) {
                            setNextQuestion(false);
                        }
                        if (typeQuestionario === 'Ese' && idQuizCompletati.includes(sezioni[i].quiz[j].quizId) && showCorrectAnswer) {
                            setNextQuestion(true);
                        }
                    }
                    setSelectedDomanda(questionarioCompilato[indexQuestionarioCompilato].givenAnswer);
                    return;
                }
                tmpContatore++;
            }
        }
    }


    function onPrevClick() {

        if ((currentPage % pageNumberLimit) === 0) {
            if (maxPage <= pageNumberLimit) {
                setMaxPage(pageNumberLimit);
            } else {
                setMaxPage(maxPage - pageNumberLimit);
            }
            setMinPage(minPage - pageNumberLimit);
        }
        onPageChange(currentPage - 1)
        // setCurrentPage(prev => prev - 1);

        // let tmpContatore = 0;
        // for (let i = 0; i < sezioni.length; i++) {
        //     for (let j = 0; j < sezioni[i].quiz.length; j++) {
        //         if (tmpContatore === currentPage) {
        //             if (indexSezione !== i) {
        //                 setIndexSezione(i);
        //                 setIndexDomanda(sezioni[i].quiz.length - 1)
        //             } else {
        //                 setIndexSezione(i);
        //                 setIndexDomanda(j);
        //             }


        //             let quizIdTmp = sezioni[i].quiz[j].quizId;
        //             let indexQuestionarioCompilato = questionarioCompilato.findIndex(quiz => quiz.quizId === quizIdTmp)
        //             setSelectedDomanda(questionarioCompilato[indexQuestionarioCompilato].givenAnswer);

        //             return;
        //         }
        //         tmpContatore++;
        //     }
        // }



    }
    function onNextClick() {

        setNextQuestion(false);
        //setShowCorrectAnswer(true);
        if ((currentPage + 1) > maxPage) {
            setMaxPage(maxPage + pageNumberLimit);
            setMinPage(minPage + pageNumberLimit);
        }
        onPageChange(currentPage + 1)
        // 
        // setCurrentPage(prev => prev + 1);
        // let tmpContatore = 0;
        // for (let i = 0; i < sezioni.length; i++) {
        //     for (let j = 0; j < sezioni[i].quiz.length; j++) {
        //         if (tmpContatore === currentPage) {
        //             setIndexSezione(i);
        //             setIndexDomanda(j);
        //             let quizIdTmp = sezioni[i].quiz[j].quizId;
        //             let indexQuestionarioCompilato = questionarioCompilato.findIndex(quiz => quiz.quizId === quizIdTmp)
        //             setSelectedDomanda(questionarioCompilato[indexQuestionarioCompilato].givenAnswer);
        //             return;
        //         }
        //         tmpContatore++;
        //     }
        // }
    }
    function navigateToPage(url){
        navigate(url)
    }

    function detectWindowWidth() {
        setWindiwWidth(window.innerWidth)
        if ((windowWidth >= 370) && (windowWidth <= 768)) {
            setMaxPage(9)
            setPageNumberLimit(10)
        }
        else if (windowWidth < 370) {
            setMaxPage(4)
            setPageNumberLimit(5)
        }
        else {
            setMaxPage(19)
            setPageNumberLimit(20)
        }
    }
    window.onbeforeunload = (event) => {
        const e = event || window.event;
        e.preventDefault();
        if (e) {
            e.returnValue = '';
        }
        return '';
    };
    window.onload = () => {
        setIndexDomandaProgress(0)
        setQuestionarioCompilato([])
    }

    return (
        <Fragment>
            <Box minHeight={'100vh'} w={'100vw'} height={{ base: 'unset', md: '100%' }} bgColor={"linear-gradient(45deg, rgb(76, 104, 35) 0%, rgb(119, 168, 51) 100%)"}>
                {totalNumberQuiz > 0 ? (
                    quizLoaded === true && (
                        <Box h={'100%'} w="full">
                            {errorQuiz === true && (
                                <Container style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }} maxWidth="lg">
                                    <Text color={'white'} textAlign={'center'} fontSize="3xl">
                                        {t("pages.quiz.errorGeneratingQuestionario")}
                                    </Text>
                                    {isGratuito === false && (
                                        <Button variant="ghost" onPress={changePage}>
                                            <Text display={{ base: 'flex', md: 'none' }}>{t("pages.quiz.btnBack")}</Text>
                                            <Text display={{ base: 'none', md: 'flex' }}>{t("pages.quiz.btnBackToSelection")}</Text>
                                        </Button>
                                    )}
                                </Container>
                            )}
                            {errorQuiz === false && (
                                <Center w="full">
                                    <Container w="full" style={{ display: 'flex', flexDirection: 'column', alignItems: 'space-between', justifyContent: 'center', height: '100%' }}>
                                        <Box px={{ base: 4, md: 0 }} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: '100vh' }}>
                                            <HStack container spacing={2} style={{ marginTop: '20px' }}>    {/*Header*/}
                                                <Flex direction='row' justify='start' align='center' w={'30%'} > {/*Ritorna alla sezione precedente */}
                                                    {isGratuito === false ? (
                                                        <Button bgColor={'light.50'} variant="solid" onPress={changePage}>
                                                            <Text display={{ base: "flex", md: "none" }}>{t("pages.quiz.btnBack")}</Text>
                                                            <Text display={{ base: "none", md: "flex" }}>{t("pages.quiz.btnBackToSelezione")}</Text>
                                                        </Button>
                                                    ) : (
                                                        <Button variant="solid" onPress={goBackToQuestionarioGratuito}>
                                                            <Text display={{ base: "flex", md: "none" }}> {t("pages.quiz.btnBack")}</Text>
                                                            <Text display={{ base: "none", md: "flex" }}>{t("pages.quiz.btnBackToSelezione")}</Text>
                                                        </Button>
                                                    )}
                                                </Flex>

                                                <Flex w={'50%'} mt={2} direction="row" justify="center" >  {/*Timer*/}
                                                    <Timer timer={timer} sezione={sezioni[indexSezione]} typeQuestionario={typeQuestionario}
                                                        changeRemainingTime={changeRemainingTime} onTimeFinished={onTimeFinished} />
                                                </Flex>

                                                <Box w={'20%'} mt={2} display={{ base: "flex", md: "none" }} style={{ justifyContent: 'center', alignItems: 'center' }}> {/*Logo*/}
                                                    {/* <img style={{ maxWidth: '50%' }} draggable="false" loading="lazy" src={ Logo} /> */}
                                                </Box>




                                                <Box display={{ base: "none", md: "flex" }} style={{ justifyContent: 'center', alignItems: 'center' }}> {/*Logo*/}
                                                    {/* <img style={{ maxWidth: '50%' }} draggable="false" loading="lazy" src={Logo} /> */}
                                                    {/* <img style={{ maxWidth: '35%' }} draggable="false" alt="Logo Simone Edizioni" loading="lazy" src={Logo} /> */}
                                                </Box>
                                            </HStack>


                                            <Box my={1} w={'100%'} > {/*Domanda e n. domanda */}

                                                {/*Domanda su tablet e desktop */}
                                                <Text color={'white'} bold display={{ base: "none", md: "flex" }}   >
                                                    {t("pages.quiz.answer")} {indexDomanda + 1}
                                                    {(sezioni[indexSezione].nomeParte !== '' && typeQuestionario === 'Ese') && (' (' + sezioni[indexSezione].nomeParte + ')')}
                                                </Text>
                                                <Box display={{ base: "none", md: "flex" }} maxHeight={'20vh'} style={{ overflowY: 'scroll' }} >
                                                    <Text fontSize={"xl"} color={'white'}>
                                                        <div dangerouslySetInnerHTML={{ __html: sezioni[indexSezione].quiz[indexDomanda].title }}></div>
                                                    </Text>
                                                </Box>


                                                {/*Domanda su dispositivi mobile */}
                                                <Text color={'grey.200'} display={{ base: "flex", md: "none" }} maxHeight={'20vh'} bold style={{ overflowY: 'scroll' }} >
                                                    {(sezioni[indexSezione].nomeParte !== '' && typeQuestionario === 'Ese') && (' (' + sezioni[indexSezione].nomeParte + ')')}
                                                </Text>
                                                <Box color={'grey.200'} display={{ base: "flex", md: "none" }} bold>
                                                    <div dangerouslySetInnerHTML={{ __html: sezioni[indexSezione].quiz[indexDomanda].title }}></div>
                                                </Box>
                                            </Box>

                                            <Answer domanda={sezioni[indexSezione].quiz[indexDomanda]} showCorrectAnswer={showCorrectAnswer}
                                                nextQuestion={nextQuestion} selectDomanda={selectDomanda} selectedDomanda={selectedDomanda} />



                                            <Flex style={{ margin: '50px 0px 50px 0px' }}> {/*Bottoni conferma/impostazioni e progress bar */}

                                                {(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione)) && (
                                                    <Flex my={2} style={{ textAlign: 'end' }}>
                                                        <Text color="white">
                                                            {t("pages.quiz.needToWait")} &nbsp; {calculateTempoRestanteSezione()}
                                                        </Text>
                                                    </Flex>
                                                )}
                                                {/* <Box w={'30%'} style={{ position: "relative" }}>
                                                    <Text textAlign={'center'} color='white' style={{ position: 'absolute', zIndex: 10, left: '45%', lineHeight: 'unset' }}>
                                                        {indexDomandaProgress}/{totalNumberQuiz}
                                                    </Text>
                                                    <BorderLinearProgress variant="determinate" value={progressBarPercentage()} /> 
                                                </Box>*/}

                                                <Flex w={'100%'} mt={{ base: 2, md: 0 }} direction='row' justify={{ base: 'space-between', md: 'end' }} >
                                                    {typeQuestionario == 'Ese' && ( //BOTTONE IMPOSTAZIONI
                                                        <Fragment>
                                                            {/*Bottone impostazioni DISPOSITIVI MOBILE */}
                                                            <Button size="sm" variant="ghost" color={'white'} display={{ base: 'flex', md: 'none' }} mr="3" onPress={setCorrectAnswer}>
                                                                {showCorrectAnswer ? t("pages.quiz.dontShowCorrectAnswer") : t("pages.quiz.showCorrectAnswer")}
                                                            </Button>
                                                            {/*Bottone impostazioni DISPOSITIVI MEDIO-GRANDI */}
                                                            <Button size="md" variant="ghost" color={'white'} display={{ base: 'none', md: 'flex' }} mr="3" onPress={setCorrectAnswer}>
                                                                {showCorrectAnswer ? t("pages.quiz.dontShowCorrectAnswer") : t("pages.quiz.showCorrectAnswer")}
                                                            </Button>
                                                        </Fragment>
                                                    )}
                                                    {isGratuito && (
                                                        <Box>
                                                            {/*Bottone impostazioni DISPOSITIVI MOBILE */}
                                                            <Button size="sm" color="success" variant="outline" display={{ base: 'flex', md: 'none' }} mr="3" onPress={openDialogSegnalazione}>
                                                                {t("pages.quiz.submitReport")}
                                                            </Button>
                                                            {/*Bottone impostazioni DISPOSITIVI MEDIO-GRANDI */}
                                                            <Button size="md" color="error" variant="solid" display={{ base: 'none', md: 'flex' }} mr="3" onPress={openDialogSegnalazione}>
                                                                {t("pages.quiz.submitReport")}
                                                            </Button>
                                                        </Box>
                                                    )}
                                                    {/*Bottone conferma e prosegui DISPOSITIVI MEDIO-GRANDI */}
                                                    {currentPage < questionarioCompilato.length - 1 && (
                                                        <Button display={{ base: 'none', md: 'flex' }} variant="solid"
                                                            disabled={((indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione))
                                                                || (nextDomandaButtonDisabled && showCorrectAnswer))}
                                                            onPress={() => { nextQuestionCheck(); checkQuizInSection() }}>
                                                            {(showCorrectAnswer === true && !isGratuito) && (
                                                                indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                    t("pages.quiz.confirmAndGoToNextSection")
                                                                ) : (
                                                                    nextQuestion === false ? t("pages.quiz.confirmAndShowAnser") : t("pages.quiz.goNext")
                                                                )
                                                            )}
                                                            {showCorrectAnswer === false && (
                                                                indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                    t("pages.quiz.confirmAndGoToNextSection")
                                                                ) : (
                                                                    t("pages.quiz.confirmAndGoNext")
                                                                )
                                                            )}

                                                        </Button>
                                                    )}
                                                    {currentPage >= questionarioCompilato.length - 1 && (
                                                        <Button size="md" color="success" display={{ base: 'none', md: 'flex' }} disabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && typeQuestionario === 'Ese'}
                                                            variant="solid" onPress={checkForEmptyGivenAnswer}>
                                                            {t("pages.quiz.btnEnd")}
                                                        </Button>
                                                    )}



                                                    {/*Bottone conferma e prosegui DISPOSITIVI MOBILE */}
                                                    {currentPage < questionarioCompilato.length - 1 && (
                                                        <Button fullWidth={typeQuestionario === 'Sim' ? true : false} display={{ base: 'flex', md: 'none' }} variant="solid"
                                                            onPress={() => { nextQuestionCheck(); checkQuizInSection() }} disabled={((indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese' && !sezioniConTempoMinTrascorso.includes(indexSezione))
                                                                || (nextDomandaButtonDisabled && showCorrectAnswer))}>
                                                            {showCorrectAnswer === true && (
                                                                indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                    t("pages.quiz.confirmAndGoToNextSection")
                                                                ) : (
                                                                    nextQuestion === false ? t("pages.quiz.confirmAndShowAnser") : t("pages.quiz.confirmAndGoNext")
                                                                )
                                                            )}
                                                            {showCorrectAnswer === false && (
                                                                indexDomanda === (sezioni[indexSezione].quiz.length - 1) ? (
                                                                    t("pages.quiz.confirmAndGoToNextSection")
                                                                ) : (
                                                                    t("pages.quiz.confirmAndGoNext")
                                                                )
                                                            )}
                                                        </Button>
                                                    )}
                                                    {currentPage >= questionarioCompilato.length - 1 && (
                                                        <Button w={'full'} display={{ base: 'flex', md: 'none' }} disabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && (sezioni[indexSezione].tempMin * 60) - tempoTrascorsoInSezione) > 0 && typeQuestionario === 'Ese'}
                                                            variant="solid" onPress={checkForEmptyGivenAnswer}>
                                                            {t("pages.quiz.btnEnd")}
                                                        </Button>
                                                    )}
                                                </Flex>
                                                {/* <Button onClick={debugEndTime} variant="contained">Finisci tempo (Sezione successiva)</Button> */}
                                                <Flex p={1} mt={3} w="full" >
                                                    <Pagination minPage={minPage} maxPage={maxPage} currentPage={currentPage} total={totalNumberQuiz}
                                                        onPrevClick={onPrevClick}
                                                        onNextClick={onNextClick}
                                                        onPageChange={onPageChange}
                                                        quizSection={quizSection}
                                                        lastSectionNumberBool={(indexDomanda === (lastSectionNumber - 1) && convertMinutesToSeconds(remainingTime) > 0) ? true : false}
                                                        lastSectionNumber={lastSectionNumber}
                                                        quizPartenza={quizPartenza}
                                                        nextArrowDisabled={(indexDomanda === (sezioni[indexSezione].quiz.length - 1) && ((((remainingTime / 60) - sezioni[indexSezione].tempMin)).toFixed(2) > 0)) && sezioni[indexSezione].tempMin > 0 && typeQuestionario === 'Ese'}
                                                    />
                                                </Flex>
                                            </Flex>
                                        </Box>


                                    </Container>
                                </Center>
                            )}
                        </Box>
                    ))
                    : (
                        quizLoaded === true ? (
                            <Box height={'95vh'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <Text fontSize={'3xl'} color='white' textAlign='center'>Il questionario non ha nessun quiz associato.</Text>
                                {!isGratuito && (
                                    <Button variant="ghost" mr={3} onPress={changePage}>
                                        <Text display={{ base: 'flex', md: 'none' }}>Indietro</Text>
                                        <Text display={{ base: 'none', md: 'flex' }}>Clicca qui per tornare indietro</Text>
                                    </Button>
                                )}

                            </Box>
                        ) : (
                            <Box height={'95vh'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <HStack space={8} justifyContent="center" alignItems="center">
                                    <Flex>
                                        <Spinner size="lg" />
                                        <Text color={'white'} mt={4}> {t("pages.quiz.loading")}...</Text>
                                    </Flex>
                                </HStack>
                            </Box>
                        ))}


                {statisticheDialogOpen && (
                    <Modal isOpen={statisticheDialogOpen} size="full" maxH={'100vh'}>
                        <Modal.Content  p={5} overflowY="auto" h={'100vh'} maxH={'100vh'}>
                        <StatisticheQuizSvolto navigateTo={navigateToPage} tempo={tempoCompletamento} questionario={questionarioCompilato} isGratuito={isGratuito} nomeQuestionario={nomeQuestionario}
                            idVolume={volumeSelezionatoID} idUtente={idUtente} idQuestionario={questionarioSelezionatoID} type={typeQuestionario} sogliaSufficienza={sogliaSufficienza} />
                    </Modal.Content>
                </Modal>)}

                <Modal isOpen={openEndQuizDialog}>
                    <Modal.Content p={5}>
                        <Text>Ci sono risposte omesse, concludere comunque?</Text>
                        <Flex direction='row' justify={'end'} mt={3}>
                            <Button variant='ghost' onPress={handleDialogClose}>Chiudi</Button>
                            <Button onPress={() => { handleDialogClose(); nextQuestionCheck(); checkQuizInSection() }} variant='solid'>
                                Concludi questionario
                            </Button>
                        </Flex>

                    </Modal.Content>
                </Modal>

                <Modal maxWidth="md" w="md" isOpen={dialogSegnalazioneOpen}> {/*Dialog Segnalazioni*/}
                    <Modal.Content p={5}>
                        <Text fontSize='3xl'>Segnala un problema</Text>
                        <Box alignItems="center" w="100%">
                            <TextArea h={20} value={testoSegnalazione} onChangeText={value => changeTextSegnalazione(value)} placeholder='Spiegaci il problema che hai riscontrato' w="100%" />
                        </Box>;

                        <Box mt={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                            <Button variant="ghost" mr={1} onPress={openDialogSegnalazione}>Annulla</Button>
                            <Button variant='solid' onPress={sendSegnalazione}>Invia segnalazione</Button>
                        </Box>
                    </Modal.Content>
                </Modal>


                {!statisticheDialogOpen && (
                    <Modal isOpen={timeFinishedDialogOpen}>
                        <Modal.Content p={5}>
                            <Text>Il tempo a disposizione per rispondere alle domande della sezione selezionata è concluso.</Text>
                            <Button mt={2} variant="solid" onPress={nextSezioneTimeEnded}>Prosegui</Button>
                        </Modal.Content>
                    </Modal>
                )}





            </Box>

            <Helmet>
                <title>{nomeQuestionario}</title>
            </Helmet>
        </Fragment >
    )
}




function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let id = setInterval(() => {
            savedCallback.current();
        }, delay);
        return () => clearInterval(id);
    }, [delay]);
}
