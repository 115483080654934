import * as React from 'react';
import { useState, useEffect, Fragment, useRef } from 'react';
import { Box, Container, Modal, Flex, Center, Button, Text, Image, Skeleton } from 'native-base';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import MenuComponent from '../menu/menu';
import ListClassifica from '../shared/listClassifica';

//API
import { AuthAPI } from '../../api/authApi';
import { QuestionariAPI } from '../../api/questionariApi';

export default function Questionari(props) {
    const { t, i18n } = useTranslation();

    const modalRef = useRef(null)
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));
    const navigate = useNavigate();

    const [listQuestionari, setListQuestionari] = useState([]);
    const [classifica, setClassifica] = useState([]);
    const [modalOpen, setOpenModal] = useState(false);
    const [questionarioSelezionato, setQuestionarioSelezionato] = useState(undefined);
    const [questionarioId, setQuestionarioId] = useState();
    const [error500, setError500] = useState(false);

    useEffect(() => {
        getQuestionari();
    }, [])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));

                if (key === "getQuestionari") getQuestionari();
                if (key === "getClassifica") getClassifica(questionarioId);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        props.navigateToLogin();
                    }
                }
            })
    }

    async function getQuestionari() {
        QuestionariAPI.getQuestionari(tokenUser)
            .then((result) => {
                setListQuestionari([...result])
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                    }
                    if (error.response.status == 500) {
                        setError500(true)
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getQuestionari")
                    }
                }
            })
    }
    async function getClassifica(id) {
        QuestionariAPI.classificaQuestionario(id, tokenUser)
            .then((result) => {
                setClassifica([...result.data])
                setQuestionarioId(-1);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getClassifica")
                    }
                }
            })
    }

    function goToQuestionario() {
        document.body.style.overflow = "auto";
        navigate("/questionario?id=" + questionarioSelezionato.id, {
            state: {
                idQuestionario: questionarioSelezionato.id
            }
        })
    }
    function openModal(questionario) {
        setQuestionarioSelezionato(questionario);
        getClassifica(questionario.id);
        setQuestionarioId(questionario.id);

        setOpenModal(true);
        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }
    function closeModal() {
        document.body.style.overflow = "auto";
        setQuestionarioSelezionato({});
        setOpenModal(false);
    }

    return (
        <Fragment>

            <MenuComponent pageName="questionari" />
            {!error500 && (<Box h={'100vh'} w="full" padding={5}>
                <Container mt="5em" w="full" maxW={{ base: '100vw', md: '100%' }} justifyContent={"space-around"}>
                    {/* <Center h={'100vh'} w="full"> */}
                    {listQuestionari.length > 0 && (
                        <Flex w="full" direction={{ base: 'column', md: 'row' }} flexWrap={"wrap"}>
                            {listQuestionari.map((questionario) =>
                                <Box w={{ base: "full", md: "50%", lg: "25%" }} key={questionario.id} rounded="lg" overflow="hidden" p="2">
                                    <Flex direction='column' h="full" justify="space-between" borderWidth="1"
                                        borderColor="coolGray.200" p="4" rounded="lg">
                                        {questionario?.imgcopertina && (
                                            <Image source={{
                                                uri: questionario?.imgcopertina
                                            }} h="40" ignoreFallback={<Skeleton h="40" />} />
                                        )}
                                        <Text textAlign="center" my={4}>{questionario.nome}</Text>
                                        <Button onPress={() => openModal(questionario)}>Vai al questionario</Button>
                                    </Flex>
                                </Box>
                            )}
                        </Flex>
                    )}
                    {/* </Center> */}
                </Container>
            </Box>)}
            {!error500 && (<Modal isOpen={modalOpen} onClose={closeModal} size="xl">
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content id="modal"  >
                        <Modal.CloseButton />
                        <Modal.Body>
                            {questionarioSelezionato != undefined ? (
                                <Fragment>
                                    <Text bold pr={5}>{questionarioSelezionato.nome}</Text>
                                    <Box my={4} rounded="lg" overflow="hidden" borderWidth="1" borderColor="coolGray.300" p="4">
                                        <Center>
                                            <ListClassifica classificaQuestionarioSingolo={true} list={classifica} isModal={false} />
                                        </Center>
                                    </Box>
                                    <Button onPress={goToQuestionario}>Vai al questionario</Button>
                                </Fragment>
                            ) : (
                                <Text>Caricamento</Text>
                            )}
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>)}
            {error500 && (<Center h={"100vh"}>
                <Text fontSize="2xl" textAlign="center" bold>Siamo spiacenti, al momento non è possibile accedere ai questionari.</Text>
            </Center>)}

            <Helmet>
                <title>{t("name")} -Elenco questionari</title>
            </Helmet>
        </Fragment>
    )
}