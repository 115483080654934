import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const FileAPI = {
    upload: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/file/upload`,
            method: "POST",
            notSendToken: true,
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    download: async function (id, cancel = false) {
        const response = await api.request({
            url: `/file/${id}`,
            method: "GET",
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(FileAPI)