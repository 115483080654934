import './App.css';
import { Fragment } from 'react';
import Login from './components/auth/login';
import Registrazione from './components/auth/registrazione';
import ErrorPage from './components/errorPage';
import PwsRecover from './components/auth/pwsRecover';
import PwsReset from './components/auth/pwsReset';
import Home from './components/profile/home';
import Profile from './components/auth/profile';
import ProfileEdit from './components/profile/profileEdit';
import DashboardQuestionario from './components/quiz.js/dashboardQuestionario';
import Questionari from './components/quiz.js/questionari';
import VistaQuiz from './components/quiz.js/vistaQuiz';
import Chat from './components/profile/chat';
import { NativeBaseProvider, extendTheme } from "native-base";
import {
  Route, Routes
} from "react-router-dom";

let orangeTheme = {
  50: '#fff7ed',
  100: '#ffedd5',
  200: '#fed7aa',
  300: '#fdba74',
  400: '#fb923c',
  500: '#f97316',
  600: '#ea580c',
  700: '#c2410c',
  800: '#9a3412',
  900: '#7c2d12',
}
let limeTheme = {
  50: '#f7fee7',
  100: '#ecfccb',
  200: '#d9f99d',
  300: '#bef264',
  400: '#a3e635',
  500: '#84cc16',
  600: '#65a30d',
  700: '#4d7c0f',
  800: '#3f6212',
  900: '#365314',
}

const theme = extendTheme({
  colors: {
    primary: {
      50: '#f7fee7',
      100: '#ecfccb',
      200: '#d9f99d',
      300: '#bef264',
      400: '#a3e635',
      500: '#84cc16',
      600: '#65a30d',
      700: '#4d7c0f',
      800: '#3f6212',
      900: '#365314',
    },
  },

});



export default function App() {

  return (
    <Fragment>
      <NativeBaseProvider theme={theme}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/login" element={<Login />} />

          <Route path="/registrazione" element={<Registrazione />} />
          <Route path="/pwsrecover" element={<PwsRecover />} />
          <Route path="/pwsreset" element={<PwsReset />} />

          <Route path="/profile" element={<Profile />} />
          <Route path="/profile/edit" element={<ProfileEdit />} />
          <Route path="/questionari" element={<Questionari />} />
          <Route path="/questionario" element={<DashboardQuestionario />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/quiz/*" element={<VistaQuiz />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </NativeBaseProvider>
    </Fragment>
  );
}
