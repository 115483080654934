import * as React from 'react';
import { useState, useEffect, Fragment, useRef, useCallback } from 'react';
import { Box, Container, Modal, VStack, Flex, Center, Button, Avatar, Text, Divider, CloseIcon, Link, HStack, Spinner, Heading } from 'native-base';
import { useBreakpointValue } from 'native-base';

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import { Virtuoso } from "react-virtuoso";

import MenuComponent from '../menu/menu';
import Post from '../timeline/post';
import PostList from '../timeline/postList';
import HeaderProfile from '../shared/headerProfile';
import ListClassifica from '../shared/listClassifica';
import ListAmici from '../shared/listAmici';
import ListTraguardi from '../shared/listTraguardi';
//API
import { AuthAPI } from '../../api/authApi';
import { FileAPI } from '../../api/fileApi';
import { ProfileAPI } from '../../api/profileApi';
import { TimelineAPI } from '../../api/timelineApi';

export default function Home() {

    const navigate = useNavigate();
    const location = useLocation();

    const [showHome, setShowHome] = useState(false);

    const modalRef = useRef(null);
    const { t, i18n } = useTranslation();

    const user = JSON.parse(localStorage.getItem("user"));

    const [userProfile, setUserProfile] = useState(undefined);
    const [userPost, setUserPost] = useState([]);
    const [userFollower, setUserFollower] = useState([]);
    const [userFollowing, setUserFollowing] = useState(undefined);
    const [userBadges, setUserBadges] = useState([]);
    const [classifica, setClassifica] = useState([]);

    const [timelinePost, setTimelinePost] = useState([]);
    const [limit, setLimit] = useState(3);
    const [offset, setOffset] = useState(0);

    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [idProfile, setIdProfile] = useState(0)

    const [postSet, setPostSet] = useState();

    const [email, setEmail] = useState("");

    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState("");
    const [posts, setPost] = useState();

    const [openModal, setOpenModal] = useState(false);
    const [followerchange, setFollowerchange] = useState(false)

    const [viewAllAchievementsTab, setViewAllAchievementsTab] = useState(false);
    const [viewAllFriendsTab, setViewAllFriendsTab] = useState(false);
    const [viewLeaderboardTab, setViewLeaderboardTab] = useState(false);

    const [viewPost, setViewPost] = useState(true);

    const [follower, setFollower] = useState([]);
    const [following, setFollowing] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const flexDirection = useBreakpointValue({
        base: "column",
        lg: "row"
    });

    useEffect(() => {
        // const timeout = addTimelinePost()
        getTimelinePost(offset, limit)
        if (user != null) {
            classificaGenerale()
            getUserbyId(user.id)

            // getTimelinePost(offset, limit)
            getFollowerList();
            getFollowingList();

            dataPostProfile()
            setNome(user.nome);
            setCognome(user.cognome);
            setEmail(user.email);
            setDataDiNascita(user.datanascita.slice(0, 10));
            setUrlAvatarLocal(user.avatar.url)
        }
        // return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {
        if (location.state != null) {
            // console.log("IDprofilo", location.state.id)
            setIdProfile(location.state.id)
            getUserbyId(location.state.id)
        }
    }, [location.state])


    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                if (key === "dataPostProfile") dataPostProfile();
                if (key === "getUserbyId") getUserbyId(idProfile);
                if (key === "getFollowerList") getFollowerList();
                if (key === "getFollowingList") getFollowingList();
                if (key === "classificaGenerale") classificaGenerale();
                if (key === "getTimelinePost") getTimelinePost();
                window.location.reload()
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }

    async function getTimelinePost(offset, limit) {

        if (tokenUser != undefined) {
            if (offset == undefined) offset = 0;
            if (limit == undefined) limit = 25;
            TimelineAPI.timeline(tokenUser, offset, limit)
                .then((result) => {
                    if (result != undefined) setTimelinePost(generateRandomColoursAvatarPost([...timelinePost, ...result]));
                    setShowHome(true)
                })
                .catch((error) => {
                    if (error.response) {
                      /*  if (error.response.status == 404 || error.response.status == 500) {
                        }*/
                    }
                })
        } else { navigateToLogin(); }
    }

    function addTimelinePost() {
        setOffset(offset + 3);
        getTimelinePost(offset + 3, limit);
    }

    async function getFollowerList() {

        ProfileAPI.getFollowerList(tokenUser)
            .then((result) => {
                // setFollower(generateRandomColoursAvatar(result));
                setFollower(result)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getFollowerList")
                    }
                }
            })
    }
    async function getFollowingList() {

        ProfileAPI.getFollowingList(tokenUser)
            .then((result) => {
                // setFollowing(generateRandomColoursAvatar(result));
                setFollowing(result)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getFollowingList")
                    }
                }
            })
    }

    function goToProfile(id) {
        navigate("/profile?id=" + id, {
            replace: true,
            state: {
                id: id
            }
        });
        closeModal();

        //Imposto caricamento
        window.scrollTo({
            top: 0,
        });
        //getProfileByID(id);
        //Quando il getProfileByID ha restituito qualcosa allora il caricamento finisce

    }

    async function getUserbyId(id) {

        if (id != undefined) {
            setIsLoading(true)
            ProfileAPI.getProfile(id, tokenUser)
                .then((result) => {
                    setProfile(result)
                    setIsLoading(false)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404 || error.response.status == 500) {
                        }
                        if (error.response.status == 403) {
                            tokenCheck("getUserbyId")
                        }
                    }
                })
        }
    }

    async function classificaGenerale() {

        ProfileAPI.classifica(tokenUser)
            .then((result) => {
                let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                let tmpUtenti = [];
                for (let utente of result) {
                    let previouseRandom = -1;
                    let randomIndex;
                    do {
                        randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    } while (randomIndex === previouseRandom);
                    previouseRandom = randomIndex;
                    utente.bgColor = coloursList[randomIndex];
                    tmpUtenti.push(utente);
                }
                setClassifica(tmpUtenti);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("classificaGenerale")
                    }
                }
            })
    }

    function setProfile(data) {
        setUserProfile(data.user);
        if (data.followers != undefined) setUserFollower(generateRandomColoursAvatar(data.followers));
        if (data.following != undefined) setUserFollowing(generateRandomColoursAvatar(data.following));
        setUserBadges(data.badges)
    }

    async function dataPostProfile() {
        ProfileAPI.dataPostProfile(tokenUser)
            .then((result) => {
                let tmpPosts = [];
                for (let post of result) {
                    let postTmp = post;
                    let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                    let randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    postTmp.post_owner.avatar.bgColor = coloursList[randomIndex];
                    tmpPosts.push(postTmp);
                }
                localStorage.setItem("posts", JSON.stringify(tmpPosts));
                setPostSet(tmpPosts);
            })
            .catch((error) => {
                if (error.response) {
                    if (localStorage.getItem("posts") != null)
                        setPostSet(JSON.parse(localStorage.getItem("posts")));

                    if (error.response.status == 403) {
                        tokenCheck("dataPostProfile")
                    }
                }
            })

    }
    function closeTab() { //Da eliminare?
        setViewAllAchievementsTab(false);
        setViewAllFriendsTab(false);
        setViewLeaderboardTab(false);
    }
    function openViewAllAchievementsTab() {
        openModalFunction();
        setViewAllAchievementsTab(true);
        setViewAllFriendsTab(false);
        setViewLeaderboardTab(false);
    }
    function openViewAllFriendsTab() {
        openModalFunction();
        setViewAllFriendsTab(true);
        setViewAllAchievementsTab(false);
        setViewLeaderboardTab(false);
    }
    function openViewLeaderboardTab() {
        openModalFunction();
        setViewLeaderboardTab(true);
        setViewAllAchievementsTab(false);
        setViewAllFriendsTab(false);
    }

    function generateRandomColoursAvatar(list) {
        let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
        let tmpUtenti = [];
        // console.log("Lista - no colori", list)
        for (let utente of list) {
            let previouseRandom = -1;
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * coloursList.length) + 1
            } while (randomIndex === previouseRandom);
            previouseRandom = randomIndex;
            utente.utente.bgColor = coloursList[randomIndex];
            tmpUtenti.push(utente);
        }
        // console.log("Lista - colori", tmpUtenti)
        return tmpUtenti;
    }

    function generateRandomColoursAvatarPost(list) {
        let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
        let tmpUtenti = [];
        for (let utente of list) {
            let previouseRandom = -1;
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * coloursList.length) + 1
            } while (randomIndex === previouseRandom);
            previouseRandom = randomIndex;
            utente.post_owner.avatar.bgColor = coloursList[randomIndex];
            tmpUtenti.push(utente);
        }
        return tmpUtenti;
    }

    function openModalFunction() {
        setOpenModal(true);

        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }

    function closeModal() {
        document.body.style.overflow = "auto";
        setOpenModal(false);
    }

    function navigateToLogin() {
        navigate("/login")
    }

    function changeModalListFollow() {
        setFollowerchange(!followerchange)
    }

    return (

        <Fragment>
            {!showHome && (
                <Box h={"100vh"}>
                    <Center h="full">
                        <Box>
                            <Spinner size="lg" />
                            <Heading mt={15} color="primary.600" fontSize="md">
                                Caricamento...
                            </Heading>
                        </Box>
                    </Center>
                </Box>
            )}
            {showHome && (<Box w={"full"}>
                <MenuComponent pageName="home" ListClassifica={classifica} listFollower={userFollower} listFollowing={userFollowing} goToLogin={navigateToLogin} />
                {isLoading ? (
                    <Box h={"100vh"}>
                        <Center h="full">
                            <Box>
                                <Spinner size="lg" />
                                <Heading mt={15} color="primary.600" fontSize="md">
                                    Caricamento...
                                </Heading>
                            </Box>
                        </Center>
                    </Box>)
                    : (
                        <Center mt={"6em"}>
                            <Container w="full" maxW={{ base: '95vw', md: '80%' }}>
                                <Flex m={{ base: "0", md: "4" }} direction={flexDirection} w="full">

                                    {/* {viewPost && (<PostList userPost={timelinePost} addTimelinePost={addTimelinePost} />)} */}

                                    {viewPost && (<Box w={{ base: "100%", lg: "65%" }} mt={{ base: 4, md: 0 }}>
                                        {(timelinePost != undefined > 0) ? (
                                            <Virtuoso
                                                style={{ height: "100vh", width: "100%", margin: "0" }}
                                                data={timelinePost}
                                                endReached={addTimelinePost}
                                                overscan={2}
                                                itemContent={(index, post) =>
                                                    <Post key={index} postData={post} />
                                                }
                                                components={<div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>Caricamento...</div>}
                                            />) : (
                                            <Center h="50vh">
                                                <Text bold fontSize="2xl">Nessun post trovato.</Text>
                                            </Center>
                                        )}
                                    </Box>)}


                                    <VStack display={{base:'none', md:'flex'}} w={viewPost ? ("35%") : ("100%")}>
                                        {!viewPost && (<Box w={"100%"} alignItems={"flex-end"}>
                                            <Button textAlign={"center"} padding={0} variant={"unstyled"} onPress={closeTab}><CloseIcon />
                                            </Button>
                                        </Box>)}
                                        {(flexDirection !== "column" || !viewPost) && (
                                            <Box>

                                                {/*I tuoi amici */}
                                                <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{ shadow: 2, borderWidth: 0 }} backgroundColor="gray.50" m="2">

                                                    {/* <Modal.Header p={0} m={0}> */}
                                                    <Flex direction='row' w={"100%"} h={"50px"} borderBottomColor="coolGray.200" borderBottomWidth={'1px'}>
                                                        <Button w={"50%"} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '0px' : '2px'} _text={followerchange ? { color: "gray.300", fontWeight: "bold" } : { color: "black", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow} >
                                                            {t("pages.profile.following")}
                                                        </Button>
                                                        <Button w={"50%"} m={0} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '2px' : '0px'} _text={followerchange ? { color: "black", fontWeight: "bold" } : { color: "gray.300", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow}>
                                                            {t("pages.profile.follower")}
                                                        </Button>
                                                    </Flex>
                                                    {/* </Modal.Header> */}

                                                    {/* <ListAmici goToUser={goToProfile} list={userFollowing} isModal={false} /> */}
                                                    <Box padding={4}>
                                                        {!followerchange && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollowing} isModal={false} />)}
                                                        {followerchange && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollower} isModal={false} />)}
                                                        <Button testid="button-friends" m="2" onPress={openViewAllFriendsTab}>
                                                            {t("pages.profile.viewAllFriends")}
                                                        </Button>
                                                    </Box>
                                                </Box>

                                                {/*Classifica generale */}
                                                <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{ shadow: 2, borderWidth: 0 }} backgroundColor="gray.50" m="2" padding={4}>
                                                    {/* <UserList list={elencoUtenti} titolo={"Classifica generale"} /> */}
                                                    <ListClassifica list={classifica.slice(0, 10)} goToUser={goToProfile} goToLogin={navigateToLogin}/>
                                                    {(classifica.length > 10 && classifica != []) && (<Divider />)}
                                                    {(classifica.length > 10 && classifica != []) && (<Box m={2}>
                                                        <Flex direction="row" align="center" justify="space-between">
                                                            <Flex direction='row' alignItems="center">
                                                                <Text bold>
                                                                    {classifica[10].posizione}°
                                                                </Text>
                                                                <Button variant="unstyled" disabled>
                                                                    <Avatar bg="primary.500" alignSelf="center" source={{
                                                                        uri: classifica[10].avatar.url
                                                                    }}>
                                                                        {classifica[10].nome.slice(0, 1).toUpperCase() + classifica[10].cognome.slice(0, 1).toUpperCase()}
                                                                    </Avatar>
                                                                </Button>
                                                                <Text bold textAlign={"center"}>
                                                                    {classifica[10].nome} {classifica[10].cognome} (Tu)
                                                                </Text>
                                                            </Flex>
                                                            <Text bold textAlign={"center"}>
                                                                {classifica[10].punteggio}
                                                            </Text>
                                                        </Flex>
                                                    </Box>)}
                                                    <Button testid="button-leaderboard" m="2" onPress={openViewLeaderboardTab}>
                                                        {t("pages.profile.viewLeaderboard")}
                                                    </Button>
                                                </Box>
                                            </Box>)}
                                    </VStack>
                                </Flex>
                            </Container>
                        </Center>)}

                <Helmet>
                    <title>{t("name")} -Home</title>
                </Helmet>
            </Box>)}

            <Modal isOpen={openModal} size={"lg"} onClose={closeModal} >
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content  >
                        <Modal.CloseButton testid="button-close-modal" />
                        <Modal.Header>
                            {!viewAllFriendsTab ? (
                                <Fragment>
                                    {viewLeaderboardTab && (t("pages.profile.leaderboardTitleModal"))}
                                    {viewAllAchievementsTab && (t("pages.profile.achievementsTitleModal"))}
                                </Fragment>
                            ) : (
                                <Text>Elenco {!followerchange ? 'seguiti' : "followers"}</Text>
                            )}
                        </Modal.Header>

                        <Modal.Body h={"full"}>
                            {viewAllFriendsTab && (
                                <Flex direction='row' w={"100%"} h={"50px"} borderBottomColor="coolGray.200" borderBottomWidth={'1px'}>
                                    <Button testid="button-following" w={"50%"} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '0px' : '2px'} _text={followerchange ? { color: "gray.300", fontWeight: "bold" } : { color: "black", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow} >
                                        {t("pages.profile.following")}
                                    </Button>
                                    <Button testid="button-followers" w={"50%"} m={0} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '2px' : '0px'} _text={followerchange ? { color: "black", fontWeight: "bold" } : { color: "gray.300", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow}>
                                        {t("pages.profile.follower")}
                                    </Button>
                                </Flex>
                            )}
                            {viewLeaderboardTab && (
                                <ListClassifica goToUser={goToProfile} goToLogin={navigateToLogin} list={classifica.slice(0, 10)} isModal={true} closeModal={closeModal} />
                            )}
                            {(viewAllFriendsTab && !followerchange) && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollowing} isModal={true} closeModal={closeModal} />)}
                            {(viewAllFriendsTab && followerchange) && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollower} isModal={true} closeModal={closeModal} />)}
                            {viewAllAchievementsTab && (
                                <ListTraguardi userBadges={userBadges} viewAllAchievements={true} isModal={true} />
                            )}
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>

        </Fragment>
    );


}