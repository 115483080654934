import * as React from 'react';
import { useState, useEffect, createRef } from 'react';
import { Text, Center, Flex, Box, Input, Slider } from "native-base";
import { useTranslation } from 'react-i18next';

export default function Materia(props) {

    const { t, i18n } = useTranslation();
    let nQuizRef = createRef(null);
    let sliderRef = createRef(null);

    const [sezione, setSezione] = useState(props.sezione)
    const [indexMateria, setIndexMateria] = useState(0)

    const [simulazioneView, setSimulazioneView] = useState(false)
    const [esercitazioneView, setEsercitazioneView] = useState(false)
    const [sequenzialeView, setSequenzialeView] = useState(false)

    const [valueSliderNQuiz, setValueSliderNQuiz] = useState()
    const [valueSliderQuizStart, setValueSliderQuizStart] = useState(0)

    useEffect(() => {
        if (props.index != undefined) {
            setIndexMateria(props.index);
        }
    }, [props.index])

    useEffect(() => {
        if (props.simulazioneView != undefined) {
            setSimulazioneView(props.simulazioneView);
        }
    }, [props.simulazioneView])

    useEffect(() => {
        if (props.esercitazioneView != undefined) {
            setEsercitazioneView(props.esercitazioneView);
        }
    }, [props.esercitazioneView])

    useEffect(() => {
        if (props.sequenzialeView != undefined) {
            setSequenzialeView(props.sequenzialeView);
        }
    }, [props.sequenzialeView])

    useEffect(() => {
        if (props.sezione != undefined) {
            setSezione(props.sezione);
        }
    }, [props.sezione])



    function changeNumeroQuizToParent(e) {
       props.changeNumeroQuiz(e, props.index);
    }

    /*function changeStartQuizToParent(e) {
        setValueSliderQuizStart(e)
        // props.changeStartQuiz(e, props.index);
    }*/

    function changeQuizBySlider(e) {
        let newValue = Math.floor(e)
        if (nQuizRef.current != null) {
            nQuizRef.current.value = newValue;
        }
        setValueSliderNQuiz(e)
        //Non mettiamo nessun setState, quello viene triggherato quando finisci di spostare lo slider
    }

    function chaneQuizValue(e) {
        const re = /^[0-9\b]+$/;
        if (e === '' || re.test(e)) { //Procedi solo se è un valore numerico
            if (Math.floor(e) < sezione.quizDisponibili) {
                let newValue = Math.floor(e)
                if (nQuizRef.current != null) {
                    nQuizRef.current.value = newValue;
                }
                if (sliderRef.current != null) {
                    sliderRef.current.value = newValue;
                }
                setValueSliderNQuiz(newValue)
                props.changeNumeroQuiz(newValue, props.index);
            } else { //Se metto un numero maggiore dei quiz disponbilli
                let quizMax = Math.floor(sezione.quizDisponibili)
                nQuizRef.current.value = quizMax;
                setValueSliderNQuiz(quizMax)
                props.changeNumeroQuiz(quizMax, props.index);
            }
        }
    }

    return (
        <Box w={{ base: "100%", md: "50%", lg: "25%" }} textAlign={"center"} padding={2}  >
            <Box w="100%" textAlign={"center"} rounded={"lg"} padding={5} shadow={2} borderColor="coolGray.100" borderWidth="1" overflow="hidden">
                <Text bold >{sezione.nomeParte}</Text>
                {simulazioneView ? ( //Simulazione
                    <Text>{t("pages.quiz.totalQuantity")}
                        <b> {sezione.numQuiz}</b>
                    </Text>
                ) : ( //Esercitazione e sequenziale
                    <Center mt="2">
                        <Flex direction='row' alignItems={"center"}>
                            <Center m={2}>
                                <Text>{t("pages.quiz.totalQuantity")}</Text>
                            </Center>
                            <Input type="number" pattern="[0-9]*" backgroundColor={"white"} ref={nQuizRef} onChangeText={chaneQuizValue} defaultValue={sezione.numQuiz} w={20} textAlign={"center"} />
                            <Center m={2}>
                                <Text> di {sezione.quizDisponibili}</Text>
                            </Center>
                        </Flex>
                    </Center>
                )}
                {(esercitazioneView || sequenzialeView) && (
                    <Center>
                        <Slider w="3/4" maxW="300" colorScheme={"violet"} value={valueSliderNQuiz}  defaultValue={sezione.numQuiz} minValue={0} maxValue={sezione.quizDisponibili} step={1}
                            onChange={(newValue) => changeQuizBySlider(newValue)} onChangeEnd={v => { changeNumeroQuizToParent(v) }}>
                            <Slider.Track>
                                <Slider.FilledTrack />
                            </Slider.Track>
                            <Slider.Thumb />
                        </Slider>
                    </Center>
                )}
                {/* {sequenzialeView && (
                    <React.Fragment>
                        <Center>
                            <Flex direction='row' alignItems={"center"}>
                                <Center m={2}>
                                    <Text>{t("pages.quiz.start")}</Text>
                                </Center>
                                <Input backgroundColor={"white"} ref={nQuizRef} onChangeText={chaneQuizValue} value={valueSliderQuizStart} w={20} textAlign={"center"} />
                                <Center m={2}>
                                    <Text> di {valueSliderNQuiz}</Text>
                                </Center>
                            </Flex>
                        </Center>
                        <Center>
                            <Slider w="3/4" maxW="300" colorScheme={"violet"} defaultValue={0} minValue={0} maxValue={valueSliderNQuiz}   step={1}
                                onChange={(newValue) => changeQuizBySlider(newValue)} onChangeEnd={v => { changeStartQuizToParent(v) }}>
                                <Slider.Track>
                                    <Slider.FilledTrack />
                                </Slider.Track>
                                <Slider.Thumb />
                            </Slider>
                        </Center>
                    </React.Fragment>
                )} */}
            </Box>
        </Box>
    );
}