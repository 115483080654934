import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ProfileAPI = {
    getProfile: async function (id, token, cancel = false) {
        const response = await api.request({
            url: `/utente/${id}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editProfile: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/utente/edit`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    dataPostProfile: async function (token, cancel = false) {
        const response = await api.request({
            url: `/timeline/me`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getFollowerList: async function (token, cancel = false) {
        const response = await api.request({
            url: `/utente/follower`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    getFollowingList: async function (token, cancel = false) {
        const response = await api.request({
            url: `/utente/following`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    follow: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/interazione/follow`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    unfollow: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/interazione/unfollow`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    facolta: async function (cancel = false) {
        const response = await api.request({
            url: `/utente/listaFacolta`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    classifica: async function (token, cancel = false) {
        const response = await api.request({
            url: `/utente/classifica/generale`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(ProfileAPI)