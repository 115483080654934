import { api } from "./configs/oldAxiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const AuthAPI = {
    login: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/auth/login`,
            method: "POST",
            notSendToken: true,
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    logout: async function (token ,cancel = false) {
        const response = await api.request({
            url: `/utente/logout`,
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    registrazione: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/utente/registrazione`,
            method: "POST",
            notSendToken: true,
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    editProfile: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/utente/edit`,
            method: "POST",
            data: obj,
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    dataPostProfile: async function (token, cancel = false) {
        const response = await api.request({
            url: `/timeline/me`,
            method: "GET",
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    validazioneUtenteGet: async function (token, email, cancel = false) {
        const response = await api.request({
            url: `/utente/verificaemail?token=${token}&email=${email}`,
            method: "GET",
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    validazioneUtentePost: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/utente/verificaemail`,
            method: "POST",
            data: obj,
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    emailCheck: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/utente/emailunica`,
            method: "POST",
            data: obj,
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    refreshToken: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/auth/refreshToken`,
            method: "POST",
            data: obj,
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    resetPws: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/utente/resetpassword`,
            method: "POST",
            data: obj,
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
    updatePws: async function (obj, cancel = false) {
        const response = await api.request({
            url: `/utente/updatepassword`,
            method: "POST",
            data: obj,
            notSendToken: true,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(AuthAPI)