import * as React from 'react';
import { useState, useEffect, Fragment, useRef } from 'react';
import { Flex, Container, Modal, Button, Input, Box, Image, Center, Avatar, HStack, Text, Menu, CloseIcon, Actionsheet, useDisclose, useBreakpointValue } from 'native-base';



import { AuthAPI } from '../../api/authApi';

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ListClassifica from '../shared/listClassifica';
import ListAmici from '../shared/listAmici';

import { ProfileAPI } from '../../api/profileApi';
import { faHome, faSearch, faUserGroup, faMedal } from '@fortawesome/free-solid-svg-icons'

export default function MenuMobileComponent(props) {


    const modalRef = useRef(null);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));


    const [nome, setNome] = useState("");
    const [username, setUsername] = useState("");
    const [cognome, setCognome] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState(`${process.env.REACT_APP_FETCH_URL}static/uploads/placeholder.jpg`);



    const [token, setToken] = useState(tokenUser);
    const [refreshToken, setRefreshToken] = useState(refreshTokenUser);

    const [pageName, setPageName] = useState("");



    const [classifica, setClassifica] = useState([]);
    const [userFollowing, setFollowing] = useState([]);
    const [userFollower, setFollower] = useState([]);
    const [followerchange, setFollowerchange] = useState(false)

    const [isModalClassificaOpen, setIsModalClassificaOpen] = useState(false);
    const [isModalFollowersOpen, setIsModalFollowersOpen] = useState(false);


    useEffect(() => {
        setToken(tokenUser);
        setRefreshToken(refreshTokenUser);
        // if(localStorage.getItem("classifica") != null) Manda in errore tutto
        //     setClassifica(localStorage.getItem("classifica"));
        setNome(user.nome);
        setCognome(user.cognome);
        setUsername(user.username);
        setUrlAvatarLocal(user.avatar.url);
    }, [])

    // useEffect(() => { NON FUNZIONA
    //     if(props.ListClassifica != undefined)
    //     setClassifica(props.ListClassifica)
    // }, [props.ListClassifica])



    useEffect(() => {
        if (props.listFollower != undefined)
            setFollower(props.listFollower)
    }, [props.listFollower])

    useEffect(() => {
        if (props.listFollowing != undefined)
            setFollowing(props.listFollowing)
    }, [props.listFollowing])

    useEffect(() => {
        setPageName(props.pageName)
    }, [props.pageName])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                // if (key === "search") search();
                // if (key === "getProfile") getProfile();
                if (key === "logout") logout();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }
    function navigateToLogin() {
        navigate("/login")
    }

    function navigateToProfile() {
        navigate("/profile?id=" + user.id, {
            replace: true,
            state: {
                id: user.id
            }
        });

        //Imposto caricamento
        window.scrollTo({
            top: 0,
        });
        //getProfileByID(id);
        //Quando il getProfileByID ha restituito qualcosa allora il caricamento finisce
    }

    function navigateToProfileEdit() {
        navigate("/profile/edit")
    }

    async function getClassificaList() {
        ProfileAPI.classifica(tokenUser)
            .then((result) => {
                // console.log("Classifica", result)
                let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                let tmpUtenti = [];
                for (let utente of result) {
                    let previouseRandom = -1;
                    let randomIndex;
                    do {
                        randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    } while (randomIndex === previouseRandom);
                    previouseRandom = randomIndex;
                    utente.bgColor = coloursList[randomIndex];
                    tmpUtenti.push(utente);
                }
                localStorage.setItem("classifica", tmpUtenti);
                setClassifica(tmpUtenti);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("classificaGenerale")
                    }
                }
            })
    }

    async function logout() {

        AuthAPI.logout(tokenUser)
            .then((result) => {
                localStorage.clear();
                navigateToLogin();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                        // setMessageDisplay(true);
                        // setErrorMessageLogin(error.response.data.description);
                    }
                    if (error.response.status == 403) {
                        tokenCheck("logout")
                    }
                }
            })
    }

    function openClassificaModal() {
        getClassificaList();
        setIsModalClassificaOpen(true);
        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }
    function closeClassificaModal() {
        document.body.style.overflow = "auto";
        setIsModalClassificaOpen(false);
    }

    function openFollowersModal() {
        setIsModalFollowersOpen(true);
        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }
    function closeFollowersModal() {
        document.body.style.overflow = "auto";
        setIsModalFollowersOpen(false);
    }

    function changeModalListFollow() {
        setFollowerchange(prev => !prev);
    }



    const {
        isOpen,
        onOpen,
        onClose
    } = useDisclose();


    function navigateToHome() {
        props.navigateToHome();
    }
    function navigateToQuestionari() {
        props.navigateToQuestionari();
    }
    function openSearchModal() {
        props.openSearch();
    }

    function goToProfile(id) {
        setIsModalClassificaOpen(false);
        setIsModalFollowersOpen(false)
        props.goToProfile(id);
    }


    return (
        <Fragment>
            <Box w="full" borderTopWidth="1px" borderColor="coolGray.300" bgColor="white" position={'fixed'} left={0} bottom={0} zIndex={10} paddingTop={2} paddingBottom={2} >
                <Center px={8}>
                    <HStack w="full" space={1} justifyContent="space-between" >

                        <Button variant="unstyled" onPress={navigateToHome}>
                            <Text fontSize="2xl" color={pageName == "home" ? 'primary.600' : 'black'}>
                                <FontAwesomeIcon icon={faHome} />
                            </Text>
                        </Button>

                        <Box>
                            <Button onPress={onOpen} p={2} variant="unstyled" leftIcon={
                                urlAvatarLocal != `${process.env.REACT_APP_FETCH_URL}static/uploads/placeholder.jpg` ? (
                                    <Image alt="avatar" size="sm" borderRadius={100} bg="violet.600" source={{ uri: urlAvatarLocal }} />
                                ) : (
                                    <Avatar bg="violet.600" alignSelf="center" size="md">
                                        {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                    </Avatar>
                                )}>
                            </Button>
                            <Actionsheet isOpen={isOpen} onClose={onClose}>
                                <Actionsheet.Content>
                                    {pageName != "home" && (
                                        <Actionsheet.Item onPress={navigateToHome} >{t("menu.home")}</Actionsheet.Item>
                                    )}
                                    {pageName != "profile" && (
                                        <Actionsheet.Item onPress={navigateToProfile} >{t("menu.myProfile")}</Actionsheet.Item>
                                    )}
                                    <Actionsheet.Item onPress={navigateToQuestionari}>Questionari</Actionsheet.Item>
                                    <Actionsheet.Item onPress={openFollowersModal}>Elenco followers</Actionsheet.Item>
                                    <Actionsheet.Item onPress={openClassificaModal}>Classifica generale</Actionsheet.Item>
                                    <Actionsheet.Item onPress={navigateToProfileEdit}>{t("menu.editProfile")}</Actionsheet.Item>
                                    <Actionsheet.Item onPress={logout}>Logout</Actionsheet.Item>
                                </Actionsheet.Content>
                            </Actionsheet>
                        </Box>

                        <Button variant="unstyled" onPress={openSearchModal}>
                            <Text fontSize="2xl" >
                                <FontAwesomeIcon icon={faSearch} />
                            </Text>
                        </Button>

                        {/* <Button variant="unstyled" onPress={openClassificaModal}>
                        <Text >
                            <FontAwesomeIcon icon={faUserGroup} size="xl" />
                        </Text>
                    </Button> */}
                        {/* <Button variant="unstyled">
                        <Text>
                            <FontAwesomeIcon icon={faMedal} size="xl" />
                        </Text>
                    </Button> */}

                    </HStack>
                </Center>
            </Box>

            <Modal isOpen={isModalClassificaOpen} onClose={closeClassificaModal} size={"lg"}  >
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content  >
                        <Modal.CloseButton />
                        <Modal.Header>Classifica generale</Modal.Header>
                        <Modal.Body h={"full"}>
                            <ListClassifica goToUser={goToProfile} list={classifica.slice(0, 10)} isModal={true} />
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>


            <Modal isOpen={isModalFollowersOpen} size={"lg"} onClose={closeFollowersModal} >
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content  >
                        <Modal.CloseButton />
                        <Modal.Header>
                            <Text>
                                Elenco {!followerchange ? 'seguiti' : "followers"}
                            </Text>
                        </Modal.Header>
                        <Modal.Body h={"full"}>
                            <Flex direction='row' w={"100%"} h={"50px"} borderBottomColor="coolGray.200" borderBottomWidth={'1px'}>
                                <Button w={"50%"} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '0px' : '2px'} _text={followerchange ? { color: "gray.300", fontWeight: "bold" } : { color: "black", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow} >
                                    {t("pages.profile.following")}
                                </Button>
                                <Button w={"50%"} m={0} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '2px' : '0px'} _text={followerchange ? { color: "black", fontWeight: "bold" } : { color: "gray.300", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow}>
                                    {t("pages.profile.follower")}
                                </Button>
                            </Flex>
                            {(!followerchange) && (
                                <ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollowing} isModal={true} closeModal={closeFollowersModal} />)}
                            {(followerchange) && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollower} isModal={true} closeModal={closeFollowersModal} />)}
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>
        </Fragment>
    );
}



