import axios from "axios"

export const api = axios.create({
    baseURL: process.env.REACT_APP_FETCH_URL,
})

const errorHandler = async (error) => {
    const statusCode = error.response?.status
    if (statusCode === 403) {
        if (localStorage.getItem('refreshToken')) {
            const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
            await getRefreshToken(refreshToken);
            return api.request(error.config);
        } else logout();
    } else if (statusCode && statusCode == 401) {
        logout();
    }

    return Promise.reject(error)
}

api.interceptors.response.use(undefined, (error) => {
    return errorHandler(error)
})

api.interceptors.request.use((config) => {
     if (config.notSendToken === undefined || config.notSendToken === false) {
         const token = JSON.parse(localStorage.getItem("token"));
    config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});


const getRefreshToken = async (refreshToken) => {
    try {
        const response = await axios.post(process.env.REACT_APP_FETCH_URL + '/auth/refreshToken', { refreshToken });
        let refreshTokenTmp = response.data.refreshToken;
        localStorage.setItem("refreshToken", JSON.stringify(refreshTokenTmp));
        let token = response.data.refreshToken;
        localStorage.setItem("token", JSON.stringify(token));
    } catch (error) {
        if (error.response?.status === 403 || error.response?.status === 404 ) {
            logout();
        }
    }
}


function logout() {
    localStorage.clear();
    window.location.replace("/login");
}