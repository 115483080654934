import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Avatar, Box, Button, Center, Flex, Text, Link } from 'native-base';
import { Virtuoso } from 'react-virtuoso';

import { ProfileAPI } from '../../api/profileApi';
import { AuthAPI } from '../../api/authApi';

import { useNavigate } from "react-router-dom";

export default function ListAmici(props) {


    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [listUser, setListUser] = useState([])
    const [titoloList, setTiloloList] = useState("")
    const [listSet, setListSet] = useState(() => []);
    const [isModal, setIsModal] = useState(false);
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        if (props.list != undefined) {
            setListUser(props.list);
            setListSet(props.list.slice(0, 5))
        }
    }, [props.list])

    useEffect(() => {
        if (props.titolo != undefined) { setTiloloList(props.titolo) }
    }, [props.titolo])


    useEffect(() => {
        if (props.isModal != undefined) { setIsModal(props.isModal) }
    }, [props.isModal])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                if (key === "getProfile") getProfile();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }

    async function getProfile(id) {

        if (id != 0) {
            ProfileAPI.getProfile(id, tokenUser)
                .then((result) => {
                    setIsModal(false)
                    localStorage.setItem("usertmp", JSON.stringify(result));
                    navigateToProfile()

                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404 || error.response.status == 500) {
                        }
                        if (error.response.status == 403) {
                            tokenCheck("getProfile")
                        }
                    }
                })
        }
    }

    const loadMore = useCallback(() => {
        return setTimeout(() => {
            if (listUser != undefined) {
                let newPost = listUser.slice(listSet.length + 1, listSet.length + 5)
                setListUser((oldSet) => [...oldSet, ...newPost])
                setListSet((oldSet) => [...oldSet, ...newPost])
            }
        }, 200)
    }, [setListSet])

    //TODO: da verificare mancanza ID utente nel json <--------------- <---------------
    // useEffect(() => {
    //     if (userId != 0 && userId != undefined) {
    //         console.log(userId)
    //         getIdUser(userId);
    //     }
    // }, [userId])

    //TODO: da verificare mancanza ID utente nel json <--------------- <---------------
    // function getIdUser(id) {
    //     if (id != undefined) props.getIdUser(id)
    // }

    function getIdUser(id) {
        getProfile(id)
    }

    function navigateToLogin() {
        props.goToLogin();
    }

    function navigateToProfile() {
        
        window.scrollTo({
            top: 0,
            // behavior: 'smooth'
        });
    }
    function goToUser(id) {
        props.goToUser(id);
    }
    return (

        <Box w={"100%"}>
            {listUser.length > 0 ? (
                <Box m={2}>
                    {/* {!isModal && (
                        <Text color={"black"} fontWeight="bold">Lista amici</Text>
                    )} */}
                    <Virtuoso
                        style={{ height: isModal ? "50vh" : "200px", width: "100%", margin: "0" }}
                        data={listUser}
                        endReached={loadMore}
                        overscan={2}
                        itemContent={(index, userFromList) => {
                            return (
                                <Flex justify="space-between" align="center" key={index} direction="row" >
                                    <Flex direction='row' alignItems="center">
                                        <Button testid='button-goto-user' mx="4" variant="unstyled" onPress={() => goToUser(userFromList.utente.id)}>
                                            {userFromList.utente.avatar.id != 0 ? (
                                                <Avatar alignSelf="center" source={{uri: userFromList.utente.avatar.url}}/>
                                            ) : (
                                                <Avatar bg={userFromList.utente.bgColor} alignSelf="center">
                                                    {userFromList.utente.nome.slice(0, 1).toUpperCase() + userFromList.utente.cognome.slice(0, 1).toUpperCase()}
                                                </Avatar>
                                            )}
                                            {/* <Avatar bg={userFromList.utente.bgColor} alignSelf="center" source={{
                                                uri: userFromList.utente.avatar.id != 0 ? userFromList.utente.avatar.url : ""
                                            }}>
                                                {userFromList.utente.nome.slice(0, 1).toUpperCase() + userFromList.utente.cognome.slice(0, 1).toUpperCase()}
                                            </Avatar> */}
                                        </Button>

                                        <Link onPress={() => goToUser(userFromList.utente.id)}>
                                            <Text >
                                                {userFromList.utente.nome} {userFromList.utente.cognome}
                                            </Text>
                                        </Link>
                                    </Flex>
                                </Flex>)
                        }
                        }
                        components={<div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>Caricamento...</div>}
                    />
                </Box>
            ) : (
                <Center h="200px">
                    <Text bold fontSize="md" textAlign={'center'}>Nessun utente da mostrare</Text>
                </Center>
            )
            }
        </Box >
    )
}