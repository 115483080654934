import * as React from 'react';
import { useState, useEffect, Fragment, useRef } from 'react';
import { Box, HStack, Stack, VStack, Flex, Center, Heading, Button, Text, Avatar, AspectRatio, Image, Tooltip, Modal, Divider } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart, faThumbsUp, faSmile } from '@fortawesome/free-solid-svg-icons'
import { faThumbsUp as faThumbsUpReg, faHeart as faHeartReg, faSmile as faSmileReg } from '@fortawesome/free-regular-svg-icons';

import { useNavigate } from "react-router-dom";

//API
import { AuthAPI } from '../../api/authApi';
import { ProfileAPI } from '../../api/profileApi';
import { ReactionAPI } from '../../api/reactionApi';

export default function Post(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));
    const modalRef = useRef(null);

    const navigate = useNavigate();

    const [likeBadge, setLikeBadge] = useState(0);
    const [likeSelect, setLikeSelect] = useState(false);
    const [loveBadge, setLoveBadge] = useState(0);
    const [loveSelect, setLoveSelect] = useState(false);
    const [faceBadge, setFaceBadge] = useState(0);
    const [faceSelect, setFaceSelect] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const [dataPost, setDataPost] = useState(props.postData);

    const [bodyReactionTmp, setBodyReactionTmp] = useState({});

    useEffect(() => {
        if (props.postData != undefined) {
            // setReaction(props.postData.reaction);
            setDataPost(props.postData);
            setLikeBadge(filterReactionById(props.postData.reaction, 1))
            setLoveBadge(filterReactionById(props.postData.reaction, 2))
            setFaceBadge(filterReactionById(props.postData.reaction, 3))
        }
    }, [props.postData])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                if (key === "getProfile") getProfile();
                if (key === "reactPost") reactPost(bodyReactionTmp.postId, bodyReactionTmp.reactionId, bodyReactionTmp.action);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }

    async function reactPost(idPost, idReaction, action) {

        let dataTmp = {
            postId: idPost,
            reactionId: idReaction,
            action: action,
        }

        setBodyReactionTmp(dataTmp);

        ReactionAPI.reaction(dataTmp, tokenUser)
            .then((result) => {

            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                    }
                    if (error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("reactPost")
                    }
                }
            })
    }

    async function getProfile(id) {

        if (id != 0) {
            ProfileAPI.getProfile(id, tokenUser)
                .then((result) => {
                    localStorage.setItem("usertmp", JSON.stringify(result));
                    navigateToProfile(id)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404 || error.response.status == 500) {
                        }
                        if (error.response.status == 403) {
                            tokenCheck("getProfile")
                        }
                    }
                })
        }
    }

    function filterReactionById(array, id) {
        if (array != undefined) {
            for (let r of array) {
                if (r.reaction_id === id) {
                    if (r != undefined) {
                        for (let u of r.users_who_reacted) {

                            if (u.id === user.id) {
                                if (r.reaction_id === 1) {
                                    setLikeSelect(true);
                                    return r.quantity - 1;
                                }
                                if (r.reaction_id === 2) {
                                    setLoveSelect(true);
                                    return r.quantity - 1;
                                }
                                if (r.reaction_id === 3) {
                                    setFaceSelect(true);
                                    return r.quantity - 1;
                                }
                            }
                        }
                        return r.quantity;
                    } else {
                        return 0
                    }
                }
            }
        }

    }

    function react_like(postId) {
        let likeTmp = !likeSelect;
        setLikeSelect(likeTmp);
        setLoveSelect(false);
        setFaceSelect(false);
        if (likeTmp == true) reactPost(postId, 1, "update");
        else reactPost(postId, 1, "remove");
    }

    function react_love(postId) {
        let loveTmp = !loveSelect;
        setLoveSelect(loveTmp);
        setLikeSelect(false);
        setFaceSelect(false);
        if (loveTmp == true) reactPost(postId, 2, "update");
        else reactPost(postId, 2, "remove");
    }

    function react_face(postId) {
        let faceTmp = !faceSelect;
        setFaceSelect(faceTmp);
        setLoveSelect(false);
        setLikeSelect(false);
        if (faceTmp == true) reactPost(postId, 3, "update");
        else reactPost(postId, 3, "remove");
    }

    function getIdUser(id) {
        getProfile(id)
    }

    function navigateToLogin() {
        localStorage.setItem("usertmp", null);
        navigate("/login")
    }

    function navigateToProfile(id) {
        if (id === undefined)
            navigate("/profile")
        else {
            navigate("/profile?id=" + id, {
                replace: true,
                state: {
                    id: id
                }
            });
        }
        window.scrollTo({
            top: 0,
            // behavior: 'smooth'
        });
    }

    function openModalFunction() {
        setOpenModal(true);
        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }

    function closeModal() {
        document.body.style.overflow = "auto";
        setOpenModal(false);
    }

    return (
        <Fragment>
            <VStack space={2}>
                <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{
                    shadow: 2,
                    borderWidth: 0
                }}
                    backgroundColor="gray.50"
                    m="2">
                    <VStack>
                        <HStack space={2} m={4}>
                            <Button p={0} rounded={'full'} onPress={() => getIdUser(dataPost.post_owner.id)}>
                                {dataPost.post_owner.avatar.id != 0 ? (
                                    <Avatar alignSelf="center" bg={"white"} source={{ uri: dataPost.post_owner.avatar.url }} />
                                ) : (
                                    <Avatar bg={dataPost.post_owner.bgColor} alignSelf="center">
                                        {dataPost.post_owner.nome.slice(0, 1).toUpperCase() + dataPost.post_owner.cognome.slice(0, 1).toUpperCase()}
                                    </Avatar>
                                )}
                            </Button>
                            <Center alignItems={"flex-start"}>
                                <Text fontSize="sm" onPress={() => getIdUser(dataPost.post_owner.id)} fontWeight="bold">{dataPost.post_owner.nome} {dataPost.post_owner.cognome}</Text>
                                {/*SE RIVOGLIAMO L'ORARIO - {dataPost.timestamp_creazione.slice(11, 16)} */}
                                <Text fontSize="sm">{dataPost.timestamp_creazione.slice(0, 10).split('-').reverse().join('/')}  </Text>
                            </Center>
                        </HStack>
                        <Divider />
                        <Flex direction={"column"}>
                            {dataPost?.imgcopertina && (
                                <Box h={{ base: "xs", md: "md" }} w={"100%"}>
                                    <AspectRatio >
                                        {/* ratio={16 / 9}>  */}
                                        <Image h={{ base: "xs", md: "md" }} source={{
                                            uri: dataPost?.imgcopertina
                                        }} alt="image" />
                                    </AspectRatio>
                                </Box>
                            )}
                            <Stack space={2} w="100%" padding={4}>
                                <Heading size="md" >
                                    {dataPost?.jsondata?.titolo ?? ""}
                                </Heading>
                                <Text my="3" fontWeight="400">
                                    {dataPost?.jsondata?.description ?? ""}
                                </Text>
                                {dataPost?.jsondata?.callToAction && (
                                    <Button >{dataPost?.jsondata?.callToAction}</Button>
                                )}
                                {/*TODO: onPress={console.log(dataPost.jsondata.urlCallAction)} */}
                            </Stack>
                        </Flex>
                        <Flex direction='row' align={'center'} justify={'space-between'} p={4}>
                            <HStack space={4}>
                                {/*Mi piace */}
                                <Button p={0} onPress={() => react_like(dataPost.id)} variant="unstyled">
                                    <HStack space={3} borderWidth="2px" borderColor="coolGray.200" rounded="full" px={3} py={1}>
                                        <Text color='info.500' >
                                            <FontAwesomeIcon className='delete-icon' icon={(likeSelect === false) ? faThumbsUpReg : faThumbsUp} size="xl" />
                                        </Text>
                                        {likeSelect === false ? (
                                            <Text>{likeBadge != undefined ? likeBadge : 0}</Text>
                                        ) : (
                                            <Text>{likeBadge != undefined ? likeBadge + 1 : 1}</Text>
                                        )}
                                    </HStack>
                                </Button>
                                {/*Cuore */}
                                <Button p={0} onPress={() => react_love(dataPost.id)} variant="unstyled">
                                    <HStack space={3} borderWidth="2px" borderColor="coolGray.200" rounded="full" px={3} py={1}>
                                        <Text color='red.600' >
                                            <FontAwesomeIcon className='delete-icon' icon={loveSelect === false ? faHeartReg : faHeart} size="xl" />
                                        </Text>
                                        {loveSelect === false ? (
                                            <Text>{loveBadge != undefined ? loveBadge : 0}</Text>
                                        ) : (
                                            <Text>{loveBadge != undefined ? loveBadge + 1 : 1}</Text>
                                        )}
                                    </HStack>
                                </Button>
                                {/*Faccina */}
                                <Button p={0} onPress={() => react_face(dataPost.id)} variant="unstyled">
                                    <HStack space={3} borderWidth="2px" borderColor="coolGray.200" rounded="full" px={3} py={1}>
                                        <Text color='yellow.400' >
                                            <FontAwesomeIcon className='delete-icon' icon={faceSelect === false ? faSmileReg : faSmile} size="xl" />
                                        </Text>
                                        {faceSelect === false ? (
                                            <Text>{faceBadge != undefined ? faceBadge : 0}</Text>
                                        ) : (
                                            <Text>{faceBadge != undefined ? faceBadge + 1 : 1}</Text>
                                        )}
                                    </HStack>
                                </Button>
                            </HStack>
                            <Tooltip hasArrow label="Mostra tutti gli utenti che hanno reagito a questo post">
                                <Avatar.Group onClick={openModalFunction} cursor="pointer" _avatar={{
                                    size: "10"
                                }} max={3}>
                                    {dataPost.reaction.map((reaction) =>
                                        reaction.users_who_reacted != null && (
                                            reaction.users_who_reacted.map((user_who_reacted) =>
                                                user_who_reacted.id != user.id && (
                                                    user_who_reacted.avatar.id != 0 ? (
                                                        <Avatar bg={'white'} source={{ uri: user_who_reacted.avatar.url }} />
                                                    ) :
                                                        (
                                                            <Avatar bg={'violet.500'} alignSelf="center" >
                                                                {user_who_reacted.nome.slice(0, 1).toUpperCase() + user_who_reacted.cognome.slice(0, 1).toUpperCase()}
                                                            </Avatar>
                                                        )
                                                )
                                            )
                                        )
                                    )}
                                </Avatar.Group>
                            </Tooltip>
                        </Flex>

                    </VStack>
                </Box>
            </VStack>
            <Modal isOpen={openModal} size={"lg"} onClose={closeModal} >
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content  >
                        <Modal.CloseButton />
                        <Modal.Header>
                            Elenco reazioni
                        </Modal.Header>

                        <Modal.Body h={"full"}>

                            {dataPost.reaction.map((reaction) =>
                                reaction.users_who_reacted != null && (
                                    reaction.users_who_reacted.map((users_who_reacted) =>
                                        <Flex direction='row' p={2} justify={'space-between'} alignItems={'center'}>
                                            {users_who_reacted.id != user.id && (<HStack space={4} alignItems="center">

                                                {users_who_reacted.avatar.id != 0 ? (
                                                    <Avatar bg={'white'} source={{ uri: users_who_reacted.avatar.url }} />
                                                ) : (<Avatar bg={'violet.500'} alignSelf="center" >
                                                    {users_who_reacted.nome.slice(0, 1).toUpperCase() + users_who_reacted.cognome.slice(0, 1).toUpperCase()}
                                                </Avatar>)}
                                                <Text>
                                                    {users_who_reacted.nome} {users_who_reacted.cognome}
                                                </Text>
                                            </HStack>)}
                                            {users_who_reacted.id != user.id && (<Box>
                                                {reaction.reaction_id == 1 && (
                                                    <Text color='info.500'>
                                                        <FontAwesomeIcon className='delete-icon' icon={faThumbsUp} size="xl" />
                                                    </Text>
                                                )}
                                                {reaction.reaction_id == 2 && (
                                                    <Text color='red.500'>
                                                        <FontAwesomeIcon className='delete-icon' icon={faHeart} size="xl" />
                                                    </Text>
                                                )}
                                                {reaction.reaction_id == 3 && (
                                                    <Text color='yellow.400'>
                                                        <FontAwesomeIcon className='delete-icon' icon={faSmile} size="xl" />
                                                    </Text>
                                                )}
                                            </Box>)}
                                        </Flex>
                                    ))
                            )}
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>
        </Fragment>
    );
}
