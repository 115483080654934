import * as React from 'react';
import { Fragment, useEffect, useState } from "react";
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
// import Avatar from '@mui/material/Avatar';

import { Box, Text, Avatar, HStack, VStack, Flex, Center, Container, Button } from 'native-base';

export default function Answer(props) {

    const [domanda, setDomanda] = useState(props.domanda);
    const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
    const [nextQuestion, setNextQuestion] = useState(props.nextQuestion);
    const [selectedDomanda, setSelectedDomanda] = useState(1);



    useEffect(() => {
        if (props.showCorrectAnswer !== showCorrectAnswer) setShowCorrectAnswer(props.showCorrectAnswer);
    }, [props.showCorrectAnswer]);

    useEffect(() => {
        if (props.nextQuestion !== nextQuestion) setNextQuestion(props.nextQuestion);
    }, [props.nextQuestion]);

    useEffect(() => {
        if (props.selectedDomanda !== selectedDomanda) {
            setSelectedDomanda(props.selectedDomanda);
        }
    }, [props.selectedDomanda]);

    useEffect(() => {
        if (props.domanda !== domanda) setDomanda(props.domanda);
    }, [props.domanda]);

    function selectDomanda(domanda, index) {
        props.selectDomanda(domanda, index)
    }

    function changeColorAnswer(choice) { /*Cambia il colore della card se la risposta è corretta (o meno) */
        if (showCorrectAnswer === true) {
            if (nextQuestion === true) {
                if (domanda.correctAnswer.includes(choice.value)) {
                    return 'success.600' //Verde- risposta corretta
                }
                if (selectedDomanda === choice.value && (!domanda.correctAnswer.includes(choice.value))) {
                    return 'red.600' //Rosso- risposta sbagliata
                }
                if (selectedDomanda !== choice.value && (!domanda.correctAnswer.includes(choice.value))) {
                    return 'white' //Rosso- risposta sbagliata
                }
            }
            else {
                if (selectedDomanda === choice.value) {
                    return 'gray.500' //Grigio quando è selezionata
                }
                else {

                    return 'white'//'#003A6C' //Blu se è alto contrasto
                }
            }
        }
        if (showCorrectAnswer === false) {
            if (selectedDomanda == choice.value) {
                return 'gray.500' //Grigio quando è selezionata
            }
            else {
                return 'white' //Blu se è alto contrasto
            }
        }
    }
    function changeColorAnswerText(choice) { /*Cambia il colore del testo della card se la risposta è corretta (o meno) */
        if ((showCorrectAnswer === true && selectedDomanda === choice.value) ||
            (showCorrectAnswer === true && domanda.correctAnswer.includes(choice.value))) {
            if (nextQuestion === true) { //E se hai cliccato 'avanti/prosegui
                return 'white' //Se la risposta è corretta o semplicemente selezionata ed è abilitata l'impostazioni di visualizza risposta
            }
            else {

                return 'black'

            }
        } else { //Se non è abilitata la visualizazzione della risposta corretta

            return 'black'; //Altrimenti nere

        }
    }

    return (
        <Box >
            {domanda.choices.map((choice, index) => (
                <Fragment key={index}>
                    {domanda.type === "radiogroup" && (//Tipo di domanda TESTO
                        <Flex direction="row" p={3} mt={3} align="center" style={{ cursor: 'pointer' }} onClick={() => selectDomanda(choice, index)} rounded="md" bgColor={changeColorAnswer(choice)}>
                            {/* style={{ backgroundColor: changeColorAnswer(choice) }} className={`cardDomanda ${showCorrectAnswer === true && nextQuestion === true ? "" : "showAnswerHoverEffect"}`}> */}
                            <Box display={{ base: "flex", md: "none" }} >
                                <Avatar bgColor={"black"} width='20px' height='20px' style={{ cursor: 'pointer' }} m={1} p={1}>
                                    <Text variant="body2">
                                        {(index + 10).toString(36).toUpperCase()}
                                    </Text>
                                </Avatar>
                            </Box>
                            <Box display={{ base: "none", md: "flex" }} >
                                <Avatar ml={2} bgColor={"black"} style={{ cursor: 'pointer' }} >
                                    {(index + 10).toString(36).toUpperCase()}
                                </Avatar>
                            </Box>

                            {/*Testo su dispositivi di medie-grandi dimensioni */}
                            <Box p={2} ml={3} display={{ base: "none", md: "flex" }} w={'90%'} style={{ color: changeColorAnswerText(choice) }}>
                                <div style={{ lineBreak: 'auto', width: '100%', cursor: 'pointer' }} dangerouslySetInnerHTML={{
                                    __html: choice.text
                                }}>
                                </div>
                            </Box>

                            {/*Testo su dispositivi mobile e tablet*/}
                            <Box p={{ xs: 1, sm: 2 }} ml={1} display={{ base: "flex", md: "none" }} w={'90%'} style={{ color: changeColorAnswerText(choice) }}>
                                <div style={{ lineBreak: 'auto', width: '100%', cursor: 'pointer' }} dangerouslySetInnerHTML={{ __html: choice.text }}></div>
                            </Box>
                        </Flex>
                    )}
                </Fragment>
            ))}

        </Box>
    )
}
