import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Avatar, Box, Button, Center, Flex, Text, Link } from 'native-base';

import { Virtuoso } from 'react-virtuoso';
import { useTranslation } from 'react-i18next';

import { ProfileAPI } from '../../api/profileApi';
import { AuthAPI } from '../../api/authApi';


export default function ListSearch(props) {

    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [listUser, setListUser] = useState([])
    const [alertList, setAlertList] = useState("")
    const [listSet, setListSet] = useState(() => []);
    const [userId, setUserId] = useState(0);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.list != undefined) {
            setListUser(props.list);
            setListSet(props.list.slice(0, 5))
        }
    }, [props.list])

    useEffect(() => {
        if (props.alert != undefined) { setAlertList(props.alert) }
    }, [props.alert])

    const loadMore = useCallback(() => {
        return setTimeout(() => {
            if (listUser != undefined) {
                let newPost = listUser.slice(listSet.length + 1, listSet.length + 5)
                setListUser((oldSet) => [...oldSet, ...newPost])
                setListSet((oldSet) => [...oldSet, ...newPost])
            }
        }, 200)
    }, [setListSet])

    useEffect(() => {
        if (userId != undefined) getIdUser(userId);
    }, [userId])

    function getIdUser(id) {
        props.getIdUser(id)
    }

    function followUser(i) {
        let usersTmp = listUser;
        let isFollowing = !usersTmp[i].utente.follow
        usersTmp[i].utente.follow = isFollowing;
        setListUser([...usersTmp])
        if (isFollowing) {
            //tokenCheck("follow")
        }

    }



    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));

                if (key === "follow") follow();
                if (key === "unfollow") unfollow();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        props.navigateToLogin();
                    }
                }
            })
    }
    async function follow(id, index) {

        let idTmp = {
            followingid: String(id)
        }

        ProfileAPI.follow(idTmp, tokenUser)
            .then((result) => {
                let listUserTmp = listUser;
                listUserTmp[index].utente.follow = true
                setListUser([...listUserTmp]);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 401) {
                        props.navigateToLogin();
                    }
                    if (error.response.status == 403) {
                        tokenCheck("follow")
                    }
                }
            })
    }
    async function unfollow(id, index) {

        let idTmp = {
            followingid: String(id)
        }

        ProfileAPI.unfollow(idTmp, tokenUser)
            .then((result) => {
                let listUserTmp = listUser;
                listUserTmp[index].utente.follow = false;
                setListUser([...listUserTmp]);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 401) {
                        props.navigateToLogin();
                    }
                    if (error.response.status == 403) {
                        tokenCheck("unfollow")
                    }
                }
            })
    }

    function goToUser(id) {
        props.goToUser(id);
    }

    return (

        <Box w={"100%"} px={4}>
            <Text color={"error.500"} textAlign={"center"} fontSize={"sm"}>{alertList}</Text>
            {listUser.length > 0 ? (
                <Virtuoso
                    style={{ height: "400px" }}
                    data={listUser}
                    endReached={loadMore}
                    overscan={2}
                    itemContent={(index, userFromList) => {
                        return (
                            <Box key={index} p="4" variant="unstyled" onPress={() => setUserId(userFromList.utente.id)}>
                                <Flex direction='row' justify="space-between">
                                    <Flex direction='row' justify="start">
                                        <Link onPress={() => goToUser(userFromList.utente.id)}>

                                            {userFromList.utente.avatar.id != 0 ? (
                                                <Avatar alignSelf="center" source={{ uri: userFromList.utente.avatar.url }} />
                                            ) : (
                                                <Avatar bg={userFromList.utente.bgColor} alignSelf="center">
                                                    {userFromList.utente.nome.slice(0, 1).toUpperCase() + userFromList.utente.cognome.slice(0, 1).toUpperCase()}
                                                </Avatar>
                                            )}

                                            {/* <Avatar bg={userFromList.utente.bgColor} alignSelf="center" style={{ cursor: "pointer" }} source={{
                                                uri: userFromList.utente.avatar.id != 0 ? userFromList.utente.avatar.url : ""
                                            }}>
                                                {userFromList.utente.nome.slice(0, 1).toUpperCase() + userFromList.utente.cognome.slice(0, 1).toUpperCase()}
                                            </Avatar> */}
                                        </Link>

                                        <Center ml={2}>
                                            <Text style={{ cursor: "pointer" }}>
                                                <Link onPress={() => goToUser(userFromList.utente.id)}>
                                                    {userFromList.utente.nome} {userFromList.utente.cognome}
                                                </Link>
                                            </Text>
                                        </Center>
                                    </Flex>
                                    <Center>
                                        <Button px={6} py={2} height={"fit-content"} onPress={userFromList.utente.follow ? (() => unfollow(userFromList.utente.id, index)) : (() => follow(userFromList.utente.id, index))} colorScheme={(userFromList.utente.follow == true) ? "danger" : "primary"}>
                                            {(userFromList.utente.follow == true) ? "Non seguire più" : "Segui"}
                                        </Button>
                                    </Center>
                                </Flex>
                            </Box>
                        )
                    }
                    }
                    components={<div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>{t("menu.noResult")}...</div>}
                />
            ) : (
                <Center h="200px">
                    <Text bold fontSize="md" textAlign={'center'}>{t("menu.noResult")}...</Text>
                </Center>
            )}
        </Box>
    )
}