import * as React from 'react';
import { Stack, Box, Image, Button, Center, Text } from 'native-base';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";
import { Fragment } from 'react';
import ErrorImg from "../img/404.png";
import Logo from "../img/logoEspanso.png";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';

export default function ErrorPage() {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    function goToHome() {
        navigate("/login")
    }

    return (
        <Fragment>
            <Box height={"100vh"} >
                <Center height={"100vh"}>
                    <Center w="container" rounded="lg" overflow="hidden" padding={"35px"} >
                        <Stack width={"100%"}  space={4} mx="auto">

                            <Center>
                                {/* <Text fontSize="9xl" bold>
                                404
                            </Text> */}
                                <Image mb="4" source={{
                                    uri: Logo
                                }} alt="Logo quizube" w="170px" h="50px" />
                                <Image source={{
                                    uri: ErrorImg
                                }} alt="404" w={{ base: "md", md: "xl" }} h={{ base: "sm", md: "lg" }} />

                                <Text mt="6" fontSize="2xl" bold>Ops! Sembra ci sia stato un errore.</Text>
                                <Button w="xl" colorScheme="primary" rounded="lg" mt="4" onPress={goToHome}
                                    leftIcon={<FontAwesomeIcon style={{ color: 'white' }} icon={faArrowLeft} />} >
                                    Torna indietro
                                </Button>
                            </Center>
                        </Stack>
                    </Center>
                </Center>
            </Box>
            <Helmet>
                <title>{t("name")} - {t("pages.errorPage.title")}</title>
            </Helmet>
        </Fragment>
    )
}