import * as React from 'react';
import { useState, useEffect } from 'react';

import { Center, Flex } from "native-base";
import { Button } from "native-base";
import { Image } from 'native-base';
import { Tooltip } from "native-base";
import { Box } from 'native-base';
import { Text } from 'native-base';
import { useTranslation } from 'react-i18next';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLarge, faTrophy, faLock } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from "react-helmet";
//API
import { AuthAPI } from '../../api/authApi';
import { FileAPI } from '../../api/fileApi';

export default function ListTraguardi(props) {

    const [viewAllAchievements, setViewAllAchievements] = useState(false);

    const [userBadges, setUserBadges] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if (props.userBadges != undefined) {
            // console.log("Badges list",props.userBadges)
            arrayAchievements(props.userBadges)
        }
    }, [props.userBadges])

    useEffect(() => {
        if (props.viewAllAchievements != undefined) {
            setViewAllAchievements(props.viewAllAchievements)
        }
    }, [props.viewAllAchievements])

    function arrayAchievements(arrayBadges) {
        if (arrayBadges.length < 5 && !viewAllAchievements) {
            let arrayTemp = [];
            let objEmpty = {
                badge: {
                    descrizione: "Da conquistare",
                }
            }
            for (let i = 0; i < (5 - arrayBadges.length); i++) {
                arrayTemp.push(objEmpty)
            }
            arrayTemp = [...arrayBadges, ...arrayTemp]
            // console.log("Badges list",arrayTemp)
            setUserBadges(arrayTemp)
        } else { 
            setUserBadges([...arrayBadges]) 
        }
    }

    return (
        <Center>
            <Flex direction={!viewAllAchievements ? ("row") : ('column')} w={{ base: "100%", lg: "100%" }} justifyContent={"space-around"}>
                {userBadges.length > 0 ? (
                    (viewAllAchievements ? props.userBadges : userBadges).map((trophy, index) =>
                        <Flex key={index} direction={viewAllAchievements ? 'row' : 'column'}>
                            <Tooltip label={trophy.badge.descrizione} openDelay={200}>
                                <Box bgColor={"gray.300"} overflow="hidden" width={!viewAllAchievements ? ("48px") : ('48px')} height={!viewAllAchievements ? ("48px") : ('48px')} variant="solid" rounded={"none"} padding={0} m={1}>
                                    <Flex direction="row" align="center" justify="center"  height={"full"}>
                                        {trophy.badge.descrizione !== "Da conquistare" ? (
                                        <Image source={{uri: trophy.badge.imgUrl }} alt={trophy.badge.descrizione} width={!viewAllAchievements ? ("48px") : ('48px')} height={!viewAllAchievements ? ("48px") : ('48px')} />) 
                                        : (
                                               <FontAwesomeIcon icon={faLock} size="xl" />)}
                                    </Flex>
                                </Box>

                            </Tooltip>
                            {viewAllAchievements && (
                                <Center marginLeft={5}>
                                    <Text>{trophy.badge.descrizione}</Text>
                                </Center>
                            )}
                        </Flex>
                    )
                ) : (
                    <Text> {t("pages.profile.noAchievements")}</Text>
                )}
            </Flex>
        </Center>
    );
}