import * as React from 'react';
import { useState, useEffect, Fragment, useRef } from 'react';
import { Box, Container, Modal, VStack, Flex, Center, Button, Avatar, Text, Divider, CloseIcon, Link, HStack, Spinner, Heading } from 'native-base';
import { useBreakpointValue } from 'native-base';

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import MenuComponent from '../menu/menu';
import PostList from '../timeline/postList';
import HeaderProfile from '../shared/headerProfile';
import ListClassifica from '../shared/listClassifica';
import ListAmici from '../shared/listAmici';
import ListTraguardi from '../shared/listTraguardi';
//API
import { AuthAPI } from '../../api/authApi';
import { FileAPI } from '../../api/fileApi';
import { ProfileAPI } from '../../api/profileApi';
import { TimelineAPI } from '../../api/timelineApi';

export default function Profile() {

    const navigate = useNavigate();
    const location = useLocation();

    const modalRef = useRef(null);
    const { t, i18n } = useTranslation();

    const user = JSON.parse(localStorage.getItem("user"));
    const [userProfile, setUserProfile] = useState(undefined);
    const [userPost, setUserPost] = useState([]);
    const [userFollower, setUserFollower] = useState([]);
    const [userFollowing, setUserFollowing] = useState([]);
    const [userBadges, setUserBadges] = useState([]);
    const [classifica, setClassifica] = useState([]);

    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [idProfile, setIdProfile] = useState(0)

    const [postSet, setPostSet] = useState();

    const [email, setEmail] = useState("");

    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState("");
    const [posts, setPost] = useState();

    const [openModal, setOpenModal] = useState(false);
    const [followerchange, setFollowerchange] = useState(false)

    const [viewAllAchievementsTab, setViewAllAchievementsTab] = useState(false);
    const [viewAllFriendsTab, setViewAllFriendsTab] = useState(false);
    const [viewLeaderboardTab, setViewLeaderboardTab] = useState(false);

    const [viewPost, setViewPost] = useState(true);

    const [follower, setFollower] = useState([]);
    const [following, setFollowing] = useState([]);

    const [isLoading, setIsLoading] = useState(true);

    const flexDirection = useBreakpointValue({
        base: "column-reverse",
        lg: "row"
    });

    useEffect(() => {
        setIsLoading(true)

        // if (location.state != null) console.log("IDprofilo", location.state.id)
        //location.state.id
        //GetByUserID
        classificaGenerale()
        if (location.state == null || location.state.id == user.id) 
        getUserbyId(user.id)


        getFollowerList();
        getFollowingList();

        // generateRandomColoursAvatar();
        dataPostProfile()
        setNome(user.nome);
        setCognome(user.cognome);
        setEmail(user.email);
        setDataDiNascita(user.datanascita.slice(0, 10));
        setUrlAvatarLocal(user.avatar.url)
        // getTimelinePost();
    }, [])

    useEffect(() => {
        if (location.state != null) {
            setIdProfile(location.state.id)
            getUserbyId(location.state.id)
        }
    }, [location.state])



    function goToProfile(id) {

        navigate("/profile?id=" + id, {
            replace: true,
            state: {
                id: id
            }
        });
        closeModal();

        //Imposto caricamento
        window.scrollTo({
            top: 0,
        });
        //getProfileByID(id);
        //Quando il getProfileByID ha restituito qualcosa allora il caricamento finisce

    }
    function closeTab() { //Da eliminare?
        setViewAllAchievementsTab(false);
        setViewAllFriendsTab(false);
        setViewLeaderboardTab(false);
    }
    function goToQuestionario() {
        navigate("/questionari");
    }
    function openViewAllAchievementsTab() {
        openModalFunction();
        setViewAllAchievementsTab(true);
        setViewAllFriendsTab(false);
        setViewLeaderboardTab(false);
    }
    function openViewAllFriendsTab() {
        openModalFunction();
        setViewAllFriendsTab(true);
        setViewAllAchievementsTab(false);
        setViewLeaderboardTab(false);
    }
    function openViewLeaderboardTab() {
        openModalFunction();
        setViewLeaderboardTab(true);
        setViewAllAchievementsTab(false);
        setViewAllFriendsTab(false);
    }
    function generateRandomColoursAvatar(list) {
        let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
        let tmpUtenti = [];
        for (let utente of list) {
            let previouseRandom = -1;
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * coloursList.length) + 1
            } while (randomIndex === previouseRandom);
            previouseRandom = randomIndex;
            utente.utente.bgColor = coloursList[randomIndex];
            tmpUtenti.push(utente);
        }
        return tmpUtenti;
    }
    function generateRandomColoursAvatarPost(list) {
        let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
        let tmpUtenti = [];
        for (let utente of list) {
            let previouseRandom = -1;
            let randomIndex;
            do {
                randomIndex = Math.floor(Math.random() * coloursList.length) + 1
            } while (randomIndex === previouseRandom);
            previouseRandom = randomIndex;
            utente.post_owner.avatar.bgColor = coloursList[randomIndex];
            tmpUtenti.push(utente);
        }
        return tmpUtenti;
    }
    function openModalFunction() {
        setOpenModal(true);
        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }
    function closeModal() {
        document.body.style.overflow = "auto";
        setOpenModal(false);
    }
    function navigateToLogin() {
        navigate("/login")
    }
    function changeModalListFollow() {
        setFollowerchange(!followerchange)
    }


    async function getUserbyId(id) {

        if (id != undefined) {
            ProfileAPI.getProfile(id, tokenUser)
                .then((result) => {
                    setNome(result.user.nome)
                    setCognome(result.user.cognome)
                    setProfile(result)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404) {
                        }
                        if (error.response.status == 500) {
                        }
                        if (error.response.status == 403) {
                            tokenCheck("getUserbyId")
                        }
                    }
                })
        }
    }
    async function classificaGenerale() {
        ProfileAPI.classifica(tokenUser)
            .then((result) => {
                // console.log("Classifica", result)
                let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                let tmpUtenti = [];
                for (let utente of result) {
                    let previouseRandom = -1;
                    let randomIndex;
                    do {
                        randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    } while (randomIndex === previouseRandom);
                    previouseRandom = randomIndex;
                    utente.bgColor = coloursList[randomIndex];
                    tmpUtenti.push(utente);
                }
                setClassifica(tmpUtenti);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                    }
                    if (error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("classificaGenerale")
                    }
                }
            })
    }
    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                if (key === "dataPostProfile") dataPostProfile();
                if (key === "getUserbyId") getUserbyId(idProfile);
                if (key === "getFollowerList") getFollowerList();
                if (key === "getFollowingList") getFollowingList();
                if (key === "classificaGenerale") classificaGenerale();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }
    async function getFollowerList() {

        ProfileAPI.getFollowerList(tokenUser)
            .then((result) => {
                setFollower([...result])
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                    }
                    if (error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getFollowerList")
                    }
                }
            })
    }
    async function getFollowingList() {

        ProfileAPI.getFollowingList(tokenUser)
            .then((result) => {
                setFollowing([...result])
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                    }
                    if (error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getFollowingList")
                    }
                }
            })
    }
    function setProfile(data) {
        setUserProfile(data.user);
        if (data.followers != undefined) setUserFollower([...generateRandomColoursAvatar(data.followers)]);
        if (data.following != undefined) setUserFollowing([...generateRandomColoursAvatar(data.following)]);
        if (data.posts != undefined) {
            setUserPost([...generateRandomColoursAvatarPost(data.posts)]);
        }
        setIsLoading(false)
        setUserBadges([...data.badges])
    }
    async function dataPostProfile() {
        ProfileAPI.dataPostProfile(tokenUser)
            .then((result) => {
                let tmpPosts = [];
                for (let post of result) {
                    let postTmp = post;
                    let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                    let randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    postTmp.post_owner.avatar.bgColor = coloursList[randomIndex];
                    tmpPosts.push(postTmp);
                }
                localStorage.setItem("posts", JSON.stringify(tmpPosts));
                setPostSet(tmpPosts);
            })
            .catch((error) => {
                if (error.response) {
                    if (localStorage.getItem("posts") != null)
                        setPostSet(JSON.parse(localStorage.getItem("posts")));

                    if (error.response.status == 403) {
                        tokenCheck("dataPostProfile")
                    }
                }
            })

    }

    

    return (

        <Fragment>
            <Box w={"full"}>
                <MenuComponent pageName="profile" ListClassifica={classifica} listFollower={userFollower} listFollowing={userFollowing} />
                {isLoading && (
                    <Box h={"100vh"}>
                        <Center h="full">
                            <Box>
                                <Spinner size="lg" />
                                <Heading mt={15} color="primary.600" fontSize="md">
                                    Caricamento profilo...
                                </Heading>
                            </Box>
                        </Center>
                    </Box>)
                }
                {!isLoading && (<HeaderProfile user={userProfile} userBadges={userBadges}
                    goToQuestionari={goToQuestionario}
                    openViewAllAchievementsTab={openViewAllAchievementsTab}
                    openViewAllFriendsTab={openViewAllFriendsTab}
                    openViewLeaderboardTab={openViewLeaderboardTab}
                    following={userFollowing} followingCheckList={following}
                />)}
                {!isLoading && (<Center>
                    <Container w="full" maxW={{ base: '95vw', md: '80%' }}>
                        <Flex m={{ base: "0", md: "4" }} direction={flexDirection} w="full">
                            {viewPost && (<PostList userPost={userPost} />)}
                            <VStack w={viewPost ? ("35%") : ("100%")}>
                                {!viewPost && (<Box w={"100%"} alignItems={"flex-end"}>
                                    <Button textAlign={"center"} padding={0} variant={"unstyled"} onPress={closeTab}><CloseIcon />
                                    </Button>
                                </Box>)}
                                {(flexDirection !== "column-reverse" || !viewPost) && (
                                    <Box>
                                        {/*Traguardi */}
                                        <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{ shadow: 2, borderWidth: 0 }} backgroundColor="gray.50" m="2" padding={4}>
                                            <Center>
                                                <ListTraguardi userBadges={userBadges} viewAllAchievements={false} />
                                            </Center>
                                            <Button m="2" onPress={openViewAllAchievementsTab}>
                                                {t("pages.profile.viewAllAchievements")}
                                            </Button>
                                        </Box>
                                        {/*I tuoi amici */}
                                        <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{ shadow: 2, borderWidth: 0 }} backgroundColor="gray.50" m="2">

                                            <Flex direction='row' w={"100%"} h={"50px"} borderBottomColor="coolGray.200" borderBottomWidth={'1px'}>
                                                <Button w={"50%"} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '0px' : '2px'} _text={followerchange ? { color: "gray.300", fontWeight: "bold" } : { color: "black", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow} >
                                                    {t("pages.profile.following")}
                                                </Button>
                                                <Button w={"50%"} m={0} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '2px' : '0px'} _text={followerchange ? { color: "black", fontWeight: "bold" } : { color: "gray.300", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow}>
                                                    {t("pages.profile.follower")}
                                                </Button>
                                            </Flex>
                                            <Box padding={4}>
                                                {!followerchange && (<ListAmici goToUser={goToProfile} list={userFollowing} isModal={false} />)}
                                                {followerchange && (<ListAmici goToUser={goToProfile} list={userFollower} isModal={false} />)}
                                                <Button m="2" onPress={openViewAllFriendsTab}>{t("pages.profile.viewAllFriends")}</Button>
                                            </Box>
                                        </Box>
                                        {/*Classifica generale */}
                                        <Box rounded="lg" overflow="hidden" borderColor="coolGray.200" borderWidth="1" _web={{ shadow: 2, borderWidth: 0 }} backgroundColor="gray.50" m="2" padding={4}>
                                            <ListClassifica list={classifica.slice(0, 10)} goToUser={goToProfile} />
                                            {(classifica.length > 10 && classifica != []) && (<Divider />)}
                                            {(classifica.length > 10 && classifica != []) && (<Box m={2}>
                                                <Flex direction="row" align="center" justify="space-between">
                                                    <Flex direction='row' alignItems="center">
                                                        <Text bold>
                                                            {classifica[10].posizione}°
                                                        </Text>
                                                        <Button variant="unstyled" disabled>
                                                            <Avatar bg="primary.500" alignSelf="center" source={{
                                                                uri: classifica[10].avatar.url
                                                            }}>
                                                                {classifica[10].nome.slice(0, 1).toUpperCase() + classifica[10].cognome.slice(0, 1).toUpperCase()}
                                                            </Avatar>
                                                        </Button>
                                                        <Text bold textAlign={"center"}>
                                                            {classifica[10].nome} {classifica[10].cognome} (Tu)
                                                        </Text>
                                                    </Flex>
                                                    <Text bold textAlign={"center"}>
                                                        {classifica[10].punteggio}
                                                    </Text>
                                                </Flex>
                                            </Box>)}
                                            <Button m="2" onPress={openViewLeaderboardTab}>
                                                {t("pages.profile.viewLeaderboard")}
                                            </Button>
                                        </Box>
                                    </Box>)}
                            </VStack>
                        </Flex>
                    </Container>
                </Center>)}
                <Helmet>
                    <title>{t("name")} - {nome} {cognome}</title>
                </Helmet>
            </Box>
            <Modal isOpen={openModal} size={"lg"} onClose={closeModal} >
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content  >
                        <Modal.CloseButton />
                        <Modal.Header>
                            {!viewAllFriendsTab ? (
                                <Fragment>
                                    {viewLeaderboardTab && (t("pages.profile.leaderboardTitleModal"))}
                                    {viewAllAchievementsTab && (t("pages.profile.achievementsTitleModal"))}
                                </Fragment>
                            ) : (
                                    <Text>Elenco {!followerchange ? 'seguiti' : "followers"}</Text>
                            )}
                        </Modal.Header>
                        <Modal.Body h={"full"}>
                            {viewAllFriendsTab && (
                                <Flex direction='row' w={"100%"} h={"50px"} borderBottomColor="coolGray.200" borderBottomWidth={'1px'}>
                                    <Button w={"50%"} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '0px' : '2px'} _text={followerchange ? { color: "gray.300", fontWeight: "bold" } : { color: "black", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow} >
                                        {t("pages.profile.following")}
                                    </Button>
                                    <Button w={"50%"} m={0} borderBottomColor="coolGray.400" borderBottomWidth={followerchange ? '2px' : '0px'} _text={followerchange ? { color: "black", fontWeight: "bold" } : { color: "gray.300", fontWeight: "bold" }} rounded="none" variant={"unstyled"} onPress={changeModalListFollow}>
                                        {t("pages.profile.follower")}
                                    </Button>
                                </Flex>
                            )}
                            {viewLeaderboardTab && (
                                <ListClassifica goToUser={goToProfile} goToLogin={navigateToLogin} list={classifica.slice(0, 10)} isModal={true} closeModal={closeModal} />
                            )}
                            {(viewAllFriendsTab && !followerchange) && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollowing} isModal={true} closeModal={closeModal} />)}
                            {(viewAllFriendsTab && followerchange) && (<ListAmici goToUser={goToProfile} goToLogin={navigateToLogin} list={userFollower} isModal={true} closeModal={closeModal} />)}
                            {viewAllAchievementsTab && (
                                <ListTraguardi userBadges={userBadges} viewAllAchievements={true} isModal={true} />
                            )}
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>
        </Fragment>
    );


}