import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
// import { Virtuoso } from "react-virtuoso";
import buildVersion from '../../buildVersion.txt'

//native-base
import { Box, FormControl, Image, Input, Stack, Flex, Container } from 'native-base';
import { VStack, HStack } from 'native-base';
import { Center } from 'native-base';
import { Heading } from 'native-base';
import { Button } from "native-base";
import { Divider } from 'native-base';
import { Link } from 'native-base';
import { Spinner } from 'native-base';
import Logo from "../../img/logoEspanso.png";
import { Text } from 'native-base';
import { useBreakpointValue } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import { Helmet } from "react-helmet";
import { AuthAPI } from '../../api/authApi';
import ImgChat from "../../img/chat.png";

export default function Login() {

    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token')
    const urlEmail = urlParams.get('email')
    const navigate = useNavigate();

    const regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regExPassword = /^[a-zA-Z0-9]{8,}$/;

    const { t, i18n } = useTranslation();

    const [showPws, setShowPws] = useState(false);
    const [email, setEmail] = useState("");
    const [emailResetPws, setEmailResetPws] = useState("");
    const [pws, setPws] = useState("");
    const [valid, setValid] = useState(true);
    const [validEmailResetPws, setValidEmailResetPws] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [emailConferma, setEmailConferma] = useState("");
    const [conferma, setConferma] = useState(true); // da impostare a true
    const [errorMessageLogin, setErrorMessageLogin] = useState("");
    const [messageDisplay, setMessageDisplay] = useState(false);
    const [level, setLevel] = useState(-1); // -1

    const [versionNumber, setVersionNumber] = useState("");

    const displayObj = useBreakpointValue({
        base: false,
        lg: true
    });

    useEffect(() => {
        fetch(buildVersion)
            .then(r => r.text())
            .then(text => {
                let textTmp = "Versione n." + text;
                setVersionNumber(textTmp ?? "")
            })
            .catch(err => {
                setVersionNumber("")
            })
    }, [])

    // TODO: temporaneo - test caricamento
    useEffect(() => {
        if (isLoading) setTimeout(() => setIsLoading(false), 2000)
    }, [isLoading])

    useEffect(() => {
        if (urlToken !== null) {
            tokenConfermaCheckGet()
        }
    }, [urlToken])


    async function tokenConfermaCheckGet() {
        AuthAPI.validazioneUtenteGet(urlToken, urlEmail)
            .then((result) => {
                setLevel(0)
            })
            .catch((error) => {
                if (error.response.status == 409) {
                    setErrorMessageLogin(error.response.data.description)
                    setLevel(3)
                    setConferma(false)
                }
                if (error.response.status == 500 || error.response.status == 403 || error.response.status == 404) {
                    setLevel(1)
                    setConferma(false)
                }
            })
    }

    async function tokenConfermaCheckPost() {

        const data = {
            email: emailConferma
        }

        AuthAPI.validazioneUtentePost(data)
            .then((result) => {
                setLevel(2);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 409) {
                        setErrorMessageLogin(error.response.data.description)
                        setLevel(3)
                        setConferma(false)
                    }
                    if (error.response.status == 500 || error.response.status == 403 || error.response.status == 404) {
                        setLevel(1)
                        setConferma(false)
                    }
                }
            })
    }

    async function login() {

        let idDevice = (window.navigator.appCodeName + window.navigator.platform).toLowerCase();

        let userData = {
            email: email,
            password: pws,
            deviceId: idDevice
        }
        AuthAPI.login(userData)
            .then((result) => {
                localStorage.setItem("user", JSON.stringify(result.user));
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                navigateToHome(result.user.id);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404) {
                    }
                    if (error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        setMessageDisplay(true);
                        // setErrorMessageLogin("Password o Email non corrette");
                    }

                }
            })
    }

    function sendNewEmail() {
        tokenConfermaCheckPost();
    }

    function backToLogin() {
        navigateToLogin();
        setConferma(true)
    }
    function handleClick() { setShowPws(!showPws) };

    function validate() {
        if (email === "" || pws === "" || regExEmail.test(email) === false || regExPassword.test(pws) === false) {
            setValid(false)
            return false
        } else {
            setValid(true)
            return true
        }
    }

    function validateRestEmail() {
        if (emailResetPws === "") {
            setValidEmailResetPws(false)
            return false
        } else {
            setValidEmailResetPws(true)
            return true
        }
    }

    function emailSet(value) {
        setEmail(value)
    }
    function emailConfermaSet(value) {
        setEmailConferma(value)
    }

    function emailResetPwsSet(value) {
        setEmailResetPws(value)
    }

    function pwsSet(value) {
        setPws(value)
    }

    function onSubmit() {
        if (email != "" && pws != "" && regExEmail.test(email) === true) login();
        if (regExEmail.test(email) === false) setValid(false);
    }

    function resetPwsSubmit() {
        validateRestEmail()
        if (validateRestEmail()) {
            setIsLoading(true);
            console.log(emailResetPws)
        }
        else {
            console.log("accesso non riuscito")
        }
    }

    function navigateToLogin() {
        navigate("/login")
    }

    function navigateToReg() {
        navigate("/registrazione")
    }

    function navigateToRecoverPws() {
        navigate("/pwsrecover")
    }

    function navigateToHome(id) {
        navigate("/home", {
            replace: true,
            state: {
                id: id
            }
        })
        window.scrollTo({
            top: 0,
        });
    }

    return (
        <Fragment>
            <Box height={"100vh"}>
                <Center height={"100vh"}>
                    <Box overflow="hidden" backgroundColor="white" borderColor={{ base: "none", sm: "coolGray.200" }} >
                        <Flex direction={"row"}>
                            <Box w={{ sm: "0", lg: "40vw" }} h={"100vh"} backgroundColor={"primary.600"}>
                                <Center h={'100%'}>
                                    <Image source={{
                                        uri: ImgChat
                                    }} alt="img" w={{ base: "md", md: "lg" }} h={{ base: "xs", md: "md" }} />
                                    <Text fontSize="2xl" color="white" bold>Competi con altri concorsisti</Text>
                                </Center>
                            </Box>
                            <Center w={{ sm: "100vw", lg: "60vw" }} overflow="hidden" borderColor="coolGray.200" px={{ base: 2, sm: "8em", lg: "6em" }}  >
                                <Container w="full" >
                                    {isLoading === false && (
                                        <Stack space={4} w="100%" mx="auto">
                                            <Heading justifyContent="center" mb={4}>
                                                <Center>
                                                    <Image source={{
                                                        uri: Logo
                                                    }} alt="LOGO" w="170px" h="50px" />
                                                </Center>
                                            </Heading>
                                            {urlToken !== null && (
                                                <VStack>
                                                    {(conferma == false && level == 0) && (
                                                        <VStack>
                                                            <Text fontSize="6xl" textAlign="center">
                                                                <FontAwesomeIcon icon={faEnvelope} color="#65a30d" />
                                                            </Text>
                                                            <Text fontSize="3xl" textAlign="center">{t("pages.signup.emailConfermata")}</Text>
                                                            <Button colorScheme="primary" rounded="lg" onPress={backToLogin} mt="4">
                                                                {t("pages.signup.gotologin")}
                                                            </Button>
                                                        </VStack>
                                                    )}
                                                    {(conferma == false && level == 1) && (
                                                        <VStack>
                                                            <Text fontSize="3xl" textAlign="center">{t("pages.resetPws.error")}</Text>
                                                            <Text fontSize="md" textAlign="center" >{t("pages.resetPws.errorSub")}</Text>
                                                            <FormControl isRequired isInvalid={valid != true} mt="4">
                                                                <Input w="100%" rounded="none" size="lg" placeholder="Email" onChangeText={value => emailConfermaSet(value)} />
                                                                {(valid === false && email === "") && (<FormControl.ErrorMessage>{t("errors.email.empty")}</FormControl.ErrorMessage>)}
                                                                {(regExEmail.test(emailConferma) === false && email !== "") && (<FormControl.ErrorMessage>{t("errors.email.invalid")}</FormControl.ErrorMessage>)}
                                                            </FormControl>
                                                            <Button colorScheme="primary" rounded="lg" onPress={sendNewEmail} mt="4">
                                                                {t("pages.login.sendNewConfirmationMail")}
                                                            </Button>
                                                        </VStack>
                                                    )}
                                                    {(conferma == false && level == 2) && (
                                                        <VStack>
                                                            {/* <Text fontSize="6xl" mt="3" textAlign="center">
                                                        <FontAwesomeIcon icon={faCheck} color="#65a30d" />
                                                    </Text> */}
                                                            <Text fontSize="3xl" textAlign="center">{t("pages.recoveryPws.linkSent2")} <b>{emailConferma}</b></Text>
                                                            <Button colorScheme="primary" rounded="lg" onPress={backToLogin} mt="4">
                                                                {t("pages.signup.gotologin")}
                                                            </Button>
                                                        </VStack>
                                                    )}
                                                    {(conferma == false && level == 3) && (
                                                        <VStack>
                                                            <Text fontSize="3xl" textAlign="center">{errorMessageLogin}</Text>
                                                            <Button colorScheme="primary" rounded="lg" onPress={backToLogin} mt="4">
                                                                {t("pages.signup.gotologin")}
                                                            </Button>
                                                        </VStack>
                                                    )}
                                                </VStack>

                                            )}
                                            {conferma == true && (<VStack space={4}>
                                                <VStack space={2}>
                                                    <FormControl isRequired isInvalid={valid != true}>
                                                        <Input testid='input-email' onSubmitEditing={onSubmit} w="100%" rounded="none" size="lg" placeholder="Email" onChangeText={value => emailSet(value)} />
                                                        {(valid === false && email === "") && (<FormControl.ErrorMessage>{t("errors.email.empty")}</FormControl.ErrorMessage>)}
                                                        {(regExEmail.test(email) === false && email !== "") && (<FormControl.ErrorMessage>{t("errors.email.invalid")}</FormControl.ErrorMessage>)}
                                                    </FormControl>
                                                    <FormControl isRequired isInvalid={valid != true}>
                                                        <Input testid="input-password" onSubmitEditing={onSubmit} w="100%" rounded="none" size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                                            <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                                        } placeholder="Password" onChangeText={value => pwsSet(value)} />
                                                        {/* {(valid === false && pws === "" && !messageDisplay) && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)} */}
                                                    </FormControl>
                                                    {messageDisplay === true && (<Text fontSize={"xs"} color={"error.500"}>{t("pages.login.errorLogin")}</Text>)}
                                                    <Link _text={{
                                                        fontSize: "xs",
                                                        fontWeight: "500",
                                                    }} alignSelf="flex-end" mt="1" onPress={navigateToRecoverPws}>
                                                        {t("pages.login.forgotpassword")}
                                                    </Link>
                                                </VStack>
                                                <VStack space={2}>
                                                    <Button testid="button-login" colorScheme="primary" rounded="lg" onPress={onSubmit}>Login</Button>
                                                    <Button testid="button-signup" colorScheme="primary" variant="subtle" rounded="lg" onPress={navigateToReg}>
                                                        {t("pages.login.signup")}
                                                    </Button>
                                                </VStack>
                                                <Text fontSize="xs" textAlign="center">{versionNumber}</Text>
                                            </VStack>)}
                                        </Stack>
                                    )}
                                    {isLoading === true && (
                                        <HStack space={8} justifyContent="center" alignItems="center">
                                            <Spinner size="lg" />
                                        </HStack>
                                    )}
                                </Container>
                            </Center>
                        </Flex>
                    </Box>
                </Center>
            </Box>
            <Helmet>
                <title>{t("name")} - {t("pages.login.title")}</title>
            </Helmet>
        </Fragment>
    )
}

