import * as React from 'react';
import { useState, useEffect, Fragment,useRef, createRef } from 'react';
import { Box, Input, Modal, Image, Flex, Center, Button, Avatar, Text, Pressable, Icon, TextArea, Hidden } from 'native-base';
import { useBreakpointValue } from 'native-base';

import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import MenuComponent from '../menu/menu';
import ListAmici from '../shared/listAmici';
import { Link } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import imgChat from "../../img/chat.png";

import { ProfileAPI } from '../../api/profileApi';
import { AuthAPI } from '../../api/authApi';
import { Virtuoso } from 'react-virtuoso';

export default function Chat() {

    const navigate = useNavigate();

    const inputMessaggioRef = createRef(null);

    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [chatList, setChatList] = useState([]);

    const [chatSelected, setChatSelected] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [followerchange, setFollowerchange] = useState(false)

    const [listFollower, setListFollower] = useState([]);
    const [listFollowing, setListFollowing] = useState([]);
    const [listUser, setListUser] = useState([]);

    const [userProfile, setUserProfile] = useState();
    const [idProfile, setIdProfile] = useState(0);

    const modalRef = useRef(null);
    const { t, i18n } = useTranslation();
    const isScreenSmall = useBreakpointValue({
        base: true,
        md: false

    });
    useEffect(() => {

        getFollowerList();
        getFollowingList();


    }, [])

    useEffect(() => {
        totListOrder()
    }, [listFollower, listFollowing])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                if (key === "getFollowerList") getFollowerList();
                if (key === "getFollowingList") getFollowingList();
                if (key === "getUserbyId") getUserbyId(idProfile);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 401 ||error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }

    function totListOrder() {
        setListUser([...listFollower, ...listFollowing].sort((a, b) => (a.utente.nome > b.utente.nome) ? 1 : ((b.utente.nome > a.utente.nome) ? -1 : 0)))
    }

    async function getFollowerList() {
        ProfileAPI.getFollowerList(tokenUser)
            .then((result) => {
                let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                let tmpUtenti = [];
                for (let u of result) {
                    let utente = u;
                    let randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    utente.utente.bgColor = coloursList[randomIndex];
                    tmpUtenti.push(utente);
                }
                setListFollower(tmpUtenti)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getFollowerList")
                    }
                }
            })
    }
    async function getFollowingList() {

        ProfileAPI.getFollowingList(tokenUser)
            .then((result) => {
                let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                let tmpUtenti = [];
                for (let u of result) {
                    let utente = u;
                    let randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    utente.utente.bgColor = coloursList[randomIndex];
                    tmpUtenti.push(utente);
                }
                setListFollowing([...tmpUtenti])
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("getFollowingList")
                    }
                }
            })
    }

    async function getUserbyId(id) {

        if (id != undefined) {
            setIdProfile(id)
            ProfileAPI.getProfile(id, tokenUser)
                .then((result) => {
                    let userSelected = result.user;
                    let indexUserSelected = listFollowing.findIndex(user => user.utente.id === id);
                    if (indexUserSelected !== -1)
                    userSelected.bgColor = listFollowing[indexUserSelected].utente.bgColor;
                    setUserProfile(userSelected)
                    closeModal()
                    setChatSelected(true)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404 || error.response.status == 500) {
                        }
                        if (error.response.status == 403) {
                            tokenCheck("getUserbyId")
                        }
                    }
                })
        }
    }

    function openModalFunction() {
        setOpenModal(true);

        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)
    }

    function closeModal() {
        document.body.style.overflow = "auto";
        setOpenModal(false);
    }

    function changeModalListFollow() {
        setFollowerchange(!followerchange)
    }

    function goToElencoChat(){
        setChatSelected(false);
    }
    function sendMessage(){
        if (inputMessaggioRef.current != null) {
        console.log("messaggio inviato: " + inputMessaggioRef.current.value);
        inputMessaggioRef.current.value = "";
        }
    }

    function goToChatUser(id) {

        getUserbyId(id)

        window.scrollTo({
            top: 0,
        });
    }

    function navigateToLogin() {
        navigate("/login")
    }

    return (
        <Fragment>
            <MenuComponent pageName="chat" ListClassifica={[]} listFollower={[]} listFollowing={[]} />
            <Flex direction='row'>
                {!chatSelected && (
                    <Box w={'40%'} h={'80vh'} borderRightWidth='1px' borderColor={'coolGray.300'}>
                        <Box h="full">
                            <Flex h={'full'} direction="column" justify='space-between'>
                                <Box h="10vh" borderBottomWidth="1px" borderColor="coolGray.300">
                                    <Flex px="4" h="full" direction="row" align="center" justify="space-between">
                                        <Text bold fontSize={"xl"}>{t("pages.chat.chatList")}</Text>
                                        <Box>
                                            <Button px={5} color="white" colorScheme={'primary'} variant="solid" rounded={"full"} onPress={openModalFunction}>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </Button>
                                        </Box>
                                    </Flex>
                                </Box>
                                {chatList.length <= 0 ? (
                                    <Center px={6} h="full">
                                        <Text mb={4} textAlign="center" fontSize="md">{t("pages.chat.noChatStarted")}</Text>
                                        <Button testid='button-new-chat' variant="solid" onPress={openModalFunction}>
                                            <Text color={'white'}>{t("pages.chat.newChatButton")} <FontAwesomeIcon icon={faPlus} /></Text>
                                        </Button>
                                        {/* <Button variant="ghost" colorScheme={'violet'} mt="5" onPress={() => setChatSelected((prev) => !prev)}>
                                        <Text>{chatSelected ? 'Nascondi' : 'Mostra'} pannello a destra (DEBUG)</Text>
                                    </Button> */}
                                    </Center>
                                ) : (
                                    <p>ok</p>
                                )}
                            </Flex>
                        </Box>
                    </Box>
                )}
                <Box w={chatSelected ? '100%' : '60%'} h={'90vh'} bgColor="coolGray.100">
                    {chatSelected ? (
                        <Flex h={'full'} direction="column" justify='space-between'>
                            <Box px={5} h="10vh" bgColor="white" borderBottomWidth="1px" borderColor="coolGray.300">
                                <Flex h="full" direction="row" align="center">
                                    <Button testid='button-goto-elenco-chat' variant={"ghost"} mr={4} onPress={goToElencoChat}>
                                        <FontAwesomeIcon size={"lg"} icon={faArrowLeft} />
                                    </Button>
                                    {userProfile.avatar.id != 0 ? (
                                        <Avatar alignSelf="center" source={{ uri: userProfile.avatar.url }} />
                                    ) : (
                                        <Avatar bg={userProfile.bgColor} alignSelf="center">
                                            {userProfile.nome.slice(0, 1).toUpperCase() + userProfile.cognome.slice(0, 1).toUpperCase()}
                                        </Avatar>
                                    )}
                                    <Text ml={4} fontSize={"md"}>{userProfile.nome} {userProfile.cognome}</Text>
                                </Flex>
                            </Box>
                            <Box px={5}>
                            </Box>
                            <Box w={'100%'} h={'10vh'} bgColor="white" borderTopWidth={'1px'} borderColor='coolGray.300'>
                                <Center w="100%" h="100%">
                                    <Input testid='input-message' ref={inputMessaggioRef} onSubmitEditing={sendMessage} w="90%" variant="rounded" placeholder="Scrivi un messaggio" InputRightElement={
                                        <Pressable testid='button-send-message' onPress={sendMessage}>
                                            <Text  color="muted.500" mr={5}><FontAwesomeIcon icon={faPaperPlane} /></Text>
                                        </Pressable>} />
                                </Center>
                            </Box>
                        </Flex>
                    ):(
                    <Center h="full">
                        <Image source={{
                            uri: imgChat
                        }} alt="image" w={{ base: "sm", md: "lg" }} h={{ base: "xs", md: "md" }} />
                    </Center>
                    )}
                </Box>
            </Flex>
            <Modal isOpen={openModal} size={"lg"} onClose={closeModal} >
                <Center h="100vh" w="full" ref={modalRef}>
                    <Modal.Content  >
                        <Modal.CloseButton />
                        <Modal.Header>
                            <Text> {t("pages.chat.newChat")}</Text>
                        </Modal.Header>
                        <Modal.Body h={"full"}>
                            <Box w={"100%"}>
                                {listUser.length > 0 ? (
                                    <Box m={2}>
                                        <Virtuoso
                                            style={{ height: "50vh", width: "100%", margin: "0" }}
                                            data={listUser}
                                            // endReached={loadMore}
                                            // overscan={2}
                                            itemContent={(index, userFromList) => {
                                                return (
                                                    <Flex justify="space-between" align="center" key={index} direction="row" >
                                                        <Flex direction='row' alignItems="center">
                                                            <Button testid={'button-user-chat-'+ index} mx="4" variant="unstyled" onPress={() => goToChatUser(userFromList.utente.id)}>
                                                                {userFromList.utente.avatar.id != 0 ? (
                                                                    <Avatar alignSelf="center" source={{ uri: userFromList.utente.avatar.url }} />
                                                                ) : (
                                                                    <Avatar bg={userFromList.utente.bgColor} alignSelf="center">
                                                                        {userFromList.utente.nome.slice(0, 1).toUpperCase() + userFromList.utente.cognome.slice(0, 1).toUpperCase()}
                                                                    </Avatar>
                                                                )}
                                                            </Button>

                                                            <Link onPress={() => goToChatUser(userFromList.utente.id)}>
                                                                <Text >
                                                                    {userFromList.utente.nome} {userFromList.utente.cognome}
                                                                </Text>
                                                            </Link>
                                                        </Flex>
                                                    </Flex>)
                                            }
                                            }
                                            components={<div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>{t("pages.chat.loading")}</div>}
                                        />
                                    </Box>
                                ) : (
                                    <Center h="200px">
                                        <Text bold fontSize="md" textAlign={'center'}>Lista momentaneamente non disponibile.</Text>
                                    </Center>
                                )
                                }
                            </Box >
                        </Modal.Body>
                    </Modal.Content>
                </Center>
            </Modal>
            <Helmet>
                <title>{t("name")} - Chat</title>
            </Helmet>
        </Fragment>
    )
}