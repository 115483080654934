import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const QuestionariAPI = {
    getQuestionari: async function ( token, cancel = false) {
        const response = await api.request({
            url: `/questionario/list`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    getQuestionarioByID: async function (id,token, cancel = false) {
        const response = await api.request({
            url: `/questionario/getbyid/` + id,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    parseQuestionario: async function ( token, cancel = false) {
        const response = await api.request({
            url: `/utente/classifica/`,
            method: "GET",
            data: {
                "type": "Sim",
                "id": "678" 
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    classificaQuestionario: async function (id, token, cancel = false) {
        const response = await api.request({
            url: `/questionario/parse`,
            method: "POST",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

    parseQuestionario: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/questionario/parse`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },

}
const cancelApiObject = defineCancelApiObject(QuestionariAPI)