import * as React from 'react';
import { useState, useEffect } from 'react';
import { HStack, Box, Center, Text, Avatar, Button, Tooltip, Image } from 'native-base';
import { useBreakpointValue } from 'native-base';
import { useTranslation } from 'react-i18next';

import ListTraguardi from './listTraguardi';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLarge, faBook, faTrophy, faRankingStar } from '@fortawesome/free-solid-svg-icons'

import { ProfileAPI } from '../../api/profileApi';
import { AuthAPI } from '../../api/authApi';

export default function HeaderProfile(props) {

    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [id, setId] = useState(0);
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [urlAvatar, setUrlAvatar] = useState(`${process.env.REACT_APP_FETCH_URL}static/uploads/placeholder.jpg`);
    const [livello, setLivello] = useState(10); // TODO: valore test => da azzerare
    const [progresso, setProgresso] = useState(35); // TODO: valore test => da azzerare
    const [dataTraguardi, setDataTraguardi] = useState([])
    const [userBadges, setUserBadges] = useState([]);

    const [userFollow, setUserFollow] = useState(false)
    const [following, setFollowing] = useState([])

    const { t, i18n } = useTranslation();

    const resp = useBreakpointValue({
        base: true,
        lg: false
    });

    // useEffect(() => {
    //         setUserFollow(checkFollowing(props.followingCheckList))
    // }, [])

    useEffect(() => {
        if (props.userBadges != undefined) {
            setUserBadges(props.userBadges)}
    }, [props.userBadges])


    useEffect(() => {
        if (props.user != undefined && props.followingCheckList != undefined) {

            //Quando cambia utente
            setId(props.user.id)
            setNome(props.user.nome);
            setCognome(props.user.cognome);
            setUrlAvatar(props.user.avatar.url);
            // setLivello(props.user.livello);
            // setProgresso(props.user.progresso);
            // setDataTraguardi(props.traguardi)
            setUserFollow(checkFollowing(props.followingCheckList,props.user.id))
        }
    }, [props.user, props.followingCheckList])

    useEffect(() => {
        if (props.following != undefined) {
            // console.log("following", props.following)
            setFollowing(props.following);
            // if (user.id != id) 
        }
    }, [props.following])

    useEffect(() => {
        if (props.followingCheckList != undefined && props.user != undefined) {
            setUserFollow(checkFollowing(props.followingCheckList,props.user.id))
        }
    }, [props.followingCheckList])

    // useEffect(() => {
    //     if (userTmp === null) {
    //         setNome(props.user.nome);
    //         setCognome(props.user.cognome);
    //         setUrlAvatar(props.user.avatar.url);
    //         // setLivello(props.user.livello);
    //         // setProgresso(props.user.progresso);
    //         setDataTraguardi(props.traguardi)
    //     }
    //     else {
    //         setNome(userTmp.user.nome);
    //         setCognome(userTmp.user.cognome);
    //         setUrlAvatar(userTmp.user.avatar.url);
    //         // setDataTraguardi(props.userTmp.traguardi)
    //     }
    // }, [userTmp])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));

                if (key === "follow") follow();
                if (key === "unfollow") unfollow();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        props.navigateToLogin();
                    }
                }
            })
    }

    async function follow() {

        let idTmp = {
            followingid: String(id)
        }

        ProfileAPI.follow(idTmp, tokenUser)
            .then((result) => {
                setUserFollow(true)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("follow")
                    }
                }
            })
    }
    async function unfollow() {

        let idTmp = {
            followingid: String(id)
        }

        ProfileAPI.unfollow(idTmp, tokenUser)
            .then((result) => {
                setUserFollow(false)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        tokenCheck("unfollow")
                    }
                }
            })
    }

    function checkFollowing(following, id) {
        for (let utente of following) {
            if (utente.utente.id === id) {
                return true
            }
        }
    }

    return (
        <Box bg="primary.600" mt={"5em"}>
            <Center p="8">
                {urlAvatar != `${process.env.REACT_APP_FETCH_URL}static/uploads/placeholder.jpg` ? (
                    <Image alt="avatar" size="xl" borderRadius={100} bg="violet.600" source={{ uri: urlAvatar }} />
                ) : (
                    <Avatar bg="violet.600" alignSelf="center" size="2xl">
                        {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                    </Avatar>
                )}
                <Text color="white" fontSize="2xl" mt="1" fontWeight="bold">{nome} {cognome}</Text>
                {/* <Text color="white" fontSize="md" mt="1">{t("pages.profile.level")}: {livello}</Text> */}

                {resp && (
                    <Box mt="4">
                        <ListTraguardi userBadges={userBadges} viewAllAchievements={false} />
                    </Box>
                )}


                {/* <Box w="90%" maxW="400" mt="2"><Progress value={progresso} mx="4" size="md" /></Box> */}
                {user.id == id && (<HStack space={4} mt="5">
                    <Tooltip hasArrow label={t("pages.profile.survey")} openDelay={200}>
                        <Button bgColor="white" mr="2" variant="solid" w={"48px"} h={"48px"} padding={6} onPress={props.goToQuestionari}
                            rounded={"full"} leftIcon={<div className='badge-container'><Text color="violet.700"><FontAwesomeIcon className='delete-icon' size="xl" icon={faBook} /></Text></div>} />
                    </Tooltip>
                    <Tooltip hasArrow label={t("pages.profile.friends")} openDelay={200}>
                        <Button onPress={props.openViewAllFriendsTab} bgColor="white" mr="2" variant="solid" w={"48px"} h={"48px"} padding={6} rounded={"full"} leftIcon={<div className='badge-container'><Text color="violet.700"><FontAwesomeIcon className='delete-icon' icon={faUserLarge} size="xl" /></Text></div>} />
                    </Tooltip>
                    <Tooltip hasArrow label={t("pages.profile.achievements")} openDelay={200}>
                        <Button onPress={props.openViewAllAchievementsTab} bgColor="white" mr="2" variant="solid" w={"48px"} h={"48px"} padding={6} rounded={"full"} leftIcon={<div className='badge-container'><Text color="violet.700"><FontAwesomeIcon className='delete-icon' icon={faTrophy} size="xl" /></Text></div>} />
                    </Tooltip>
                    <Tooltip hasArrow label={t("pages.profile.leaderboard")} openDelay={200}>
                        <Button onPress={props.openViewLeaderboardTab} bgColor="white" variant="solid" w={"48px"} h={"48px"} padding={6} rounded={"full"} leftIcon={<div className='badge-container'><Text color="violet.700"><FontAwesomeIcon className='delete-icon' icon={faRankingStar} size="xl" /></Text></div>} />
                    </Tooltip>
                </HStack>)}
                {user.id != id && (
                    <Button mt={3} px={6} colorScheme={userFollow == true ? "danger" : "secondary"} onPress={userFollow ? unfollow : follow}>
                        {userFollow == true ? "Non seguire più" : "Segui"}
                    </Button>)
                }
            </Center>
        </Box>
    );
}