import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const TimelineAPI = {
    timeline: async function (token, offset, limit, cancel = false) {
        const response = await api.request({
            url: `/timeline/me?offset=${offset}&limit=${limit}`,
            method: "GET",
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(TimelineAPI)