import * as React from 'react';
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { Virtuoso } from 'react-virtuoso'
import { VictoryPie, VictoryLabel, VictoryTooltip } from 'victory';
// TODO: componente mancante
// import PieGraph from '../components/pieGraph';

import { Box, Center, Modal, Text, Avatar, VStack, Flex, Container, Button, HStack, CloseIcon, Input, FormControl, Checkbox, Tooltip } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight, faCheck, faClose } from '@fortawesome/free-solid-svg-icons'
import { async } from 'q';


export default function StatisticheQuizSvolto(props) {

    const user = JSON.parse(localStorage.getItem("user"));

    const regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const [valid, setValid] = useState(true);

    const [questionarioCompletato, setQuestionarioCompletato] = useState(props.questionario);
    const [risposteCorrette, setRisposteCorrette] = useState(0);
    const [risposteErrate, setRisposteErrate] = useState(0);
    const [risposteOmesse, setRisposteOmesse] = useState(0);

    const [punteggio, setPunteggio] = useState(0);
    const [tempoDiCompletamento, setTempoDiCompletamento] = useState(props.tempo);
    const [showRiepilogoDomande, setShowRiepilogoDomande] = useState(false);
    const [idQuestionario, setIdQuestionario] = useState(props.idQuestionario !== undefined ? props.idQuestionario : -1);
    const [idVolume, setIdVolume] = useState(props.idVolume !== undefined ? props.idVolume : -1);
    const [idUtente, setIdUtente] = useState(props.idUtente !== undefined ? props.idUtente : 99);
    const [isGratuito, setIsGratuito] = useState(true); // da impostare a false
    const [typeQuestionario, setTypeQuestionario] = useState('Sim');
    const [sogliaSufficienza, setSogliaSufficienza] = useState(0);
    const [esito, setEsito] = useState(false);
    const [nomeQuestionario, setNomeQuestionario] = useState(props.nomeQuestionario);
    const [showGraficoOnMobileOpen, setShowGraficoOnMobileOpen] = useState(false);
    const [emailPageOpen, setEmailPageOpen] = useState(true); // da impostare a false
    const [consensoPrivacy, setConsensoPrivacy] = useState(false);
    const [consensoNewsletter, setConsensoNewsletter] = useState(false);
    const [emailUtente, setEmailUtente] = useState('');

    useEffect(() => {
        setIdQuestionario(props.idQuestionario);
        setIdVolume(props.idVolume);
        // setTypeQuestionario(props.typeQuestionario);
        // setIsGratuito(true)
        //setIsGratuito(props.isGratuito); // da riattivare
        setSogliaSufficienza(props.sogliaSufficienza);
        setQuestionarioCompletato(props.questionario)

        // da sistemare
        calculateRisposteCorrette(props.questionario);
        calculateRisposteErrate(props.questionario);
        calculateRisposteOmesse(props.questionario);
        setEmailUtente(user.email)
        if (typeQuestionario === 'Sim') {
            calculateEsito();
            saveQuestionario(props.idQuestionario, props.idVolume);
        }
    }, [])

    function saveQuestionario(idQuestionario, idVolume) {
        let quizTmp = questionarioCompletato;
        let risposteCorretteTmp = 0, risposteErrateTmp = 0, risposteOmesseTmp = 0;
        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].correctAnswer.includes(quizTmp[i].givenAnswer))
                risposteCorretteTmp += 1;
            if (quizTmp[i].givenAnswer === '')
                risposteOmesseTmp += 1;
            if (quizTmp[i].givenAnswer !== "" && (!quizTmp[i].correctAnswer.includes(quizTmp[i].givenAnswer))) {
                risposteErrateTmp += 1;

            }
        }
        let data = {
            "nomeQuestionario": nomeQuestionario,
            "idQuestionario": parseInt(idQuestionario),
            "idVolume": idVolume,
            "idUtente": "" + idUtente,
            "superato": esito,
            "type": typeQuestionario,
            "risposteOmesse": risposteOmesseTmp,
            "risposteErrate": risposteErrateTmp,
            "risposteCorrette": risposteCorretteTmp,
            "quiz": questionarioCompletato
        }
        let requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        };

        if (!isGratuito) {
            // fetch(process.env.REACT_APP_FETCH_URL + 'questionario/archive/add', requestOptions)
            //     .then(response => response.json())
            //     .then(result => { })
        }
    }

    function calculateRisposteCorrette(questionarioTmp) {
        let quizTmp = questionarioTmp;
        let risposteCorretteTmp = 0;
        let punteggioTmp = punteggio;
        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].correctAnswer.includes(quizTmp[i].givenAnswer)) {
                risposteCorretteTmp++;
                punteggioTmp += quizTmp[i].puntiCorretta;
            }
        }
        setRisposteCorrette(risposteCorretteTmp);
        setPunteggio(punteggioTmp);
    }
    function calculateRisposteErrate(questionarioTmp) {
        let quizTmp = questionarioTmp;
        let risposteErrateTmp = 0;
        let punteggioTmp = punteggio;
        for (let i = 0; i < quizTmp.length; i++) {
            if ((!quizTmp[i].givenAnswer.includes(quizTmp[i].correctAnswer)) && quizTmp[i].givenAnswer !== "") {
                risposteErrateTmp++;
                punteggioTmp += quizTmp[i].puntiErrata;
            }
        }
        setRisposteErrate(risposteErrateTmp);
        setPunteggio(punteggioTmp);
    }
    function calculateRisposteOmesse(questionarioTmp) {
        let quizTmp = questionarioTmp;
        let risposteOmesseTmp = 0;
        let punteggioTmp = punteggio;

        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].givenAnswer === '') {
                risposteOmesseTmp++;
                punteggioTmp += quizTmp[i].puntiOmessa;
            }
        }
        setRisposteOmesse(risposteOmesseTmp);
        setPunteggio(punteggioTmp);
    }


    function calculateEsito() {

        let quizTmp = questionarioCompletato;
        let punteggioTmp = punteggio;
        for (let i = 0; i < quizTmp.length; i++) {
            if (quizTmp[i].correctAnswer.includes(quizTmp[i].givenAnswer))
                punteggioTmp += quizTmp[i].puntiCorretta;
            if (quizTmp[i].givenAnswer === '')
                punteggioTmp += quizTmp[i].puntiOmessa;
            if (quizTmp[i].givenAnswer !== "" && (!quizTmp[i].correctAnswer.includes(quizTmp[i].givenAnswer))) {
                punteggioTmp += quizTmp[i].puntiErrata;

            }
        }

        setPunteggio(punteggioTmp);
        punteggioTmp > sogliaSufficienza ? setEsito(true) : setEsito(false);
    }
    function rowDomanda(index) {
        return (
            <Box p={5} mx={2} borderColor="coolGray.200" borderWidth="1" rounded="md">
                <Text bold >
                    Risposta n. {index + 1}
                </Text>
                <Text display={{ base: 'none', md: 'flex' }} bold fontSize="2xl">
                    <div dangerouslySetInnerHTML={{ __html: questionarioCompletato[index].title }}></div>
                </Text>
                <Text display={{ base: 'flex', md: 'none' }} mb={2} mt={1} bold fontSize="lg">
                    <div dangerouslySetInnerHTML={{ __html: questionarioCompletato[index].title }}></div>
                </Text>
                {questionarioCompletato[index].choices.map((choice, i) => (
                    <Flex direction="row" alignItems="center" key={i} mt={{ base: 2, md: 2 }}  >
                        <Text fontSize="md" mr={2}>
                            {(i + 10).toString(36).toUpperCase() + ")"}
                        </Text>
                        <div dangerouslySetInnerHTML={{ __html: choice.text }} />
                        <Text variant="subtitle2" style={{ color: changeColorAnswerText(questionarioCompletato[index], choice) }}>
                            {choice.value === questionarioCompletato[index].givenAnswer && !questionarioCompletato[index].correctAnswer.includes(questionarioCompletato[index].givenAnswer) && (
                                <Text ml={2} color="danger.500" bold>(Risposta Fornita - Errata)</Text>
                            )}
                            {choice.value !== questionarioCompletato[index].givenAnswer &&
                                (questionarioCompletato[index].correctAnswer.includes(choice.value)) && (
                                    <Text ml={2} color="success.600" bold>(Corretta)</Text>
                                )}

                            {choice.value === questionarioCompletato[index].givenAnswer &&
                                questionarioCompletato[index].correctAnswer.includes(questionarioCompletato[index].givenAnswer) && (
                                    <Text ml={2} color="success.600" bold>(Risposta fornita - Corretta)</Text>
                                )}
                        </Text>
                    </Flex>
                ))
                }
                {
                    questionarioCompletato[index].givenAnswer === '' && (
                        <Text bold mt={2} color="info.700">Risposta Omessa</Text>
                    )
                }
            </Box >
        )
    }


    function changeColorAnswerText(domanda, choice) {
        if (choice.value === domanda.givenAnswer) { //Se è la domanda selezionata
            if (!domanda.correctAnswer.includes(choice.value)) return 'error.500'
        }
        if (domanda.correctAnswer.includes(choice.value)) return 'success.500'
    }

    function goToRiepilogo() {
        setShowRiepilogoDomande((prev) => !prev)
    }
    function goToHome() {
        props.navigateTo("/home");
    }
    function goToElencoQuestionari() {
        props.navigateTo("/questionari");
    }



    function goToStatistiche() {
        setShowRiepilogoDomande(false);
    }
    function openDialogPieChart() {
        setShowGraficoOnMobileOpen(!showGraficoOnMobileOpen);
    }

    function emailUtenteSet(e) {
        // let emailTmp = e.target.value;
        // emailTmp = emailTmp.replace(/\s+/g, '');
        // setEmailUtente(emailTmp);
        setEmailUtente(e);
    }
    function changeConsensoPrivacy() {
        setConsensoPrivacy(prev => !prev);
    }
    function changeConsensoNewsletter() {
        setConsensoNewsletter(prev => !prev);
    }
    async function saveNewEmail() {
        let requestOptions = {
            method: 'POST',
            body: new URLSearchParams({
                email: emailUtente,
                consenso_privacy: consensoPrivacy,
                consenso_newsletter: consensoNewsletter
            })
        };

        fetch(process.env.REACT_APP_FETCH_URL + 'contattoMailingList/add', requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.success === true) {
                    setEmailPageOpen(false);
                }
            });

    }

    // ---------------------------------------------

    function validate() {
        if (emailUtente === "" || regExEmail.test(emailUtente) === false || consensoPrivacy === true) {
            setValid(false)
        } else {
            setValid(true)
            saveNewEmail()
        }
    }

    return (
        <Fragment>
            <Center w="100%" h="100%">
                    <Container w="full">
                        {showRiepilogoDomande === false ? (
                            <Fragment>
                                <Box w="full" rounded="lg" overflow="hidden" shadow="3" > {/*Esito questionario*/}
                                    <Flex py={5} w='full' direction='row' justify='center'>
                                        <Avatar bg={esito ? 'success.500' : 'error.500'} style={{ width: 60, height: 60 }}>
                                            <Text color="white" >
                                                <FontAwesomeIcon icon={esito ? faCheck : faClose} />
                                            </Text>
                                        </Avatar>
                                        <Box ml={5}>
                                            <Text >Esito Questionario:</Text>
                                            <Text fontSize='3xl' color={esito ? 'success.500' : 'error.500'}>
                                                {esito ? 'Superato' : 'Non superato'}
                                            </Text>
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box w="full" rounded="lg" overflow="hidden" shadow="3" p={5} > {/*Numero risposte*/}
                                    <Center>
                                        <HStack container space={5}>
                                            <Center>
                                                <Avatar bg={'success.500'}>
                                                    <Text color="white" >
                                                        <FontAwesomeIcon icon={faCheck} />
                                                    </Text>
                                                </Avatar>
                                                <Text variant="subtitle2" textAlign={"center"} bold mt={2}>Risposte Corrette</Text>
                                                <Text variant="body" >{risposteCorrette}</Text>
                                            </Center>
                                            <Center>
                                                <Avatar bg={'error.500'} >
                                                    <Text color="white" >
                                                        <FontAwesomeIcon icon={faClose} />
                                                    </Text>
                                                </Avatar>
                                                <Text variant="subtitle2" textAlign={"center"} bold mt={2}>Risposte Errate</Text>
                                                <Text >{risposteErrate}</Text>
                                            </Center>
                                            <Center>
                                                <Avatar bg={'info.500'}>
                                                    <Text color="white" >
                                                        <FontAwesomeIcon icon={faClose} />
                                                    </Text>
                                                </Avatar>
                                                <Text variant="subtitle2" textAlign={"center"} bold mt={2}>Risposte Omesse</Text>
                                                <Text  >{risposteOmesse}</Text>
                                            </Center>
                                        </HStack>
                                    </Center>
                                </Box>
                                <Box w="full" rounded="lg" overflow="hidden" shadow="3" p={5} > {/*Grafico a torta*/}
                                    <Center>
                                        <Box w="40%">
                                            <VictoryPie
                                                colorScale={["#22c55e", "#0ea5e9", "#ef4444"]}
                                                innerRadius={100}
                                                padAngle={({ datum }) => datum.y}
                                                labelComponent={<VictoryTooltip
                                                    cornerRadius={2}
                                                    flyoutStyle={{
                                                        fill: "black",
                                                        // stroke: "white",
                                                        strokeWidth: 15
                                                    }}
                                                />}
                                                data={[
                                                    { l: "white", label: risposteCorrette ? "Corrette: " + risposteCorrette : " ", y: risposteCorrette },
                                                    { l: "white", label: risposteOmesse ? "Omesse: " + risposteOmesse : " ", y: risposteOmesse },
                                                    { l: "white", label: risposteErrate ? "Errate: " + risposteErrate : " ", y: risposteErrate }
                                                ]}
                                                style={
                                                    { labels: { fill: "white", size: "2em" } }
                                                }
                                            />
                                        </Box>
                                        <Center >
                                            <HStack w="full" space={5}>
                                                <Flex direction='row'>
                                                    <Box w='20px' h='20px' bgColor={'success.500'}></Box>
                                                    <Text ml={2}>Risposte corrette</Text>
                                                </Flex>
                                                <Flex direction='row'>
                                                    <Box w='20px' h='20px' bgColor={'info.500'}></Box>
                                                    <Text ml={2}>Risposte omesse</Text>
                                                </Flex>
                                                <Flex direction='row'>
                                                    <Box w='20px' h='20px' bgColor={"#ef4444"}></Box>
                                                    <Text ml={2}>Risposte errate</Text>
                                                </Flex>
                                            </HStack>
                                        </Center>
                                    </Center>
                                </Box>
                            </Fragment>
                        ) : (
                            <Box height={{ base: '75vh', md: '80vh' }} w='full' p={5} borderColor="coolGray.300" rounded="md" borderWidth="1">
                                <Virtuoso
                                    style={{ height: '80vh', width: '100%' }}
                                    totalCount={questionarioCompletato.length}
                                    itemContent={index => (rowDomanda(index))}
                                />
                            </Box>
                        )}
                        <Flex w="full" direction='row' justify="space-between" mt={4}>
                            <Flex direction="row">
                                <Button variant="outline" rounded="lg" onPress={goToElencoQuestionari}>Torna all'elenco dei questionari</Button>
                                <Button variant="outline" rounded="lg" onPress={goToHome} ml={2}>Torna alla home</Button>
                            </Flex>
                            {showRiepilogoDomande ? (
                                <Button variant="solid" rounded="lg" onPress={goToRiepilogo}>Torna alle statistiche</Button>
                            ) : (
                                <Button variant="solid" rounded="lg" onPress={goToRiepilogo}>Riepilogo domanda</Button>
                            )}
                        </Flex>
                    </Container>
            </Center>
        </Fragment>
    )
}


export const GoToImpostazioni = (props) => {
    //const navigate = useNavigate()

    const changePage = e => {
        e.preventDefault()
        // navigate("/questionario", { state: { idQuestionario: props.idQuestionario, idUtente: props.idUtente } })
    }
    const goToListaVolumi = e => {
        e.preventDefault()
        //  navigate("/home", { state: { volumeSelezionato: false } })
    }
    const gotListaQuestionari = e => {
        e.preventDefault()
        // navigate("/home")
    }

    return (
        <Box >
            <Box display={{ base: 'block', md: 'none' }} >
                {/* icona deve essere cambiata con icona restart */}
                <Button w={"full"} variant="contained" onClick={changePage} leftIcon={<CloseIcon />}>Ripeti questionario</Button>
                <Button w={"full"} variant="outlined" mt={3} mb={1} onClick={gotListaQuestionari} >Lista questionari</Button>
                <Button w={"full"} variant="outlined" onClick={goToListaVolumi} >Lista volumi</Button>
            </Box>
            <Box display={{ base: 'none', md: 'flex' }} >
                <Button px={5} variant="outlined" onClick={gotListaQuestionari} >Lista questionari</Button>
                <Button px={5} mx={1} variant="outlined" onClick={goToListaVolumi}>Lista volumi</Button>
                {/* icona deve essere cambiata con icona restart */}
                <Button px={5} variant="contained" onClick={changePage} leftIcon={<CloseIcon />}>Ripeti questionario</Button>
            </Box>
        </Box >
    )
}
