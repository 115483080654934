import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';

//native-base
import { Box, FormControl, Image, Input, Stack, Icon } from 'native-base';
import { VStack, HStack } from 'native-base';
// import { Checkbox } from 'native-base';
import { Center } from 'native-base';
import { Heading } from 'native-base';
import { Button } from "native-base";
import { Divider } from 'native-base';
import { IconButton } from 'native-base';
import { Link } from 'native-base';
import { Spinner } from 'native-base';
import Logo from "../../img/logoEspanso.png";
import { AlertDialog } from "native-base";
import { Text } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faCheck, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

//react-router-dom
import { useNavigate } from "react-router-dom";

//react-i18next
import { useTranslation } from 'react-i18next';

import { Helmet } from "react-helmet";
//API
import { AuthAPI } from '../../api/authApi';


export default function PwsReset() {

    const urlParams = new URLSearchParams(window.location.search);
    const urlToken = urlParams.get('token');
    const urlEmail = urlParams.get('email');

    const navigate = useNavigate();

    const regExPassword = /^[a-zA-Z0-9]{8,}$/;

    const { t, i18n } = useTranslation();

    const [showPws, setShowPws] = useState(false);

    const [pws, setPws] = useState("");
    const [pwsConferma, setPwsConferma] = useState("");
    const [valid, setValid] = useState();
    const [level, setLevel] = useState(0);
    const [errorMessageReset, setErrorMessageReset] = useState("");
    const [messageDisplay, setMessageDisplay] = useState(false);

    const handleClick = () => { setShowPws(!showPws) };

    async function updatepassword() {
        if (pws === "" || regExPassword.test(pws) === false || pws !== pwsConferma) {
            setValid(false)
            console.log("password non modificata")
        } else {
            let userData = {
                email: urlEmail,
                token: urlToken,
                newPassword: pws
            }
            AuthAPI.updatePws(userData)
                .then((result) => {
                    setValid(true);
                    setLevel(1);
                    return true;
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404 || error.response.status == 500 || error.response.status == 403) {
                            setLevel(2);
                            setErrorMessageReset(error.response.data.description);
                        }
                    }
                })
        }
    }

    function pwsSet(value) {
        setPws(value)
    }

    function pwsConfermaSet(value) {
        setPwsConferma(value)
    }

    function resetPws() {

        if (pws === "" || regExPassword.test(pws) === false || pws !== pwsConferma) {
            setValid(false)
        } else {
            setValid(true)
            setLevel(1)
        }
    }

    function navigateToLogin() {
        navigate("/login")
    }

    function navigateToPwsRecover() {
        navigate("/pwsrecover")
    }

    return (
        <Fragment>
            <Box height={"100vh"} backgroundColor={{ base: "white", sm: "primary.800"}}>
                <Center height={"100vh"}>
                    <Box rounded={{ base: "none", sm: "lg" }}  overflow="hidden" backgroundColor="white" borderColor={{ base: "none", sm: "coolGray.200"}} borderWidth={{ base: "0", sm: "1"}}>
                        <Center w={{ base: "100vw" }} maxW="2xl" rounded={{ base: "none", sm: "lg" }} overflow="hidden" borderColor="coolGray.200" padding={"2em"}>
                            <Image source={{
                                uri: Logo
                            }} alt="Logo" w="170px" h="50px" />


                            {level == 0 &&
                                (<Stack space={4} w="100%" mx="auto">
                                    <VStack space={2}>
                                        <Text fontSize="3xl" bold textAlign="center" mt="5" >{t("pages.resetPws.newpws")}</Text>
                                        <Text fontSize="6xl" mt="3" textAlign="center">
                                            <FontAwesomeIcon icon={faKey} color="#65a30d" />
                                        </Text>
                                        <FormControl isRequired isInvalid={valid != true}>
                                            <FormControl.Label>Password</FormControl.Label>
                                            <Input w="100%" rounded="none" value={pws} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                                <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                            } onChangeText={value => pwsSet(value)} />
                                            {(valid === false && pws === "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                            {(regExPassword.test(pws) === false && pws !== "") && (<FormControl.ErrorMessage>{t("errors.pws.invalid")}</FormControl.ErrorMessage>)}
                                        </FormControl>
                                        <FormControl isRequired isInvalid={valid != true}>
                                            <FormControl.Label>{t("pages.signup.confirmpassword")}</FormControl.Label>
                                            <Input w="100%" rounded="none" value={pwsConferma} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                                <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                            } onChangeText={value => pwsConfermaSet(value)} />
                                            {(valid === false && pwsConferma === "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                            {(pwsConferma !== pws && pwsConferma !== "") && (<FormControl.ErrorMessage>{t("errors.pws.unequal")}</FormControl.ErrorMessage>)}
                                            {/* {messageDisplay === true && (<FormControl.ErrorMessage>{errorMessageReset}</FormControl.ErrorMessage>)} */}
                                        </FormControl>

                                        <Button my="3" colorScheme="primary" rounded="lg" onPress={updatepassword}>Reset password</Button>
                                    </VStack>
                                </Stack>)
                            }
                            {level == 1 &&
                                (<Stack space={4} w="100%" maxW="400px" mx="auto">
                                    <VStack space={2}>
                                        {/* <FontAwesomeIcon icon={faCheck} size="2xl" color='#059669' /> */}
                                        <Text fontSize="6xl" mt="3" textAlign="center">
                                            <FontAwesomeIcon icon={faCheck} color="#65a30d" />
                                        </Text>
                                        <Text fontSize="3xl" textAlign="center">{t("pages.resetPws.confirm")}</Text>
                                        <Text fontSize="md" textAlign="center" >{t("pages.resetPws.confirmSub")}</Text>
                                        {/* <Text fontSize="md" alignSelf="center">Fai click sul pulsante </Text> */}
                                        <Button colorScheme="primary" rounded="lg" onPress={navigateToLogin} mt="3">{t("pages.signup.gotologin")}</Button>
                                    </VStack>
                                </Stack>)
                            }
                            {level == 2 &&
                                (<Stack space={4} w="100%" maxW="400px" mx="auto">
                                    <VStack space={2}>
                                        {/* <Text fontSize="6xl" mt="3" textAlign="center">
                                            <FontAwesomeIcon icon={faCheck} color="#65a30d" />
                                        </Text> */}
                                        <Text fontSize="3xl" textAlign="center">{t("pages.resetPws.error")}</Text>
                                        {/* <Text fontSize="md" textAlign="center" > Siamo spiacenti, {errorMessageReset == "Token scaduto" && ("le credenziali inviatele sono scadute.")}</Text> */}
                                        <Text fontSize="md" textAlign="center" >{t("pages.resetPws.errorSub")}</Text>
                                        {/* <Text fontSize="md" alignSelf="center">Fai click sul pulsante </Text> */}
                                        <Button colorScheme="primary" rounded="lg" onPress={navigateToPwsRecover} mt="3">{t("pages.resetPws.gotopwsrecover")}</Button>
                                    </VStack>
                                </Stack>)
                            }
                        </Center>
                    </Box>
                </Center>
            </Box>
            <Helmet>
                <title>{t("name")} - {t("pages.login.title")}</title>
            </Helmet>
        </Fragment>
    )
}


{/* <Fragment>
<Box height={"100vh"} backgroundColor="primary.800">
    <Center height={{ base: "", sm: "100vh" }}>
        <Box rounded="lg" overflow="hidden" backgroundColor="white" borderColor="coolGray.200" borderWidth="1">
            <Center w={{ base: "100vw" }} maxW="2xl" rounded={{ base: "none", sm: "lg" }} overflow="hidden" borderColor="coolGray.200" padding={"2em"}>
                <Image source={{
                    uri: Logo
                }} alt="Logo" w="170px" h="50px" />
                {level == 0 &&
                    (<Stack space={4} w="100%" mx="auto">
                        <VStack space={2}>
                            <Text fontSize="3xl" bold textAlign="center" mt="5">
                                {t("pages.recoveryPws.haveYouForgotPws")}
                            </Text>
                            <Text fontSize="md" textAlign="center" >
                                {t("pages.recoveryPws.dontWorry")}
                            </Text>
                            <Text fontSize="6xl" mt="3" textAlign="center">
                                <FontAwesomeIcon icon={faKey} color="#65a30d" />
                            </Text>
                            <FormControl my="3" isRequired isInvalid={valid != true}>
                                <Input w="100%" rounded="none" size="lg" placeholder="Email" onChangeText={value => emailSet(value)} />
                                {(valid === false && email === "") && (<FormControl.ErrorMessage>{t("errors.email.empty")}</FormControl.ErrorMessage>)}
                                {(regExEmail.test(email) === false && email !== "") && (<FormControl.ErrorMessage>{t("errors.email.invalid")}</FormControl.ErrorMessage>)}
                                {displayErrorMessage && (<FormControl.ErrorMessage> {errorMessage}</FormControl.ErrorMessage>)}
                            </FormControl>

                            <Button colorScheme="primary" rounded="lg" onPress={confirmSendEmail}>
                                {t("pages.recoveryPws.sendEmail")}
                            </Button>
                            <Button colorScheme="primary" variant="outline" rounded="lg" onPress={navigateToLogin}>
                                {t("pages.signup.gotologin")}
                            </Button>
                        </VStack>
                    </Stack>)
                }
                {level == 1 &&
                    (<Stack space={4} w="100%" maxW="400px" mx="auto">
                        <VStack space={2} mt="5">
                            <Text fontSize="3xl" textAlign="center" bold>{t("pages.recoveryPws.checkYourEmail")}</Text>
                            <Text fontSize="6xl" mt="3" textAlign="center">
                                <FontAwesomeIcon icon={faMailBulk} color="#65a30d" />
                            </Text>
                            <Text fontSize="md" textAlign="center">{t("pages.recoveryPws.linkSent")} <b>{email}</b></Text>
                            <Button colorScheme="primary" rounded="lg" onPress={navigateToLogin} mt="3" >
                                {t("pages.signup.gotologin")}
                            </Button>
                        </VStack>
                    </Stack>)
                }
            </Center>
        </Box>
    </Center>
</Box>
<Helmet>
    <title>{t("name")} - {t("pages.login.title")}</title>
</Helmet>
</Fragment> */}

