import React from 'react';
import { Fragment, useEffect, useState } from "react";
import { Box, Button, Flex } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

export default function Pagination(props) {

    const [currentPage, setCurrentPage] = useState(props.currentPage);
    const [maxPage, setMaxPage] = useState(props.maxPage);
    const [minPage, setMinPage] = useState(props.minPage);
    const [total, setTotal] = useState(props.total);
    const [quizSection, setQuizSection] = useState(props.quizSection);
    const [totalPages, setTotalPages] = useState(props.maxPage - 1);
    const [lastSectionNumberBool, setLastSectionNumberBool] = useState(props.lastSectionNumberBool);
    const [lastSectionNumber, setLastSectionNumber] = useState(props.lastSectionNumber);
    const [quizPartenza, sertQuizPartenza] = useState(props.quizPartenza);
    const [nextArrowDisabled, setNextArrowDisabled] = useState(props.nextArrowDisabled);
    const [pages, setPages] = useState([]);

    useEffect(() => {
        let tmpPages = [];
        for (let i = 0; i <= total - 1; i++) {
            let pageObj = {
                pageNumber: i,
                checkQuiz: false
            }
            tmpPages.push(pageObj);
        }
        setPages(tmpPages);
    }, [])

    useEffect(() => {
        if (props.currentPage !== currentPage) {
            setCurrentPage(props.currentPage);
            let previousPage = props.currentPage - 1;
            let indexPage = pages.findIndex(pagina => pagina.pageNumber == previousPage)
            if (previousPage <= maxPage && previousPage >= minPage) {
                let tmpPages = pages;
                if (quizSection.includes(previousPage)) {
                    tmpPages[indexPage].checkQuiz = true
                    setPages(tmpPages);
                }
            }
        }
    }, [props.currentPage]);

    useEffect(() => {
        if (props.maxPage !== maxPage) setMaxPage(props.maxPage)
    }, [props.maxPage]);

    useEffect(() => {
        if (props.minPage !== minPage) setMinPage(props.minPage)
    }, [props.minPage]);

    useEffect(() => {
        if (props.total !== total) setTotal(props.total)
    }, [props.total]);

    useEffect(() => {
        if (props.quizSection !== quizSection) setQuizSection(props.quizSection)
    }, [props.quizSection]);

    useEffect(() => {
        if (props.lastSectionNumber !== lastSectionNumber) setLastSectionNumber(props.lastSectionNumber)
    }, [props.lastSectionNumber]);

    useEffect(() => {
        if (props.lastSectionNumberBool !== lastSectionNumberBool) setLastSectionNumberBool(props.lastSectionNumberBool)
    }, [props.lastSectionNumberBool]);

    useEffect(() => {
        if (props.nextArrowDisabled !== nextArrowDisabled) setNextArrowDisabled(props.nextArrowDisabled)
    }, [props.nextArrowDisabled]);

    function handlePrevClick() {
        props.onPrevClick();
    }
    function handleNextClick() {
        props.onNextClick();
    }
    function handlePageClick(e) {
        props.onPageChange(parseInt(e.target.id))
    }

    return (
        <Box mb={5} bgColor={"white"}>
            {pages.length > 0 && (
                <Flex direction='row' className='pagination' >
                    <Button w="10%" colorScheme={'primary'} variant="ghost" onPress={handlePrevClick} disabled={currentPage === pages[0].pageNumber}
                        leftIcon={<FontAwesomeIcon className='delete-icon' size="xl" icon={faArrowLeft} />} />
                    <Flex w="80%" direction="row" justify={'space-around'} p={3} >
                        {pages.map((page, index) => (
                            <Fragment key={index}>
                                {page.pageNumber <= maxPage && page.pageNumber >= minPage && (
                                    index < lastSectionNumber ?
                                        (
                                            <div p={3} style={{ cursor: 'pointer' }} id={page.pageNumber} onClick={(e) => handlePageClick(e)} className={`buttonPagination ${currentPage === page.pageNumber ? "active" : ''}
                                ${page.checkQuiz === true ? "checked" : ''}  ${currentPage === page.pageNumber && page.checkQuiz === true ? "checked-active" : ""}`}>
                                                {page.pageNumber + 1 + quizPartenza}
                                            </div>
                                        )
                                        :
                                        (
                                            <div id={page.pageNumber} p={3} style={{ cursor: 'pointer' }}
                                                className={`buttonPaginationDisabled`}>
                                                {page.pageNumber + 1 + quizPartenza}
                                            </div>
                                        )
                                )}
                            </Fragment>
                        ))}
                    </Flex>
                    <Button w="10%" colorScheme={'primary'} variant="ghost" onPress={handleNextClick} disabled={(currentPage === pages[pages.length - 1].pageNumber) || (lastSectionNumberBool === true)}
                        leftIcon={<FontAwesomeIcon className='delete-icon' size="xl" icon={faArrowRight} />} />
                </Flex>
            )}
        </Box >
    )

}
