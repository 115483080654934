import { api } from "./configs/oldAxiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const ReactionAPI = {
    reaction: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/interazione/reaction`,
            method: "POST",
            data: obj,
            headers: {
                Authorization: "Bearer " + token
            },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(ReactionAPI)