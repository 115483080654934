import { api } from "./configs/axiosConfig"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const SearchAPI = {
    search: async function (obj, token, cancel = false) {
        const response = await api.request({
            url: `/utente/ricerca`,
            method: "POST",
            data: obj,
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })

        return response.data
    },
}
const cancelApiObject = defineCancelApiObject(SearchAPI)