import * as React from 'react';
import { useState, useEffect, Fragment, useRef } from 'react';
import { Flex, Container, Modal, Button, Input, Box, Image, Center, Avatar, HStack, Text, Menu, CloseIcon, Actionsheet, useDisclose, useBreakpointValue, FormControl } from 'native-base';

import Logo from "../../img/logoEspanso.png";
import Logomin from "../../img/logoMin.png";

import ListSearch from '../shared/listSearch';
import MenuMobileComponent from './menuMobile';
import { AuthAPI } from '../../api/authApi';
import { SearchAPI } from '../../api/searchApi';
import { ProfileAPI } from '../../api/profileApi';

import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserLarge, faMessage, faSearch, faBell } from '@fortawesome/free-solid-svg-icons'

export default function MenuComponent(props) {


    const modalRef = useRef(null);
    const modalInputRef = useRef(null);
    const searchInputRef = useRef(null);

    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const [userBadge, setUserBadge] = useState(2);
    const [chatBadge, setChatBadge] = useState(5);
    const [nome, setNome] = useState("");
    const [username, setUsername] = useState("");
    const [cognome, setCognome] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState(`${process.env.REACT_APP_FETCH_URL}static/uploads/placeholder.jpg`);

    const [alertList, setAlertList] = useState("")
    const [listUserSearch, setlistUserSearch] = useState([])

    const [token, setToken] = useState(tokenUser);
    const [refreshToken, setRefreshToken] = useState(refreshTokenUser);

    const [searchOpen, setSearchOpen] = useState(false);
    const [pageName, setPageName] = useState("");
    const [searchValue, setSearchValue] = useState("");

    const [variabileTokenCheck, setVariabileTokenCheck] = useState("");

    const [classifica, setClassifica] = useState([]);
    const [userFollowing, setFollowing] = useState([]);
    const [userFollower, setFollower] = useState([]);

    const displayOnDesktop = useBreakpointValue({
        base: false,
        lg: true
    });

    useEffect(() => {
        if (user != null) {
            setToken(tokenUser);
            setRefreshToken(refreshTokenUser);
            // console.log(user)
            setNome(user.nome);
            setCognome(user.cognome);
            setUsername(user.username);
            setUrlAvatarLocal(user.avatar.url);
        }
    }, [])

    useEffect(() => {
        if (props.ListClassifica != undefined)
        setClassifica(props.ListClassifica)
    }, [props.ListClassifica])

    useEffect(() => {
        if (props.listFollower != undefined)
        setFollower(props.listFollower)
    }, [props.listFollower])

    useEffect(() => {
        if (props.listFollowing != undefined)
        setFollowing(props.listFollowing)
    }, [props.listFollowing])


    useEffect(() => {
        setPageName(props.pageName)
    }, [props.pageName])

    async function tokenCheck(key) {

        let userRefreshToken = {
            refreshToken: refreshTokenUser
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                if (key === "search") search();
                if (key === "getProfile") getProfile();
                if (key === "logout") logout();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }

    async function search() {


        modalInputRef.current.value = searchValue;

        if (searchInputRef.current != null) //Da mobile non c'è questo input
            searchInputRef.current.value = searchValue;

        let data = { name: searchValue }

        SearchAPI.search(data, tokenUser)
            .then((result) => {
                let tmpUsers = [];
                for (let user of result) {
                    let utente = user;
                    let coloursList = ["primary.500", "error.500", "warning.500", "success.500", "yellow.500", "info.500"]
                    let randomIndex = Math.floor(Math.random() * coloursList.length) + 1
                    utente.utente.bgColor = coloursList[randomIndex];
                    tmpUsers.push(utente);
                }
                // localStorage.setItem("users", JSON.stringify(tmpUsers));
                setlistUserSearch(tmpUsers);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                        setAlertList("Utente non trovato")
                        setlistUserSearch([])
                    }
                    if (error.response.status == 403) {
                        tokenCheck("search")
                    }
                }
            })
    }

    async function getProfile(id) {

        if (id != 0) {
            ProfileAPI.getProfile(id, tokenUser)
                .then((result) => {
                    console.log(result)
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404 || error.response.status == 500) {
                        }
                        if (error.response.status == 403) {
                            tokenCheck("getProfile")
                        }
                    }
                })
        }
    }

    async function logout() {

        AuthAPI.logout(tokenUser)
            .then((result) => {
                localStorage.clear();
                navigateToLogin();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                        // setMessageDisplay(true);
                        // setErrorMessageLogin(error.response.data.description);
                    }
                    if (error.response.status == 403) {
                        tokenCheck("logout")
                    }
                }
            })
    }




    function changeInput(e) {
        modalInputRef.current.value = e;
        if (searchInputRef.current != null) //Da mobile non c'è questo input
            searchInputRef.current.value = e;
        // comincia la ricerca dopo il 3° carattere
        if (e.length > 2) {
            setAlertList("")
            setSearchValue(e)
        } else if (e.length <= 2) {
            setAlertList("Sono necessari almeno 3 caratteri affinchè la ricerca risulti efficace.")
            setSearchValue("")
        }
    }


    function navigateToLogin() {
        navigate("/login")
    }

    function navigateToProfile() {
        navigate("/profile?id=" + user.id, {
            replace: true,
            state: {
                id: user.id
            }
        });

        //Imposto caricamento
        window.scrollTo({
            top: 0,
        });
        //getProfileByID(id);
        //Quando il getProfileByID ha restituito qualcosa allora il caricamento finisce
    }

    function navigateToHome() {
        navigate("/home");
        window.scrollTo({
            top: 0,
        });
    }

    function navigateToProfileEdit() {
        navigate("/profile/edit")
    }

    function openSearchModal() {
        setSearchOpen(true);
        setTimeout(() => {
            modalRef.current?.scrollIntoView()
            document.body.style.overflow = "hidden"; //Quando apro la modale non voglio poter scrollare quello che c'ho sotto
        }, 100)

        if (searchValue == "") {

            setAlertList(t("menu.minCharSearch"))
            setlistUserSearch([]);
        }
        else {
            setAlertList("");
            search();
        }
    }

    function closeSearchModal() {
        document.body.style.overflow = "auto";
        setSearchOpen(false);
    }

    function getIdUser(id) {
        getProfile(id)
    }

    const {
        isOpen,
        onOpen,
        onClose
    } = useDisclose();

    function goToProfile(id) {
        closeSearchModal()
        navigate("/profile?id=" + id, {
            replace: true,
            state: {
                id: id
            }
        });
        window.scrollTo({
            top: 0,
        });
        onClose()
    }

    function navigateToHome() {
        navigate("/home")
        window.scrollTo({
            top: 0,
        });
    }
    function navigateToChat() {
        navigate("/chat")
        window.scrollTo({
            top: 0,
        });
    }
    function navigateToQuestionari() {
        navigate("/questionari")
        window.scrollTo({
            top: 0,
        });
    }


    return (
        <Fragment>
            {/* <HStack backgroundColor="white" h="56px" space={0}> */}
            <Box borderBottomWidth="2px" borderColor="coolGray.300"  w="full" bgColor="white" position={pageName != "chat" ? "fixed" :"block"} 
            zIndex={10} paddingTop={2} paddingBottom={2} >

                <Container w="full" alignSelf={"center"}>
                    <Flex w="full" direction='row' justify="space-between">

                        {/*Logo*/}
                        <Center cursor={pageName != "home" ? "pointer" : ""}>
                            <Image onClick={navigateToHome} source={{
                                uri: displayOnDesktop ? Logo : Logomin
                            }} alt="Logo" w={{ base: "60px", lg: "200px" }} h="60px" />
                        </Center>
                        {/*Barra di ricerca */}
                        <Center>
                            {displayOnDesktop && (
                                <Flex direction='row'>
                                    <Input rounded="lg" size="lg" ref={searchInputRef} onSubmitEditing={openSearchModal} InputRightElement={<Button colorScheme={"primary"} rounded="lg"
                                        borderRadius={"none lg lg none"} h="100%" onPress={openSearchModal}><Text color='white'><FontAwesomeIcon className='password-icon' icon={faSearch} /></Text></Button>} onChangeText={changeInput} />
                                </Flex>
                            )}
                        </Center>
                        {/*Bottoni + avatar */}
                        <HStack space={0} justifyContent="flex-end" >
                            <Center>
                                <Flex w="full" direction='row' justify="space-between">
                                    <Button variant="unstyled" mr={2} leftIcon={
                                    <div className='badge-container'><FontAwesomeIcon className='delete-icon' icon={faBell} size="xl" colors='primary.500' />
                                    {/* {(userBadge != 0 && userBadge <= 100) && (<div className="badge">{userBadge}</div>)}
                                    {userBadge > 100 && (<div className="badge">+100</div>)} */}
                                    </div>} 
                                    />
                                    <Button variant="unstyled" mr={2} onPress={navigateToChat} leftIcon={
                                    <div className='badge-container'><FontAwesomeIcon className='delete-icon' icon={faMessage} size="xl" colors='primary.500' />
                                    {/* {(chatBadge != 0 && chatBadge <= 100) && (<div className="badge">{chatBadge}</div>)}
                                    {chatBadge > 100 && (<div className="badge">+100</div>)} */}
                                    </div>
                                    } 
                                    />

                                    {displayOnDesktop && (
                                        <Menu placement='bottom right' trigger={triggerProps => {
                                            return <Button variant="unstyled" {...triggerProps} leftIcon={
                                                urlAvatarLocal != `${process.env.REACT_APP_FETCH_URL}static/uploads/placeholder.jpg` ? (
                                                    <Image alt="avatar" size="sm" borderRadius={100} bg="violet.600" source={{ uri: urlAvatarLocal }} />
                                                ) : (
                                                    <Avatar bg="violet.600" alignSelf="center" size="md">
                                                        {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                                    </Avatar>
                                                )}>
                                            </Button>;
                                        }}>
                                            {pageName != "home" && (
                                                <Menu.Item onPress={navigateToHome}>{t("menu.home")}</Menu.Item>
                                            )}
                                            {/* {pageName != "profile" && ( //TODO: + aggiungi controllo se è il mio profilo
                                                <Menu.Item onPress={navigateToProfile}>{t("menu.myProfile")}</Menu.Item>
                                            )} */}
                                            <Menu.Item onPress={navigateToProfile}>{t("menu.myProfile")}</Menu.Item>
                                            {pageName != "questionari" && (
                                                <Menu.Item onPress={navigateToQuestionari}>Questionari</Menu.Item>
                                            )}
                                            <Menu.Item onPress={navigateToProfileEdit}>{t("menu.editProfile")}</Menu.Item>
                                            <Menu.Item onPress={logout}>Logout</Menu.Item>
                                        </Menu >
                                    )}
                                </Flex>
                            </Center>
                        </HStack>
                    </Flex>
                </Container>

                <Modal isOpen={searchOpen} onClose={closeSearchModal} size="xl">
                    <Center h="100vh" w="full" ref={modalRef}>
                        <Modal.Content id="modal"  >
                            <Modal.CloseButton />
                            <Modal.Header>{t("menu.searchTitle")}</Modal.Header>
                            <Modal.Body>
                                <Input rounded="lg" size="lg" mb={2} ref={modalInputRef}
                                    InputRightElement={
                                        <Button colorScheme={"primary"} rounded="lg" borderRadius={"none lg lg none"} h="100%" onPress={openSearchModal}>
                                            <Text color='white'><FontAwesomeIcon className='password-icon' icon={faSearch} /></Text>
                                        </Button>}
                                    onChangeText={changeInput} />
                                <Text color={"error.500"} mt={2} textAlign={"center"} fontSize={"sm"}>{alertList}</Text>
                                <ListSearch list={listUserSearch} alert={alertList} getIdUser={getIdUser} goToUser={goToProfile} />
                            </Modal.Body>
                        </Modal.Content>
                    </Center>
                </Modal>


            </Box>

            {(!displayOnDesktop && pageName != "edit" && pageName != "chat") && (
                <MenuMobileComponent goToProfile={goToProfile} listFollowers={userFollower} listFollowing={userFollowing} listClassifica = {classifica}
                 navigateToQuestionari={navigateToQuestionari} navigateToHome={navigateToHome} openSearch={openSearchModal} pageName={pageName} />
            )}


        </Fragment>
    );
}
