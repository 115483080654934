import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { Box, FormControl, HStack, Input, Stack, Select } from 'native-base';
import { VStack } from 'native-base';
import { Flex, Spacer } from "native-base";
import { Center } from 'native-base';
import { Heading } from 'native-base';
import { Button } from "native-base";
import { Link } from 'native-base';
import { Text } from 'native-base';
import { Avatar } from 'native-base';
import { Divider } from 'native-base';
import { Popover } from 'native-base';

import MenuComponent from '../menu/menu';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faTrashCan, faInfoCircle } from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import { Helmet } from "react-helmet";
//API
import { AuthAPI } from '../../api/authApi';
import { FileAPI } from '../../api/fileApi';
import { ProfileAPI } from '../../api/profileApi';

export default function ProfileEdit() {

    const user = JSON.parse(localStorage.getItem("user"));
    const tokenUser = JSON.parse(localStorage.getItem("token"));
    const refreshTokenUser = JSON.parse(localStorage.getItem("refreshToken"));

    const navigate = useNavigate();
    const regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regExPassword = /^[A-Za-zÀ-ȕ0-9-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]{8,}$/;
    const regExUsername = /^[a-zA-Z0-9]{0,20}$/;

    const { t, i18n } = useTranslation();

    const [showPws, setShowPws] = useState(false);

    const [valid, setValid] = useState(true);
    const [validPws, setValidPws] = useState(true);

    const [email, setEmail] = useState("");
    const [pws, setPws] = useState("");
    const [pwsConferma, setPwsConferma] = useState("");
    const [pwsOld, setPwsOld] = useState("");
    const [nome, setNome] = useState("");
    // const [username, setUsername] = useState("");
    const [cognome, setCognome] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [idAvatar, setIdAvatar] = useState();
    const [urlAvatar, setUrlAvatar] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState("https://sandbox4.itlogix.it/api/static/uploads/file_1676991967706bjf453dsr77.png");
    const [isLoading, setIsLoading] = useState(false);
    const [arrayFacolta, setArrayFacolta] = useState([]);
    const [facoltaId, setFacoltaId] = useState(0);
    const [level, setLevel] = useState(0);

    const [token, setToken] = useState(tokenUser);
    const [refreshToken, setRefreshToken] = useState(refreshTokenUser);

    const [avatarFile, setAvatarFile] = useState(undefined)
    const [valueAvatar, setValueAvatar] = useState(null)

    const [isHeavy, setIsHeavy] = useState(false);

    const [errorMessageReg, setErrorMessageReg] = useState("");
    const [messageDisplay, setMessageDisplay] = useState(false);

    useEffect(() => {



        setUrlAvatarLocal(user.avatar.url)
        getListFacolta()

        setToken(tokenUser);
        setRefreshToken(refreshTokenUser);
        setNome(user.nome);
        setCognome(user.cognome);
        user.facolta == null ? setFacoltaId(0) : setFacoltaId(user.facolta); // TODO: da verificare
        // setUsername(user.username);
        setEmail(user.email);
        setDataDiNascita(user.datanascita.slice(0, 10));
        // setUrlAvatarLocal(user.avatar.url)

        //TODO: da tenere sotto controllo -> il backend restituisce avatarUrl = "https://sandbox4.itlogix.it/api/static/uploads/null"
        // user.avatar.url.slice(-5) === "/null" ? setUrlAvatarLocal(null) : setUrlAvatarLocal(user.avatar.url)
    }, [])



    // TODO: temporaneo - test caricamento
    useEffect(() => {
        if (isLoading) setTimeout(() => setIsLoading(false), 2000)
    }, [isLoading])

    async function tokenCheck() {

        let userRefreshToken = {
            refreshToken: refreshToken
        }

        AuthAPI.refreshToken(userRefreshToken)
            .then((result) => {
                localStorage.setItem("token", JSON.stringify(result.token));
                localStorage.setItem("refreshToken", JSON.stringify(result.refreshToken));
                modificaProfilo(idAvatar)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 403) {
                        navigateToLogin();
                    }
                }
            })
    }

    async function modificaProfilo(idAvatarUser) {

        let userData = {
            newNome: nome,
            newCognome: cognome,
            newData: dataDiNascita,
            newEmail: email,
            newFacolta: parseInt(facoltaId)
        }
        if (pwsOld != "") userData.oldPassword = pwsOld;
        if (pws != "") userData.newPassword = pws;
        idAvatarUser != 0 ? userData.newAvatar = idAvatarUser : userData.newAvatar = 0;
        setIdAvatar(idAvatarUser != 0 ? userData.newAvatar = idAvatarUser : userData.newAvatar = 0);

        ProfileAPI.editProfile(userData, tokenUser)
            .then((result) => {
                localStorage.setItem("user", JSON.stringify(result.user));
                navigateToProfile();
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 400 || error.response.status == 500 || error.response.status == 404) {
                        setMessageDisplay(true);
                        setErrorMessageReg(error.response.data.description);
                    }
                    if (error.response.status == 403) {
                        tokenCheck()
                    }
                    if (error.response.status == 401) {
                        navigateToLogin();
                    }
                }
            })

    }

    async function uploadAvatar(avatarFile) {
        let formData = new FormData();

        formData.append("file", avatarFile);

        FileAPI.upload(formData)
            .then((result) => {
                setIdAvatar(result.id.toString());
                modificaProfilo(result.id.toString());
                setUrlAvatar(result.url);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 500) {
                        setMessageDisplay(true);
                        setErrorMessageReg(error.response.data.description);
                    }
                }
            })
    }

    async function getListFacolta() {
        ProfileAPI.facolta()
            .then((result) => {
                setArrayFacolta(result)
            })
            .catch((error) => {
            })
    }

    function editProfile() {
        validate()
        if (valid && validPws) {
            (urlAvatarLocal != "" && urlAvatarLocal != user.avatar.url) ? uploadAvatar(avatarFile) : modificaProfilo(null)

        };
    }

    function onFileChange(e) {
        if (e.target.files[0].size > 1000000) {
            setIsHeavy(true);
        }
        else {
            setIsHeavy(false);
            setValueAvatar(e.target.value);
            setAvatarFile(e.target.files[0]);
            setUrlAvatarLocal(URL.createObjectURL(e.target.files[0]));
        }
    }

    function handleClick() { setShowPws(!showPws) };

    function validate() {
        if (email != "" || regExEmail.test(email) === true || nome != "" || cognome != "" || 
            //username != "" || regExUsername.test(username) === false ||
            dataDiNascita != "") {
            if (pws != "" || pwsConferma != "" || pwsOld != "") {
                if (pwsConferma != pws) {
                    setValid(false);
                    setValidPws(false);
                }
                else {
                    setValid(true);
                    setValidPws(true);
                }
            } else {
                setValid(true);
                setValidPws(false);
            }
        } else {
            setValid(false);
            setValidPws(false);
        }
    };
    function emailSet(value) {
        setEmail(value)
    }
    function pwsSet(value) {
        setPws(value)
    }
    function pwsOldSet(value) {
        setPwsOld(value)
    }
    function pwsConfermaSet(value) {
        setPwsConferma(value)
    }
    function nomeSet(value) {
        setNome(value)
    }
    function cognomeSet(value) {
        setCognome(value)
    }
    function dataDiNascitaSet(e) {
        setDataDiNascita(e.target.value)
    }
    function navigateToProfile() {
        navigate("/profile")
    }
    function navigateToLogin() {
        navigate("/login")
    }

    function resetAvatarSet() {
        setUrlAvatarLocal("");
        setAvatarFile(undefined);
        setValueAvatar(null);
    }

    return (

        <Fragment>
            <MenuComponent pageName="edit" />
            <Box height={{ base: "", sm: "100vh" }} m={{ base: "0", sm: "8" }} mt={"5em"}>
                {/* <Center height={{ base:"", sm:"100vh"}}> */}
                <Center w={{ base: "100vw" }} maxW="500px" alignSelf={"center"} rounded={{ base: "none", sm: "lg" }} overflow="hidden" borderColor="coolGray.200" padding={"2em"} borderWidth="1" _web={{
                    shadow: 2,
                    borderWidth: 0
                }} _light={{
                    backgroundColor: "white"
                }}>
                    <Stack space={4} w="100%" maxW="400px" mx="auto">
                        <Center>
                            <HStack space={2}>
                                <Center>
                                    {urlAvatarLocal == "" &&
                                        (<Avatar bg="violet.600" alignSelf="center" size="2xl" source={{
                                            uri: urlAvatarLocal
                                        }}>
                                            {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                        </Avatar>)}
                                    {urlAvatarLocal != "" &&
                                        (<Avatar bg="violet.600" alignSelf="center" size="2xl" source={{
                                            uri: urlAvatarLocal
                                        }}>
                                            {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                        </Avatar>)}
                                </Center>
                                <Center>
                                    <VStack space={1}>
                                        {/* <Button colorScheme="primary" rounded="lg" onPress={avatartDisplaySet} size="xs" >
                                            Cambia foto
                                        </Button> */}
                                        <input type="file" accept="image/png, image/jpg, image/jpeg, image/PNG, image/JPG, image/JPEG" name="file" id="file" className="inputfile" onChange={onFileChange} />
                                        <label htmlFor="file">
                                            <Button rounded="lg" size="xs" textAlign="right">Cambia foto</Button>
                                        </label>
                                        <Button colorScheme="danger" rounded="lg" onPress={resetAvatarSet} size="xs" leftIcon={<FontAwesomeIcon className='delete-icon' icon={faTrashCan} size="xs" color='#F5F5FA' />}>
                                            Rimuovi Foto
                                        </Button>
                                    </VStack>
                                </Center>
                            </HStack>
                            {/* <Text fontSize="3xl"> Benvenuto {nome} </Text> */}
                        </Center>
                        <VStack space={2}>
                            <Flex direction="row" flexWrap="wrap">
                                <FormControl isRequired isInvalid={valid != true} w="49%">
                                    <FormControl.Label>{t("pages.signup.name")}</FormControl.Label>
                                    <Input testid='input-nome' w="100%" rounded="none" value={nome} onSubmitEditing={editProfile} size="lg" onChangeText={value => nomeSet(value)} />
                                    {(valid === false && nome === "") && (<FormControl.ErrorMessage>{t("errors.nome.empty")}</FormControl.ErrorMessage>)}
                                </FormControl>
                                <Spacer w="2%" />
                                <FormControl isRequired isInvalid={valid != true} w="49%">
                                    <FormControl.Label>{t("pages.signup.surname")}</FormControl.Label>
                                    <Input testid='input-cognome' onSubmitEditing={editProfile} w="100%" value={cognome} rounded="none" size="lg" onChangeText={value => cognomeSet(value)} />
                                    {(valid === false && cognome === "") && (<FormControl.ErrorMessage>{t("errors.cognome.empty")}</FormControl.ErrorMessage>)}
                                </FormControl>
                            </Flex>
                            {/* <FormControl isRequired isInvalid={valid != true}>
                                <FormControl.Label>Username</FormControl.Label>
                                <Input w="100%" rounded="none" value={username} size="lg" onChangeText={value => usernameSet(value)} maxLength={20} />
                                {(valid === false && username === "") && (<FormControl.ErrorMessage>{t("errors.username.empty")}</FormControl.ErrorMessage>)}
                                {(regExUsername.test(username) === false && username !== "") && (<FormControl.ErrorMessage>{t("errors.username.invalid")}</FormControl.ErrorMessage>)}
                            </FormControl> */}
                            <FormControl isRequired isInvalid={valid != true}>
                                <FormControl.Label>Email</FormControl.Label>
                                <Input testid='input-email' onSubmitEditing={editProfile} w="100%" rounded="none" value={email} size="lg" onChangeText={value => emailSet(value)} />
                                {(valid === false && email === "") && (<FormControl.ErrorMessage>{t("errors.email.empty")}</FormControl.ErrorMessage>)}
                                {(regExEmail.test(email) === false && email !== "") && (<FormControl.ErrorMessage>{t("errors.email.invalid")}</FormControl.ErrorMessage>)}
                            </FormControl>
                            <FormControl isRequired isInvalid={valid != true}>
                                <FormControl.Label>{t("pages.signup.bday")}</FormControl.Label>
                                <input testid='input-data' value={dataDiNascita} type="date" onChange={dataDiNascitaSet} />
                                {(valid === false && dataDiNascita === "") && (<FormControl.ErrorMessage>{t("errors.dataDiNascita.empty")}</FormControl.ErrorMessage>)}
                            </FormControl>
                            <FormControl isInvalid={valid != true}>
                                <FormControl.Label>Facoltà</FormControl.Label>
                                <Select rounded={"none"} size="lg"
                                    _selectedItem={{
                                        bg: "teal.600",
                                    }}
                                    mt="1" defaultValue={String(user.facolta.id)} value={String(facoltaId)} onValueChange={(value) => setFacoltaId(value)}>
                                    {arrayFacolta.map((item) =>
                                        <Select.Item key={item.id} label={item.nome} value={String(item.id)} />
                                    )}
                                </Select>
                            </FormControl>
                            <FormControl isRequired={pwsConferma === "" || pws === "" ? false : true} isInvalid={valid != true}>
                                <FormControl.Label>{t("pages.signup.oldpassword")}</FormControl.Label>
                                <Input testid='input-old-password' onSubmitEditing={editProfile} w="100%" rounded="none" value={pwsOld} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                    <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                } onChangeText={value => pwsOldSet(value)} />
                                {(valid === false && pwsConferma != "" && pws != "" && pwsOld === "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                {(regExPassword.test(pwsOld) === false && pwsOld !== "") && (<FormControl.ErrorMessage>{t("errors.pws.invalid")}</FormControl.ErrorMessage>)}
                            </FormControl>
                            {/* <Text textAlign="center">
                                La nuova password deve essere diversa dalla precedente
                            </Text> */}
                            <FormControl isRequired={pwsConferma === "" || pwsOld === "" ? false : true} isInvalid={valid != true}>
                                <FormControl.Label>
                                    <Popover trigger={triggerProps => {
                                        return <Button w="0" h="0" style={{ paddingLeft: "0.4em" }} {...triggerProps} variant="unstyled" leftIcon={<div className='badge-container'><FontAwesomeIcon color='red' className='delete-icon' icon={faInfoCircle} size="xs" colors='primary.500' /></div>} />
                                    }}>
                                        <Popover.Content accessibilityLabel="Search" w="56">
                                            <Popover.Arrow />
                                            <Popover.CloseButton />
                                            <Popover.Header fontSize="xs">
                                                Info
                                            </Popover.Header>
                                            <Popover.Body fontSize="xs">
                                                <div>La nuova password deve essere diversa dalla precedente e contenere almeno:</div>
                                                <ul>
                                                    <li>8 caratteri;</li>
                                                    <li>1 carattere maiuscolo;</li>
                                                    <li>1 carattere numerico;</li>
                                                    <li>1 carattere non alfanumerico.</li>
                                                </ul>
                                            </Popover.Body>
                                        </Popover.Content>
                                    </Popover>
                                    {t("pages.signup.newpassword")}</FormControl.Label>
                                <Input testid='input-new-password' onSubmitEditing={editProfile} w="100%" rounded="none" value={pws} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                    <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                } onChangeText={value => pwsSet(value)} />
                                {(valid === false && pwsConferma != "" && pws === "" && pwsOld != "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                {(regExPassword.test(pws) === false && pws !== "") && (<FormControl.ErrorMessage>{t("errors.pws.invalid")}</FormControl.ErrorMessage>)}
                            </FormControl>
                            <FormControl isRequired={pws === "" || pwsOld === "" ? false : true} isInvalid={valid != true}>
                                <FormControl.Label>{t("pages.signup.confirmpassword")}</FormControl.Label>
                                <Input testid='input-confirm-password' onSubmitEditing={editProfile} w="100%" rounded="none" value={pwsConferma} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                    <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                } onChangeText={value => pwsConfermaSet(value)} />
                                {(valid === false && pwsConferma === "" && pws != "" && pwsOld != "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                {(pwsConferma !== pws && pwsConferma !== "") && (<FormControl.ErrorMessage>{t("errors.pws.unequal")}</FormControl.ErrorMessage>)}
                            </FormControl>
                            {messageDisplay === true && (<Text fontSize="xs" color="red.600" mt="4">{errorMessageReg}</Text>)}
                        </VStack>
                        <VStack space={2}>
                            <Button colorScheme="primary" rounded="lg" onPress={editProfile}>
                                {t("pages.login.editProfile")}
                            </Button>
                            <Button variant="outline" colorScheme="primary" rounded="lg" onPress={navigateToProfile}>
                                Torna al profilo
                            </Button>
                        </VStack>
                    </Stack>
                    {/* </Center> */}
                </Center>
            </Box>
            <Helmet>
                <title>{t("name")} - Modifica profilo</title>
            </Helmet>
        </Fragment>
    )
}