import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import { Text, Center, Button, Spinner, Flex, Box, Container, HStack } from "native-base";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faPlay } from '@fortawesome/free-solid-svg-icons'
import { useNavigate, useLocation } from 'react-router-dom';

import Sezione from './sezione';

import { useTranslation } from 'react-i18next';
import { QuestionariAPI } from '../../api/questionariApi';

export default function DashboardQuestionario() {

    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const tokenUser = JSON.parse(localStorage.getItem("token"));

    const [idQuestionario, setIdQuestionario] = useState(-1);
    const [questionario, setQuestionario] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);


    const [sezioni, setSezioni] = useState([])

    const [nQuiz, setNQuiz] = useState(0)

    const [simulazioneView, setSimulazioneView] = useState(true)
    const [esercitazioneView, setEsercitazioneView] = useState(false)
    const [sequenzialeView, setSequenzialeView] = useState(false)
    const [tipoTest, setTipoTest] = useState("Sim");



    useEffect(() => {
        let idTmp = -1;
        if (location.state != null) //Se provengo dall'elenco questionari allora ho il location state
            idTmp = location.state.idQuestionario;
        else { //Se invece provengo dalla cronologia o qualcuno mi ha dato il link lo prendo dai params
            const params = new Proxy(new URLSearchParams(window.location.search), {
                get: (searchParams, prop) => searchParams.get(prop),
            });
            idTmp = params.id;
        }
        //TODO: Se non c'è nessuno dei due mandare a schermo un errore

        setIdQuestionario(idTmp);
        getQuestionarioByID(idTmp);
    }, [])

    function simulazioneViewSet() {
        totQuizStart(sezioni)
        setTipoTest('Sim');
        setSimulazioneView(true);
        setEsercitazioneView(false);
        setSequenzialeView(false);
    }
    function setEsercitazioneViewSet() {
        setTipoTest('Ese');

        setSimulazioneView(false);
        setEsercitazioneView(true);
        setSequenzialeView(false);
    }
    // function setSequenzialeViewSet() {
    //     setSimulazioneView(false);
    //     setEsercitazioneView(false);
    //     setSequenzialeView(true);
    // }
    function totQuizStart(sezioniTmp) {
        let totQuiz = 0;
        if (sezioniTmp.length > 0) {
            for (let sezione of sezioniTmp) {
                totQuiz += sezione.numQuiz;
            }
        }
        setNQuiz(totQuiz)
    }
    function changeNumeroQuiz(nuovoNumeroQuiz, index) {
        let quizTotali = 0;
        let sezioniTmp = sezioni;
        sezioniTmp[index].quizSelezionati = nuovoNumeroQuiz;
        for (let sezione of sezioniTmp) {
            if (sezione.quizSelezionati == undefined)
                quizTotali += sezione.numQuiz
            else {
                quizTotali += sezione.quizSelezionati;
            }
        }
        setSezioni(sezioniTmp);
        setNQuiz(quizTotali);
    }

    async function getQuestionarioByID(id) {
        QuestionariAPI.getQuestionarioByID(id, tokenUser)
            .then((result) => {
                    setQuestionario(result);
                    let sezioniTmp = result.sezione;
                    let totQuiz = 0;
                    for (let i = 0; i < sezioniTmp.length; i++) {
                        totQuiz += sezioniTmp[i].numQuiz;
                        sezioniTmp[i].quizSelezionati = sezioniTmp[i].numQuiz
                    }
                    setSezioni([...sezioniTmp]);
                    setNQuiz(totQuiz);

                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 404 || error.response.status == 500) {
                    }
                    if (error.response.status == 403) {
                        //tokenCheck("getProfile")
                    }
                }
            })
    }

    function goBack() {
        navigate("/questionari")
    }

    function startQuiz() {
        if (questionario?.gratuito || (tipoTest !== "Sim" && nQuiz > 0) || tipoTest === "Sim") {
            let prettyLink;
            let state = {
                idQuestionario: idQuestionario,
                idVolume: questionario.volumeAssociato.idVolume,
                idUtente: 99, //TODO: Mettere l'id vero
                type: tipoTest,
                parti: sezioni,
                gratuito: questionario.gratuito,
                descrizione: questionario.descrizionePubblica,
                prettyLink: questionario.permalink != null ? questionario.permalink : ''
            }

            if (!questionario.gratuito) {
                // state.quizSequenzialiSelezionati = nQuiz;
                state.questionario = questionario;
                state.numeroQuiz = nQuiz;
            }

            if (questionario.permalink != null) {
                prettyLink = questionario.permalink
                prettyLink = prettyLink.replace(/\s+/g, '-')

            } else {
                prettyLink = questionario.nomeQuestionario.toLowerCase();
                prettyLink = prettyLink.replace(/\s+/g, '-')
            }
            navigate("/quiz/" + prettyLink, { state: state })
        }
    }



    return (
        <Fragment>
            <Center minH="100vh"> {/*Centrato orizzontalmente */}
                {!isLoading ? (
                    <Container w="full" minH="100vh">
                        <Center w="full" minH="100vh"> {/*Centrato verticalmente dentro il container */}
                            <Text textAlign={"center"} fontSize={"4xl"} bold m={8}>
                                {questionario?.nomeQuestionario}
                            </Text>
                            <Box w="full" rounded={"lg"} padding={2} shadow={2} borderColor="coolGray.100" borderWidth="1">
                                <Center>
                                    <Flex direction={{ base: 'column', lg: 'row' }}>
                                        <Button onPress={simulazioneViewSet} colorScheme={"primary"} m="2" variant={simulazioneView ? "solid" : "text"} padding={3} rounded={"lg"} >
                                            <Text bold color={simulazioneView ? "white" : "primary"}>
                                                {t("pages.quiz.simulation")}
                                            </Text>
                                        </Button>
                                        <Button onPress={setEsercitazioneViewSet} colorScheme={"primary"} m="2" variant={esercitazioneView ? "solid" : "text"} padding={3} rounded={"lg"}>
                                            <Text bold color={esercitazioneView ? "white" : "primary"}>
                                                {t("pages.quiz.exercise")}
                                            </Text>
                                        </Button>
                                        {/* <Button onPress={setSequenzialeViewSet} colorScheme={"primary"} m="2" variant={sequenzialeView ? "solid" : "text"} padding={3} rounded={"lg"}>
                                        <Text bold color={sequenzialeView ? "white" : "primary"}>
                                            {t("pages.quiz.sequential")}
                                        </Text>
                                    </Button> */}
                                        <Center m="2">
                                            <Text>{t("pages.quiz.totalQuantity")}: <b>{nQuiz}</b></Text>
                                        </Center>
                                    </Flex>
                                </Center>
                            </Box>
                            {simulazioneView && (
                                <Text w="full" mt={4} bold fontSize={"xl"} textAlign={"start"}>
                                    {t("pages.quiz.simulationSubtitle")}
                                </Text>
                            )}
                            {esercitazioneView && (
                                <Fragment>
                                    <Text w="full" mt={4} bold fontSize={"xl"}>{t("pages.quiz.exerciseSubtitle1")}</Text>
                                    <Text w="full">{t("pages.quiz.exerciseSubtitle2")}</Text>
                                </Fragment>
                            )}
                            {sequenzialeView && (
                                <Fragment>
                                    <Text w="full" mt={4} bold fontSize={"xl"}>{t("pages.quiz.sequentialSubtitle1")}</Text>
                                    <Text w="full">{t("pages.quiz.sequentialSubtitle2")}</Text>
                                </Fragment>
                            )}
                            <Flex w="full" direction={{ base: 'column', md: 'row' }} flexWrap={"wrap"} my={4}>
                                {sezioni.map((sezione, index) =>
                                    <Sezione key={sezione.idParte} index={index} sezione={sezione}
                                        simulazioneView={simulazioneView} esercitazioneView={esercitazioneView} sequenzialeView={sequenzialeView}
                                        changeNumeroQuiz={changeNumeroQuiz}
                                    />
                                )}
                            </Flex>
                            <Box my="4" mx={{ base: 0, md: 4 }} w={{ base: "full", md: "50%", lg: "full" }}>
                                <Flex w="full" direction={{ base: 'column', lg: 'row' }} justify={{ base: "space-between" }} flexWrap={"wrap"}>
                                    <Button w={{ base: 'full', md: 'full', lg: '30%' }} colorScheme={"violet"} mt="2" variant="solid" padding={3} rounded={"lg"} onPress={goBack}
                                        leftIcon={<FontAwesomeIcon icon={faArrowLeft} size="sm" color='white' />}>
                                        <Text color='white'>{t("pages.quiz.btnBack")}</Text>
                                    </Button>
                                    <Button isDisabled={nQuiz <= 0} w={{ base: 'full', md: 'full', lg: '30%' }} colorScheme={"primary"} ml={{ base: 0, md: 0, lg: 2 }} mt="2" variant="solid" padding={3} onPress={startQuiz}
                                        rounded={"lg"} leftIcon={<FontAwesomeIcon icon={faPlay} size="sm" color='white' />}>
                                        {t("pages.quiz.btnStart")}
                                    </Button>
                                </Flex>
                            </Box>
                        </Center>
                    </Container>
                ) : (
                    <HStack space={8} justifyContent="center" alignItems="center">
                        <Flex>
                            <Spinner size="lg" />
                            <Text color={'primary.700'} mt={4}>Caricamento questionario...</Text>
                        </Flex>
                    </HStack>
                )}
            </Center>
        </Fragment>
    );
}