import * as React from 'react';
import { useState, useEffect, Fragment, useCallback } from 'react';
import { Box, Column, FormControl, HStack, Input, Stack } from 'native-base';
import { VStack } from 'native-base';
import { Flex, Spacer } from "native-base";
import { Center } from 'native-base';
import { Heading } from 'native-base';
import { Button } from "native-base";
import { Link } from 'native-base';
import { Text } from 'native-base';
import { Avatar } from 'native-base';
import { Divider } from 'native-base';
import { Progress } from 'native-base';
import { useBreakpointValue } from 'native-base';

import Post from './post';

import { Virtuoso } from "react-virtuoso";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faTrashCan } from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

import { Helmet } from "react-helmet";
//API
import { AuthAPI } from '../../api/authApi';
import { FileAPI } from '../../api/fileApi';

export default function PostList(props) {

    const user = JSON.parse(localStorage.getItem("user"));

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [pws, setPws] = useState("");
    const [pwsConferma, setPwsConferma] = useState("");
    const [pwsOld, setPwsOld] = useState("");
    const [nome, setNome] = useState("");
    const [username, setUsername] = useState("");
    const [cognome, setCognome] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [idAvatar, setIdAvatar] = useState();
    const [urlAvatar, setUrlAvatar] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [livello, setLivello] = useState(10);
    const [progresso, setProgresso] = useState(35);

    const [dataPost, setDataPost] = useState([]);
    const [dataTraguardi, setDataTraguardi] = useState();
    const [dataAmici, setDataAmici] = useState();
    const [dataClassifica, setDataClassifica] = useState();

    const [postSet, setPostSet] = useState(() => []);

    useEffect(() => {
        const timeout = loadMore()


        setNome(user.nome);
        setCognome(user.cognome);
        setUsername(user.username);
        setEmail(user.email);
        setDataDiNascita(user.datanascita.slice(0, 10));
        setUrlAvatarLocal(user.avatar);

        return () => clearTimeout(timeout)

    }, [])


    // useEffect(() => {
    // if(props.posts != undefined){
    //     setDataPost(props.posts);
    //     setPostSet(props.posts.slice(0, 2))
    // }
    // }, [props.posts])

    useEffect(() => {
        if (props.userPost != undefined) {
            setDataPost(props.userPost);
            setPostSet(props.userPost.slice(0, 2))
        }
    }, [props.userPost])

    const loadMore = useCallback(() => {
        if (props.addTimelinePost != undefined) props.addTimelinePost()
        return setTimeout(() => {
            if (dataPost != undefined) {
                let newPost = dataPost.slice(postSet.length + 1, postSet.length + 2)
                setDataPost((oldSet) => [...oldSet, ...newPost])
                setPostSet((oldSet) => [...oldSet, ...newPost])
            }
        }, 200)
    }, [setPostSet])

    return (
        <Box w={{ base: "100%", lg: "65%" }} mt={{ base: 4, md: 0 }}>
            {(dataPost != undefined > 0) ? (
                <Virtuoso
                    style={{ height: "100vh", width: "100%", margin: "0" }}
                    data={dataPost}
                    endReached={loadMore}
                    overscan={2}
                    itemContent={(index, post) =>
                        <Post key={index} postData={post} />
                    }
                    components={<div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>Caricamento...</div>}
                />) : (
                <Center h="50vh">
                    <Text bold fontSize="2xl">Nessun post trovato.</Text>
                </Center>
            )}
        </Box>
    );
}