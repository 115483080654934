import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
// import { Virtuoso } from "react-virtuoso";

//native-base
import { Box, FormControl, Image, Input, Stack, Icon } from 'native-base';
import { VStack, HStack } from 'native-base';
// import { Checkbox } from 'native-base';
import { Center } from 'native-base';
import { Heading } from 'native-base';
import { Button } from "native-base";
import { Link } from 'native-base';
import { Spinner } from 'native-base';
import Logo from "../../img/logoEspanso.png";
import { AlertDialog } from "native-base";
import { Text } from 'native-base';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey, faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

//react-router-dom
import { useNavigate } from "react-router-dom";

//react-i18next
import { useTranslation } from 'react-i18next';

import { Helmet } from "react-helmet";
//API
import { AuthAPI } from '../../api/authApi';


export default function PwsRecover() {

    const navigate = useNavigate();
    const regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    const { t, i18n } = useTranslation();

    const [email, setEmail] = useState("");
    const [valid, setValid] = useState();
    const [level, setLevel] = useState(0);

    const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    // TODO: temporaneo - test caricamento
    // useEffect(() => {
    //     if (isLoading) setTimeout(() => setIsLoading(false), 2000)
    // }, [isLoading])

    async function confirmSendEmail() {
        if (email === "" || regExEmail.test(email) === false) {
            setValid(false)
        } else {
            let userData = {
                email: email,
            }
            AuthAPI.resetPws(userData)
                .then((result) => {
                    setValid(true);
                    setLevel(1);
                    return true;
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status == 404) {
                            setLevel(2);
                            setErrorMessage(error.response.data.description);
                        }
                        if (error.response) {
                            if (error.response.status == 500 || error.response.status == 403) {

                            }
                        }
                    }
                })
        }
    }

    function emailSet(value) {
        setEmail(value)
    }

    // function confirmSendEmail() {

    //     if (email === "" || regExEmail.test(email) === false) {
    //         setValid(false)
    //         console.log("invio email non riuscito")
    //     } else {
    //         let data = {
    //             email: email,
    //         }
    //         console.log("invio email riuscito")
    //         // return axios.post(process.env.REACT_APP_FETCH_URL + 'utente/verificaemail', data)
    //         //     .then((response) => {
    //         //         setValid(true);
    //         //         setLevel(1);
    //         //         return true;
    //         //     })
    //         //     .catch((error) => {
    //         //         setValid(false)
    //         //         setDisplayErrorMessage(true);
    //         //         setErrorMessage(error.response.data.description);
    //         //     })
    //     }
    // }

    function navigateToLogin() {
        navigate("/login")
    }

    function navigateToPwsRecover() {
        setLevel(0)
    }

    return (
        <Fragment>
            <Box height={"100vh"} backgroundColor={{ base: "white", sm: "primary.800" }}>
                <Center height={"100vh"}>
                    <Box rounded={{ base: "none", sm: "lg" }} overflow="hidden" backgroundColor="white" borderColor={{ base: "none", sm: "coolGray.200" }} borderWidth={{ base: "0", sm: "1" }}>
                        <Center w={{ base: "100vw" }} maxW="2xl" rounded={{ base: "none", sm: "lg" }} overflow="hidden" borderColor="coolGray.200" padding={"2em"}>
                            <Image source={{
                                uri: Logo
                            }} alt="Logo" w="170px" h="50px" />
                            {level == 0 &&
                                (<Stack space={4} w="100%" mx="auto">
                                    <VStack space={2}>
                                        <Text fontSize="3xl" bold textAlign="center" mt="5">
                                            {t("pages.recoveryPws.haveYouForgotPws")}
                                        </Text>
                                        <Text fontSize="md" textAlign="center" >
                                            {t("pages.recoveryPws.dontWorry")}
                                        </Text>
                                        <Text fontSize="6xl" mt="3" textAlign="center">
                                            <FontAwesomeIcon icon={faKey} color="#65a30d" />
                                        </Text>
                                        <FormControl my="3" isRequired isInvalid={valid != true}>
                                            <Input w="100%" rounded="none" size="lg" placeholder="Email" onChangeText={value => emailSet(value)} />
                                            {(valid === false && email === "") && (<FormControl.ErrorMessage>{t("errors.email.empty")}</FormControl.ErrorMessage>)}
                                            {(regExEmail.test(email) === false && email !== "") && (<FormControl.ErrorMessage>{t("errors.email.invalid")}</FormControl.ErrorMessage>)}
                                            {/* {displayErrorMessage && (<FormControl.ErrorMessage> {errorMessage}</FormControl.ErrorMessage>)} */}
                                        </FormControl>

                                        <Button colorScheme="primary" rounded="lg" onPress={confirmSendEmail}>
                                            {t("pages.recoveryPws.sendEmail")}
                                        </Button>
                                        {/* <Button colorScheme="primary" variant="outline" rounded="lg" onPress={navigateToLogin}>
                                            {t("pages.signup.gotologin")}
                                        </Button> */}
                                        <Center  >
                                            <Link alignItems="center" mt="1" _text={{
                                                fontSize: "md",
                                                fontWeight: "500",

                                            }} onPress={navigateToLogin}>
                                                <FontAwesomeIcon icon={faArrowLeft} size="lg" colors='primary.500' />
                                                <Text ml="2">
                                                    {t("pages.signup.gotologin")}
                                                </Text>
                                            </Link>
                                        </Center>
                                    </VStack>
                                </Stack>)
                            }
                            {level == 1 &&
                                (<Stack space={4} w="100%" maxW="400px" mx="auto">
                                    <VStack space={2} mt="5">
                                        <Text fontSize="3xl" textAlign="center" bold>{t("pages.recoveryPws.checkYourEmail")}</Text>
                                        <Text fontSize="6xl" mt="3" textAlign="center">
                                            <FontAwesomeIcon icon={faMailBulk} color="#65a30d" />
                                        </Text>
                                        <Text fontSize="md" textAlign="center">{t("pages.recoveryPws.linkSent")} <b>{email}</b></Text>
                                        {/* <Button colorScheme="primary" rounded="lg" onPress={navigateToLogin} mt="3" >
                                            {t("pages.signup.gotologin")}
                                        </Button> */}
                                        <Center  >
                                            <Link alignItems="center" mt="1" _text={{
                                                fontSize: "md",
                                                fontWeight: "500",

                                            }} onPress={navigateToLogin}>
                                                <FontAwesomeIcon icon={faArrowLeft} size="lg" colors='primary.500' />
                                                <Text ml="2">
                                                    {t("pages.signup.gotologin")}
                                                </Text>
                                            </Link>
                                        </Center>
                                    </VStack>
                                </Stack>)
                            }
                            {level == 2 &&
                                (<Stack space={4} w="100%" maxW="400px" mx="auto">
                                    <VStack space={2}>
                                        {/* <Text fontSize="6xl" mt="3" textAlign="center">
                                            <FontAwesomeIcon icon={faCheck} color="#65a30d" />
                                        </Text> */}
                                        <Text fontSize="3xl" textAlign="center">{t("pages.resetPws.error")}</Text>
                                        {/* <Text fontSize="md" textAlign="center" > Siamo spiacenti, {errorMessageReset == "Token scaduto" && ("le credenziali inviatele sono scadute.")}</Text> */}
                                        {errorMessage === '' ? (<Text fontSize="md" textAlign="center" >{t("pages.resetPws.errorSub")}</Text>):
                                        (<Text fontSize="md" textAlign="center">{errorMessage}</Text>)}
                                        {/* <Text fontSize="md" alignSelf="center">Fai click sul pulsante </Text> */}
                                        <Button colorScheme="primary" rounded="lg" onPress={navigateToPwsRecover} mt="3">{t("pages.resetPws.gotopwsrecover")}</Button>
                                    </VStack>
                                </Stack>)
                            }
                        </Center>
                    </Box>
                </Center>
            </Box>
            <Helmet>
                <title>{t("name")} - {t("pages.login.title")}</title>
            </Helmet>
        </Fragment>
    )
}

