import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  it: {
    translation: {
      name: "Quizube",
      errors: {
        email: {
          empty: "Campo email vuoto",
          invalid: "Email non valida",
        },
        pws: {
          empty: "Campo password vuoto",
          invalid: "Password non valida",
          unequal: "Le password non corrispondono"
        },
        username: {
          empty: "Campo username vuoto",
          invalid: "Username non valido",
        },
        nome: {
          empty: "Campo nome vuoto",
        },
        cognome: {
          empty: "Campo cognome vuoto",
        },
        dataDiNascita: {
          empty: "Data non selezionata",
        },
        avatar: {
          empty: "Campo avatar vuoto",
          heavy: "Non è possibile caricare file che abbiano una dimensione maggiore di 1mb."
        },
      },
      menu: {
        myProfile: "Profilo",
        home: "Home",
        editProfile: "Modifica profilo",
        searchTitle: "Ricerca",
        search: "Cerca...",
        minCharSearch: "Per effettuare una ricerca è necessario inserire un nome composto da minimo 3 lettere",
        noResult: "Nessun risultato"
      },
      pages: {
        login: {
          title: 'Login',
          forgotpassword: 'Hai dimenticato la password?',
          signup: 'Non hai un account? Clicca qui',
          editProfile: 'Conferma modifiche',
          sendNewConfirmationMail: "Invia nuova email di conferma",
          loginFacebook: "Login con Facebook",
          loginGoogle: "Login con Google",
          loginLinkedin: "Login con Linkedin",
          errorLogin: "Email o Password errati"
        },
        signup: {
          emailConfermata: "L'email è stata confermata correttamente",
          title: 'Registrazione',
          gotologin: 'Torna alla schermata di login',
          gotologin2: 'Hai già un account? Torna alla schermata di login',
          gotodatapage: 'Torna alla schermata dei dati',
          password: "Password",
          oldpassword: 'Vecchia password',
          newpassword: 'Nuova password',
          confirmpassword: 'Conferma password',
          name: 'Nome',
          surname: 'Cognome',
          bday: 'Data di nascita',
          next: 'Prosegui',
          passwordRequirements: 'La password deve contenere almeno:',
          password8Char: "8 caratteri minimo;",
          passwordUpperChar: "1 carattere maiuscolo;",
          passwordNumericChar: "1 carattere numerico;",
          passwordSpecialChar: "1 carattere speciale;",
          changePhoto: "Cambia foto",
          cancel: "Cancella",
          welcome: "Benvenuto",
          confirm: "Conferma",
          modifyYourData: "Modifica i tuoi dati",
          regOk: "Registrazione avvenuta",
          regOkSub: "Per poter accedere al sito, è prima necessario confermare la propria email utilizzando il link inviato a ",
        },
        recoveryPws: {
          haveYouForgotPws: "Hai dimenticato la password?",
          dontWorry: "Non preoccuparti, ti invieremo un link e le istruzioni per resettarla.",
          sendEmail: "Invia email di recupero",
          checkYourEmail: "Controlla la tua email",
          linkSent: "Abbiamo inviato il link di reset della tua password a ",
          linkSent2: "Abbiamo inviato un nuovo link a ",
        },
        resetPws: {
          newpws: "Imposta una nuova password",
          confirm: "Password modificata",
          confirmSub: "La tua password è stata modificata correttamente.",
          error: "Qualcosa non ha funzionato",
          errorSub: "Siamo spiacenti, è necessario eseguire nuovamente la procedura.",
          gotopwsrecover: 'Torna alla schermata di reset della password',
        },
        profile: {
          level: "Livello",
          viewAllAchievements: "Vedi tutti i traguardi",
          viewAllFriends: "Vedi tutti i follower",
          viewLeaderboard: "Vedi classifica generale",
          yourFriends: "I tuoi follower",
          friends: "Amici",
          achievements: "Traguardi",
          leaderboard: "Classifica generale",
          survey: "Questionari",
          leaderboardTitleModal: "Classifica generale",
          friendsTitleModal: "Follower",
          follower: "I tuoi follower",
          noAchievements: "Non è ancora stato raggiunto nessun obiettivo",
          following: "Seguiti",
          achievementsTitleModal: "Traguardi"
        },
        quiz: {
          btnStart: "Avvia",
          btnBack: "indietro",
          btnBackToSelezione: "Torna alla selezione dei questionari",

          simulation: "Simulazione",
          exercise: "Esercitazione",
          sequential: "Sequenziale",
          totalQuantity: "Quiz selezionati",
          simulationSubtitle: "Materie della prova di concorso",
          exerciseSubtitle1: "Crea il tuo questionario:",
          exerciseSubtitle2: "Seleziona il numero di quesiti per ogni materia. Se vuoi esercitarti su una sola materia puoi azzerare tutti i quiz delle altre materie",
          sequentialSubtitle1: "Quesiti in sequenza per materia:",
          sequentialSubtitle2: "Tutti i quesiti sono in ordine di inserimento",

          answer: "Domanda n.",
          errorGeneratingQuestionario: "C'è stato un errore durante la generazione del quiz",
          needToWait: " Prima di proseguire alla sezione successiva è necessario attendere",
          dontShowCorrectAnswer: "Non mostrare risposta corretta",
          showCorrectAnswer: "Mostra risposta corretta",
          confirmAndShowAnser: "Conferma e visualizza risposta",
          confirmAndGoToNextSection: "Conferma e vai alla sezione successiva",
          confirmAndGoNext: "Conferma e prosegui",
          goNext: "Prosegui",
          btnEnd: "Concludi",
          submitReport: "Invia segnalazione",
          loading: "Caricamento",
          quizSettings: "Impostazioni quiz",
        },
        chat: {
          chatList: "Lista chat",
          newChat: "Nuova chat",
          loading: "Caricamento...",
          newChatButton: "Inizia una conversazione",
          noChatStarted: "Non hai ancora avviato nessuna conversazione"
        },
        errorPage: {
          title: "Pagina non trovata"
        }
      },
    }
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "it", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
