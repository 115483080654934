import * as React from 'react';
import { useEffect, useState } from "react";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'

import { Box, Tooltip, Modal, Text, Button } from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPause } from '@fortawesome/free-solid-svg-icons'

export default function Timer(props) {

    const [typeQuestionario, setTypeQuestionario] = useState(props.typeQuestionario);
    const [timer, setTimer] = useState(props.timer);
    const [timePaused, setTimePaused] = useState(false);
    const [remainingTime, setRemainingTime] = useState(props.timer);
    const [initialTimer, setInitialTimer] = useState(props.timer);
    const [sezione, setSezione] = useState(props.sezione);
    const [key, setKey] = useState(0);

    useEffect(() => {
        if (props.timer !== timer) setTimer(props.timer);
    }, [props.timer])

    function convertMinutesToSeconds(time) {  /*Converto i secondi in formato MM:SS */
        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;
        if (minutes < 10) minutes = '0' + minutes
        if (seconds < 10) seconds = '0' + seconds
        return (minutes + "." + seconds)
    }
    function pauseTime() {
        if (typeQuestionario === 'Ese') {
            setTimePaused(!timePaused);
        }
    }

    return (
        <>
            {typeQuestionario === 'Ese' && (
                <Tooltip arrow title='Metti in pausa'>
                    <Box onClick={pauseTime} style={{ cursor: 'pointer' }}>
                        <CountdownCircleTimer
                            size={100}
                            key={key}
                            duration={timer}
                            colors={["white"]}
                            trailColor={"#2d460a"}
                            strokeWidth={12}
                            isPlaying={!timePaused}
                            initialRemainingTime={initialTimer}
                            onUpdate={(remainingTime) => {
                                setRemainingTime(remainingTime)
                                props.changeRemainingTime(remainingTime)
                            }}
                            onComplete={() => {
                                props.onTimeFinished();
                                return { shouldRepeat: false }
                            }}
                        >
                            {({ remainingTime }) =>
                                <div>
                                    {timePaused === false && (
                                        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            <Text style={{ cursor: 'pointer' }} color={'white'} variant='h6'>
                                                {convertMinutesToSeconds(remainingTime)}
                                            </Text>
                                            <Text color={'white'} fontSize={'sm'} ><FontAwesomeIcon className='delete-icon' size="xl" icon={faPause} /></Text>
                                        </Box>
                                    )}
                                    {timePaused === true && (
                                        <Text color={'white'}><FontAwesomeIcon className='delete-icon' size="xl" icon={faPause} /></Text>
                                    )}
                                </div>
                            }
                        </CountdownCircleTimer>
                    </Box>
                </Tooltip>
            )}
            {typeQuestionario === 'Sim' && ( //Timer senza pausa
                <Tooltip arrow title='Nella modalità simulazione non puoi sospendere la prova'>
                    <Box >
                        <CountdownCircleTimer
                            size={100}
                            key={key}
                            duration={timer}
                            colors={["#65a30d"]}
                            trailColor={"#486f12"}
                            strokeWidth={12}
                            isPlaying={!timePaused}
                            initialRemainingTime={initialTimer}
                            onUpdate={(remainingTime) => {
                                setRemainingTime(remainingTime)
                                props.changeRemainingTime(remainingTime)
                            }}
                            onComplete={() => {
                                props.onTimeFinished();
                                return { shouldRepeat: false }
                            }}

                        >
                            {({ remainingTime }) =>
                                <div>
                                    {timePaused === false && (
                                        <Text color={'white'} style={{ cursor: 'context-menu' }} variant='h6'>
                                            {convertMinutesToSeconds(remainingTime)}
                                        </Text>
                                    )}
                                    {timePaused === true && (
                                        <Text color={'white'} style={{ cursor: 'context-menu' }} variant='h6'>
                                            Pausa
                                        </Text>
                                    )}
                                </div>
                            }
                        </CountdownCircleTimer>
                    </Box>
                </Tooltip>
            )}

            <Modal open={timePaused}>
                <Modal.Content style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} p={5}>
                    <Text variant="h3" fontSize={"3xl"}>In Pausa</Text>
                    <Button variant="solid" px={5} mt={2} style={{ color: 'white' }} onClick={pauseTime}>Torna al quiz</Button>
                </Modal.Content>
            </Modal>

        </>
    )
}
