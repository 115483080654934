import * as React from 'react';
import { useState, useEffect, Fragment } from 'react';
import {
    Box, Divider, FormControl, HStack, Input, Stack, Image, VStack, Flex, Hidden,
    Spacer, Center, Heading, Button, Link, Text, Avatar, Popover, Select
} from 'native-base';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faTrashCan, faInfoCircle, faArrowLeft, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Logo from "../../img/logoEspanso.png";

import { Helmet } from "react-helmet";
//API
import { AuthAPI } from '../../api/authApi';
import { FileAPI } from '../../api/fileApi';
import { ProfileAPI } from '../../api/profileApi';
import axios from 'axios';
export default function Registrazione() {

    const navigate = useNavigate();
    const regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const regExPassword = /^[A-Za-zÀ-ȕ0-9-._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]{8,}$/;
    // const regExUsername = /^[a-zA-Z0-9]{0,20}$/;

    const { t, i18n } = useTranslation();

    const [showPws, setShowPws] = useState(false);

    const [valid, setValid] = useState(true);

    const [email, setEmail] = useState("");
    const [pws, setPws] = useState("");
    const [pwsConferma, setPwsConferma] = useState("");
    const [nome, setNome] = useState("");
    // const [username, setUsername] = useState("");
    const [cognome, setCognome] = useState("");
    const [dataDiNascita, setDataDiNascita] = useState("");
    const [idAvatar, setIdAvatar] = useState();
    const [urlAvatar, setUrlAvatar] = useState("");
    const [urlAvatarLocal, setUrlAvatarLocal] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [level, setLevel] = useState(0);
    const [arrayFacolta, setArrayFacolta] = useState([]);
    const [facoltaId, setFacoltaId] = useState(0);

    const [avatarFile, setAvatarFile] = useState(undefined)
    const [valueAvatar, setValueAvatar] = useState(null)

    const [isHeavy, setIsHeavy] = useState(false);

    const [errorMessageReg, setErrorMessageReg] = useState("");
    const [messageDisplay, setMessageDisplay] = useState(false);

    const [prova, setProva] = useState(false);

    useEffect(() => {
        getListFacolta()
    }, [])

    // TODO: temporaneo - test caricamento
    useEffect(() => {
        if (isLoading) setTimeout(() => setIsLoading(false), 2000)
    }, [isLoading])

    async function registrazione() {

        let userData = {
            nome: nome,
            cognome: cognome,
            dataDiNascita: dataDiNascita,
            // username: username,
            password: pws,
            email: email,
            avatar: idAvatar,
            facolta: parseInt(facoltaId)
        }

        AuthAPI.registrazione(userData)
            .then((result) => {
                setLevel(3);
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 500) {
                        setMessageDisplay(true);
                        setErrorMessageReg(error.response.data.description);
                        setLevel(0);
                    }
                }
            })

    }

    async function uploadAvatar(avatarFile) {
        let formData = new FormData();

        formData.append("file", avatarFile);

        FileAPI.upload(formData)
            .then((result) => {
                setIdAvatar(result.id.toString())
                setUrlAvatar(result.url)
            })
            .catch((error) => {
                if (error.response) {
                    if (error.response.status == 500) {
                        setMessageDisplay(true);
                        setErrorMessageReg(error.response.data.description);
                    }
                }
            })
    }

    async function getListFacolta() {
        ProfileAPI.facolta()
            .then((result) => {
                setArrayFacolta(result)
            })
            .catch((error) => {
            })
    }

    async function validate() {
        if (email === "" || regExEmail.test(email) === false || pws === "" || pwsConferma === "" || pwsConferma !== pws || nome === "" || cognome === "" || dataDiNascita === "") {
            setValid(false)
            return false
        } else {
            let data = {
                email: email,
            }
            return axios.post(process.env.REACT_APP_FETCH_URL + 'utente/emailunica', data)
                .then((response) => {
                    setMessageDisplay(false);
                    setErrorMessageReg("");
                    return true;
                })
                .catch((error) => {
                    setMessageDisplay(true);
                    setErrorMessageReg(error.response.data.description);
                    return false
                })
        }
    }

    function onFileChange(e) {
        if (e.target.files[0].size > 1000000) {
            setIsHeavy(true);
        }
        else {
            setIsHeavy(false);
            setValueAvatar(e.target.value);
            setAvatarFile(e.target.files[0]);
            setUrlAvatarLocal(URL.createObjectURL(e.target.files[0]));
        }
    }

    function handleClick() { setShowPws(!showPws) };

    function emailSet(value) {
        setEmail(value)
    }
    function pwsSet(value) {
        setPws(value)
    }
    function pwsConfermaSet(value) {
        setPwsConferma(value)
    }
    function nomeSet(value) {
        setNome(value)
    }
    function cognomeSet(value) {
        setCognome(value)
    }
    function dataDiNascitaSet(e) {
        setDataDiNascita(e.target.value)
    }
    // function usernameSet(value) {
    //     let userTmp = value.replace(/[^A-Z0-9]/ig, "");;
    //     setUsername(userTmp)
    // }

    function navigateToLogin() {
        navigate("/login")
    }

    function stepUp() {
        //La funzione valida ora è diventata async perchè all'interno ho dovuto utilizzare "await"
        //a questo punto una funzione asincrona ritorna sempre una promise e quindi va risolta obbligatoriamente con then e catch
        validate()
            .then(validateBool => {
                if (validateBool == true) {
                    setLevel(level + 1);
                    setValid(false);
                }
            })
    };

    function resetAvatarSet() {
        setUrlAvatarLocal("");
        setAvatarFile(undefined);
        setValueAvatar(null);
    }

    function uploadFile() {
        if (urlAvatarLocal !== "") uploadAvatar(avatarFile);
        setLevel(level + 1);
    }

    function provaSet() {
        setProva(true)
    }

    return (
        <Fragment>
            <Box height={"100vh"} backgroundColor="primary.800">
                <Center height={{ base: "100vh", sm: "100vh" }}>
                    <Box height={{ base: "100%", md: "fit-content" }} rounded={{ base: "none", md: "lg" }} overflow="hidden" backgroundColor="white" borderColor={{ base: "none", sm: "coolGray.200" }} borderWidth={{ base: "0", sm: "1" }}>
                        <Center height={{ base: "100%", md: "fit-content" }} w={{ base: "100vw" }} maxW="2xl" rounded={{ base: "none", md: "lg" }} overflow="hidden" borderColor="coolGray.200" padding={"2em"}>
                            <Center>
                                <Image source={{
                                    uri: Logo
                                }} alt="LOGO" w="170px" h="50px" />
                            </Center>
                            {level != 3 && (
                                <Hidden from="base" till="sm">
                                    <Flex w="100%" mt="6" direction='row' justify="center" align="center">
                                        <Flex direction='row' align="center">
                                            <Avatar bg={level == 0 ? "primary.600" : "muted.300"} mr="1">
                                                1
                                            </Avatar>
                                            <Text mx="1">Dati generali</Text>
                                            <Box mt="1">
                                                <FontAwesomeIcon icon={faChevronRight} size="sm" colors='primary.500' />
                                            </Box>
                                        </Flex>
                                        <Flex mx="4" direction='row' align="center">
                                            <Avatar bg={level == 1 ? "primary.600" : "muted.300"} mr="1">
                                                2
                                            </Avatar>
                                            <Text mx="1">Foto profilo</Text>
                                            <Box mt="1">
                                                <FontAwesomeIcon icon={faChevronRight} size="sm" colors='primary.500' />
                                            </Box>
                                        </Flex>
                                        <Flex direction='row' align="center">
                                            <Avatar bg={level == 2 ? "primary.600" : "muted.300"} mr="1">
                                                3
                                            </Avatar>
                                            <Text mx="1">Riepilogo</Text>
                                        </Flex>
                                    </Flex>
                                </Hidden>
                            )}
                            {level == 0 && (
                                <Stack space={4} w="100%" mx="auto">
                                    {/* <Heading justifyContent="center" w="100%">
                                       <Center>
                                            <Image source={{
                                                uri: Logo
                                            }} alt="LOGO" w="170px" h="50px" />
                                        </Center> 
                                    </Heading>*/}
                                    <VStack space={2}>
                                        <FormControl isRequired isInvalid={valid != true}>
                                            <FormControl.Label>Email</FormControl.Label>
                                            <Input onSubmitEditing={stepUp} testid="input-email" w="100%" rounded="none" value={email} size="lg" onChangeText={value => emailSet(value)} />
                                            {(valid === false && email === "") && (<FormControl.ErrorMessage>{t("errors.email.empty")}</FormControl.ErrorMessage>)}
                                            {(regExEmail.test(email) === false && email !== "") && (<FormControl.ErrorMessage>{t("errors.email.invalid")}</FormControl.ErrorMessage>)}
                                        </FormControl>
                                        <FormControl isRequired isInvalid={valid != true}>
                                            <FormControl.Label>
                                                Password
                                                <Popover trigger={triggerProps => {
                                                    return <Button ml="1" w="0" h="0" style={{ paddingLeft: "0.4em" }} {...triggerProps} variant="unstyled" leftIcon={<div className='badge-container'><FontAwesomeIcon color='red' className='delete-icon' icon={faInfoCircle} size="xs" colors='primary.500' /></div>} />
                                                }}>
                                                    <Popover.Content accessibilityLabel="Search" w="56">
                                                        <Popover.Arrow />
                                                        <Popover.Body fontSize="xs">
                                                            <div> {t("pages.signup.passwordRequirements")}</div>
                                                            <ul>
                                                                <li>{t("pages.signup.password8Char")}</li>
                                                                <li>{t("pages.signup.passwordUpperChar")}</li>
                                                                <li>{t("pages.signup.passwordNumericChar")}</li>
                                                                <li>{t("pages.signup.passwordSpecialChar")}</li>
                                                            </ul>
                                                        </Popover.Body>
                                                    </Popover.Content>
                                                </Popover>
                                            </FormControl.Label>
                                            <Input onSubmitEditing={stepUp} testid="input-password" w="100%" rounded="none" value={pws} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                                <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                            } onChangeText={value => pwsSet(value)} />
                                            {(valid === false && pws === "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                            {(regExPassword.test(pws) === false && pws !== "") && (<FormControl.ErrorMessage>{t("errors.pws.invalid")}</FormControl.ErrorMessage>)}
                                        </FormControl>
                                        <FormControl isRequired isInvalid={valid != true}>
                                            <FormControl.Label>{t("pages.signup.confirmpassword")}</FormControl.Label>
                                            <Input onSubmitEditing={stepUp} testid="input-confirm-password" w="100%" rounded="none" value={pwsConferma} size="lg" type={showPws ? "text" : "password"} InputRightElement={
                                                <FontAwesomeIcon className='password-icon' icon={showPws ? faEyeSlash : faEye} onClick={handleClick} />
                                            } onChangeText={value => pwsConfermaSet(value)} />
                                            {(valid === false && pwsConferma === "") && (<FormControl.ErrorMessage>{t("errors.pws.empty")}</FormControl.ErrorMessage>)}
                                            {(pwsConferma !== pws && pwsConferma !== "") && (<FormControl.ErrorMessage>{t("errors.pws.unequal")}</FormControl.ErrorMessage>)}
                                        </FormControl>
                                        {/* <FormControl isRequired isInvalid={valid != true}>
                                            <FormControl.Label>
                                                Username
                                                <Popover trigger={triggerProps => {
                                                    return <Button w="0" h="0" ml="1" {...triggerProps} variant="unstyled" leftIcon={<div className='badge-container'><FontAwesomeIcon color='red' className='delete-icon' icon={faInfoCircle} size="xs" colors='primary.500' /></div>} />
                                                }}>
                                                    <Popover.Content accessibilityLabel="Search" w="56">
                                                        <Popover.Arrow />
                                                        <Popover.Body fontSize="xs">
                                                            <div>Lo username non può contenere caratteri speciali o spazi.</div>
                                                        </Popover.Body>
                                                    </Popover.Content>
                                                </Popover>
                                            </FormControl.Label>
                                            <Input w="100%" rounded="none" value={username} size="lg" onChangeText={value => usernameSet(value)} maxLength={20} />
                                            {(valid === false && username === "") && (<FormControl.ErrorMessage>{t("errors.username.empty")}</FormControl.ErrorMessage>)}
                                            {(regExUsername.test(username) === false && username !== "") && (<FormControl.ErrorMessage>{t("errors.username.invalid")}</FormControl.ErrorMessage>)}
                                        </FormControl> */}
                                        <Flex direction="row" flexWrap="wrap" mb="2.5" mt="1.5" >
                                            <FormControl isRequired isInvalid={valid != true} w="49%">
                                                <FormControl.Label>{t("pages.signup.name")}</FormControl.Label>
                                                <Input onSubmitEditing={stepUp} testid="input-name" w="100%" rounded="none" value={nome} size="lg" onChangeText={value => nomeSet(value)} />
                                                {(valid === false && nome === "") && (<FormControl.ErrorMessage>{t("errors.nome.empty")}</FormControl.ErrorMessage>)}
                                            </FormControl>
                                            <Spacer w="2%" />
                                            <FormControl isRequired isInvalid={valid != true} w="49%">
                                                <FormControl.Label>{t("pages.signup.surname")}</FormControl.Label>
                                                <Input onSubmitEditing={stepUp} testid="input-surname" w="100%" value={cognome} rounded="none" size="lg" onChangeText={value => cognomeSet(value)} />
                                                {(valid === false && cognome === "") && (<FormControl.ErrorMessage>{t("errors.cognome.empty")}</FormControl.ErrorMessage>)}
                                            </FormControl>
                                            <FormControl mt="4" isRequired isInvalid={valid != true}>
                                                <FormControl.Label>{t("pages.signup.bday")}</FormControl.Label>
                                                <input id="input-bday" value={dataDiNascita} type="date" onChange={dataDiNascitaSet} />
                                                {(valid === false && dataDiNascita === "") && (<FormControl.ErrorMessage>{t("errors.dataDiNascita.empty")}</FormControl.ErrorMessage>)}
                                            </FormControl>
                                            <FormControl mt="4" isInvalid={valid != true}>
                                                <FormControl.Label>Facoltà</FormControl.Label>
                                                <Select  testid='select-facolta' id="select-facolta" rounded={"none"} size="lg"
                                                    _selectedItem={{
                                                        bg: "teal.600",
                                                    }}
                                                    mt="1" defaultValue={String(facoltaId)} value={String(facoltaId)} onValueChange={(value) => setFacoltaId(value)}>
                                                    {arrayFacolta.map((item,index) =>
                                                        <Select.Item testid={"facolta" + index}  key={item.id} label={item.nome} value={String(item.id)} />
                                                    )}
                                                </Select>
                                            </FormControl>
                                            {messageDisplay === true && (<Text testid={"error-message"} fontSize="xs" color="red.600" mt="4">{errorMessageReg}</Text>)}
                                        </Flex>
                                    </VStack>
                                    <VStack space={2}>
                                        <Button testid="button-next"  colorScheme="primary" rounded="lg" onPress={stepUp}>
                                            {t("pages.signup.next")}
                                        </Button>
                                    </VStack>
                                    <Center  >
                                        <Link alignItems="center" mt="1" _text={{
                                            fontSize: "md",
                                            fontWeight: "500",

                                        }} onPress={navigateToLogin}>
                                            <FontAwesomeIcon icon={faArrowLeft} colors='primary.500' />
                                            <Text ml="2">
                                                {t("pages.signup.gotologin2")}
                                            </Text>
                                        </Link>
                                    </Center>

                                </Stack>
                            )}
                            {level == 1 && (
                                <Stack space={4} w="100%" maxW="400px" mx="auto" mt="8">
                                    <FormControl isInvalid={valid != true}>
                                        {/* <FormControl.Label>Seleziona il tuo Avatar</FormControl.Label> */}
                                        <Center>
                                            <HStack space={2}>
                                                <Center>
                                                    {urlAvatarLocal == "" &&
                                                        (<Avatar bg="info.600" alignSelf="center" size="2xl" source={{
                                                            uri: urlAvatarLocal
                                                        }}>
                                                            {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                                        </Avatar>)}
                                                    {urlAvatarLocal != "" &&
                                                        (<Avatar bg="info.600" alignSelf="center" size="2xl" source={{
                                                            uri: urlAvatarLocal
                                                        }}>
                                                            {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                                        </Avatar>)}
                                                </Center>
                                                <Center>
                                                    <VStack space={1}>
                                                        <input type="file" accept="image/png, image/jpg, image/jpeg, image/PNG, image/JPG, image/JPEG" name="file" id="file" className="inputfile" onChange={onFileChange} />
                                                        <label htmlFor="file">
                                                            <Button testid="button-upload-photo" rounded="lg" size="xs" textAlign="right">
                                                                {t("pages.signup.changePhoto")}
                                                            </Button>
                                                        </label>
                                                        <Button testid="button-removo-photo" colorScheme="danger" rounded="lg" onPress={resetAvatarSet} size="xs" leftIcon={<FontAwesomeIcon className='delete-icon' icon={faTrashCan} size="xs" color='#F5F5FA' />}>
                                                            Rimuovi foto
                                                            {/* {t("pages.signup.cancel")}  */}
                                                        </Button>
                                                    </VStack>
                                                </Center>
                                            </HStack>
                                        </Center>
                                        {(isHeavy == true) && (<FormControl.ErrorMessage>{t("errors.avatar.heavy")}</FormControl.ErrorMessage>)}
                                    </FormControl>
                                    <Flex direction="row" flexWrap="wrap" mb="2.5" mt="1.5" >
                                        {/* isDisabled={urlAvatarLocal != "" ? false : true} */}

                                        <Button testid="button-next2" colorScheme="primary" rounded="lg" onPress={uploadFile} w="100%">
                                            {urlAvatarLocal != "" ? "Carica" : "Prosegui"}
                                        </Button>
                                        <Button testid="button-goTo-dati-generali"  mt="2" variant="outline" colorScheme="primary" rounded="lg" onPress={() => setLevel(0)} w="100%">
                                            {t("pages.signup.gotodatapage")}
                                        </Button>
                                    </Flex>
                                </Stack>
                            )}
                            {level == 2 && (
                                <Stack space={4} w="100%" maxW="400px" mx="auto" mt="6">
                                    <Center >
                                        <Avatar bg="primary.500" alignSelf="center" size="2xl" source={{
                                            uri: urlAvatarLocal
                                        }}>
                                            {nome.slice(0, 1).toUpperCase() + cognome.slice(0, 1).toUpperCase()}
                                        </Avatar>
                                        <Text fontSize="3xl"> {t("pages.signup.welcome")} {nome} </Text>
                                    </Center>
                                    <Divider></Divider>
                                    <VStack space={2}>
                                        <HStack>
                                            <Text fontSize="md" w="50%"> <b>{t("pages.signup.name")}:</b> {nome}</Text>
                                            <Text fontSize="md" w="50%"> <b>{t("pages.signup.surname")}:</b> {cognome}</Text>
                                        </HStack>
                                        {/* <Text fontSize="md"> <b>Username:</b> {username}</Text> */}
                                        <Text fontSize="md"> <b>Email:</b> {email}</Text>
                                        <Text fontSize="md"> <b>{t("pages.signup.bday")}:</b> {dataDiNascita.split('-').reverse().join('/')}</Text>
                                    </VStack>
                                    <Flex direction="row" flexWrap="wrap" mb="2.5" mt="1.5" >
                                        <Button testid="button-goTo-dati-generali" colorScheme="secondary" rounded="lg" onPress={() => setLevel(0)} w="49%">
                                            {t("pages.signup.modifyYourData")}
                                        </Button>
                                        <Spacer w="2%" />
                                        <Button testid="button-confirm-signup" colorScheme="primary" rounded="lg" onPress={registrazione} w="49%">
                                            {t("pages.signup.confirm")}
                                        </Button>
                                    </Flex>
                                </Stack>
                            )}
                            {level == 3 && (
                                <Stack w="100%" maxW="400px" mx="auto" mt="6">
                                    <Center>
                                        <VStack space={2}>
                                            <Text fontSize="3xl" textAlign="center" testid="text-confirmed-signup" >{t("pages.signup.regOk")}</Text>
                                            <Text fontSize="md" textAlign="center">
                                                {t("pages.signup.regOkSub")}<b>{email}</b>
                                            </Text>
                                            {/* <Center>
                                                <Link alignItems="center" mt="1" _text={{
                                                    fontSize: "md",
                                                    fontWeight: "500",
                                                }} onPress={navigateToLogin}>
                                                    <FontAwesomeIcon icon={faArrowLeft} size="md" colors='primary.500' />
                                                    <Text ml="2" textAlign={"center"}>
                                                        {t("pages.signup.gotologin")}
                                                    </Text>
                                                </Link>
                                            </Center> */}
                                        </VStack>
                                    </Center>
                                </Stack>)}
                        </Center>
                    </Box>
                </Center>
            </Box>
            <Helmet>
                <title>{t("name")} - {t("pages.signup.title")}</title>
            </Helmet>
        </Fragment>
    )
}
