import * as React from 'react';
import { useState, useEffect, useCallback } from 'react';
import { Avatar, Box, Button, Center, Flex, Text, Link } from 'native-base';
import { Virtuoso } from 'react-virtuoso';

export default function ListClassifica(props) {

    const [listUser, setListUser] = useState([])
    const [listSet, setListSet] = useState(() => []);
    const [isModal, setIsModal] = useState(false);
    const [classificaQuestionarioSingolo, setClassificaQuestionarioSingolo] = useState(false);


    useEffect(() => {
        if (props.classificaQuestionarioSingolo != undefined)
            setClassificaQuestionarioSingolo(props.classificaQuestionarioSingolo)
    }, [])

    useEffect(() => {
        if (props.list != undefined) {
            setListUser(props.list);
            setListSet(props.list.slice(0, 5))
        }
    }, [props.list])

    useEffect(() => {
        if (props.isModal != undefined) { setIsModal(props.isModal) }
    }, [props.isModal])

    const loadMore = useCallback(() => {
        return setTimeout(() => {
            if (listUser != undefined) {
                let newPost = listUser.slice(listSet.length + 1, listSet.length + 5)
                setListUser((oldSet) => [...oldSet, ...newPost])
                setListSet((oldSet) => [...oldSet, ...newPost])
            }
        }, 200)
    }, [setListSet])

    function goToUser(id) {
        props.goToUser(id);
    }
    return (

        <Box w={"100%"}>
            {listUser.length > 0 ? (
                <Box m={2}>
                    {!isModal && (
                        <Text color={"black"} fontWeight="bold" mb={4}>Classifica generale</Text>
                    )}
                    <Virtuoso
                        style={{ height: isModal ? "50vh" : "200px", width: "100%", margin: "0" }}
                        data={listUser}
                        endReached={loadMore}
                        overscan={2}
                        itemContent={(index, userFromList) => {
                            return (
                                <Flex justify="space-between" align="center" key={index} direction="row" >
                                    <Flex direction='row' alignItems="center">

                                        <Text bold>{userFromList.posizione != undefined ? userFromList.posizione + "°" : "n" + "°"}</Text>
                                        <Button mx="4" variant="unstyled" onPress={() => goToUser(userFromList.id)}>
                                            {userFromList.avatar.id != 0 ? (
                                                <Avatar alignSelf="center" source={{ uri: userFromList.avatar.url }} />
                                            ) : (
                                                <Avatar bg={userFromList.bgColor} alignSelf="center">
                                                    {userFromList.nome.slice(0, 1).toUpperCase() + userFromList.cognome.slice(0, 1).toUpperCase()}
                                                </Avatar>
                                            )}
                                        </Button>

                                        <Link onPress={() => goToUser(userFromList.id)}>
                                            <Center>
                                                <Flex direction={window.innerWidth < 1200 ?'column': "row"}>
                                                    <Text >{userFromList.nome} </Text>
                                                    <Text >{userFromList.cognome}</Text>
                                                </Flex>
                                            </Center>
                                        </Link>
                                    </Flex>

                                    <Text textAlign={"center"} mr={4}>{userFromList.punteggio}</Text>
                                </Flex>)
                        }
                        }
                        components={<div style={{ padding: '2rem', display: 'flex', justifyContent: 'center' }}>Caricamento...</div>}
                    />
                </Box>
            ) : (
                <Center h="200px">
                    <Text bold fontSize="md" textAlign={'center'}>
                        {classificaQuestionarioSingolo == false ? ("Classifica momentaneamente non disponibile.")
                            : ("Nessun utente ha ancora completato questo questionario.")}
                    </Text>
                </Center>
            )
            }
        </Box >
    )
}